import DataChannelStatsModel from "./models/DataChannelStats";
import Utility from "./core/Utility";

export default class DataChannelStats implements DataChannelStatsModel {
  private _lastBitrateReceived: number = null;
  private _lastBitrateSent: number = null;
  private _lastBytesReceived = 0;
  private _lastBytesSent = 0;
  private _lastTimestamp: DOMHighResTimeStamp = null;
  private _ready: boolean = false;

  private _bitrateReceived: number = null;
  private _bitrateSent: number = null;
  private _bytesReceived: number = null;
  private _bytesSent: number = null;

  public get bitrateReceived(): number { return this._bitrateReceived; }
  public get bitrateSent(): number { return this._bitrateSent; }
  public get bytesReceived(): number { return this._bytesReceived; }
  public get bytesSent(): number { return this._bytesSent; }
  public get ready(): boolean { return this._ready; }

  public aggregate(dataChannelStats: DataChannelStats[]): void {
    this.reset();
    this._ready = true;
    this._bitrateReceived = dataChannelStats.map(s => s.bitrateReceived).filter(x => !Utility.isNullOrUndefined(x)).reduce((a, b) => a + b, 0);
    this._bitrateSent = dataChannelStats.map(s => s.bitrateSent).filter(x => !Utility.isNullOrUndefined(x)).reduce((a, b) => a + b, 0);
    this._bytesReceived = dataChannelStats.map(s => s.bytesReceived ?? 0).reduce((a, b) => a + b, 0);
    this._bytesSent = dataChannelStats.map(s => s.bytesSent ?? 0).reduce((a, b) => a + b, 0);
  }
  
  public reset(): void {
    this._bitrateReceived = null;
    this._bitrateSent = null;
    this._bytesReceived = null;
    this._bytesSent = null;
  }
  
  public update(bytesSent: number, bytesReceived: number, timestamp: DOMHighResTimeStamp): void {
    this.reset();
    this._ready = true;
    this._bytesReceived = bytesReceived;
    this._bytesSent = bytesSent;
    
    let bitrateReceived: number = this._lastBitrateReceived;
    let bitrateSent: number = this._lastBitrateSent;
    if (this._lastTimestamp) {
      const deltaBytesReceived = bytesReceived - this._lastBytesReceived;
      const deltaBytesSent = bytesSent - this._lastBytesSent;
      const deltaSeconds = (timestamp - this._lastTimestamp) / 1000;
      if (deltaSeconds == 0) return;
      bitrateReceived = Math.ceil(deltaBytesReceived * 8 / deltaSeconds / 1000);
      bitrateSent = Math.ceil(deltaBytesSent * 8 / deltaSeconds / 1000);
    }
    this._bitrateReceived = bitrateReceived;
    this._bitrateSent = bitrateSent;
    this._lastBitrateReceived = bitrateReceived;
    this._lastBitrateSent = bitrateSent;
    this._lastBytesReceived = bytesReceived;
    this._lastBytesSent = bytesSent;
    this._lastTimestamp = timestamp;
  }
}