const paramsRead = new URLSearchParams([...new URLSearchParams(window.location.search)].map(([key, value]) => [key.toLowerCase(), value]));
const paramsWrite = new URLSearchParams(window.location.search);

export const getBoolean = (name: string): boolean | undefined => {
  const value = paramsRead.get(name.toLowerCase())?.toLowerCase();
  return (value == null || value == '' || value == undefined) ? undefined : (value == "true" || value == "yes");
};

export const getFloat = (name: string): number | undefined => {
  const value = paramsRead.get(name.toLowerCase());
  return (value == null || value == '' || value == undefined) ? undefined : parseFloat(value);
};

export const getInt = (name: string): number | undefined => {
  const value = paramsRead.get(name.toLowerCase());
  return (value == null || value == '' || value == undefined) ? undefined : parseInt(value);
};

export const getString = (name: string): string | undefined => {
  const value = paramsRead.get(name.toLowerCase());
  return (value == null || value == '' || value == undefined) ? undefined : value;
};

export const set = (name: string, value: any, valueDefault?: any): void => {
  if (value == null || value == '' || undefined || value == "undefined" || value == valueDefault) {
    paramsWrite.delete(name);
  } else if (typeof value == "boolean" || value instanceof Boolean) {
    paramsWrite.set(name, (<boolean>value).toString().toLowerCase());
  } else if (typeof value == "number" || value instanceof Number) {
    paramsWrite.set(name, (<number>value).toString().toLowerCase());
  } else {
    paramsWrite.set(name, value);
  }
}

export const setUrl = (): void => {
  window.history.replaceState({ }, '', `${location.pathname}?${paramsWrite}`);
}