import DispatchQueue from "../core/DispatchQueue";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Device from "./models/Device";
import DevicesEvent from "./models/DevicesEvent";
import DeviceCollection from "./DeviceCollection";
import DeviceManagerState from "./models/DeviceManagerState";
import DeviceManagerStateChangeEvent from "./models/DeviceManagerStateChangeEvent";
import DeviceManagerStateMachine from "./DeviceManagerStateMachine";
import Log from "../logging/Log";

export default class DeviceManager {
  private static _shared: DeviceManager;

  public static get shared(): DeviceManager {
    if (!this._shared) this._shared = new DeviceManager();
    return this._shared;
  }

  private readonly _audioInputs: DeviceCollection = new DeviceCollection();
  private readonly _audioInputsUpdated = new EventOwnerAsync<DevicesEvent>();
  private readonly _audioOutputs: DeviceCollection = new DeviceCollection();
  private readonly _audioOutputsUpdated = new EventOwnerAsync<DevicesEvent>();
  private readonly _eventQueue = new DispatchQueue();
  private readonly _stateChanged: EventOwnerAsync<DeviceManagerStateChangeEvent> = new EventOwnerAsync<DeviceManagerStateChangeEvent>();
  private readonly _stateEvents = new Map<DeviceManagerState, EventOwnerAsync<DeviceManagerStateChangeEvent>>();
  private readonly _stateMachine = new DeviceManagerStateMachine();
  private readonly _videoInputs: DeviceCollection = new DeviceCollection();
  private readonly _videoInputsUpdated = new EventOwnerAsync<DevicesEvent>();

  private _pollDevicesTimoutInterval: number = 1000;
  private _pollDevicesTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private _isDeviceMonitorRunning: boolean = false;

  public get audioInputs(): DeviceCollection { return this._audioInputs; }
  /** @event */
  public get audioInputsUpdated(): EventOwnerAsync<DevicesEvent> { return this._audioInputsUpdated; }
  public get audioOutputs(): DeviceCollection { return this._audioOutputs; }
  /** @event */
  public get audioOutputsUpdated(): EventOwnerAsync<DevicesEvent> { return this._audioOutputsUpdated; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped" || this.state == "new"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public set pollingInteral(milliseconds: number) { this._pollDevicesTimoutInterval = milliseconds; }
  public get pollingInterval(): number { return this._pollDevicesTimoutInterval; }
  /** @event */
  public get started(): EventOwnerAsync<DeviceManagerStateChangeEvent> { return this._stateEvents.get("started"); }
  /** @event */
  public get starting(): EventOwnerAsync<DeviceManagerStateChangeEvent> { return this._stateEvents.get("starting"); }
  /** @event */
  public get stopped(): EventOwnerAsync<DeviceManagerStateChangeEvent> { return this._stateEvents.get("stopped"); }
  /** @event */
  public get stopping(): EventOwnerAsync<DeviceManagerStateChangeEvent> { return this._stateEvents.get("stopping"); }
  public get state(): DeviceManagerState { return this._stateMachine.state; }
  /** @event */
  public get stateChanged(): EventOwnerAsync<DeviceManagerStateChangeEvent> { return this._stateChanged; }
  public get videoInputs(): DeviceCollection { return this._videoInputs; }
  /** @event */
  public get videoInputsUpdated(): EventOwnerAsync<DevicesEvent> { return this._videoInputsUpdated; }

  public constructor() {
    this._stateEvents.set("started", new EventOwnerAsync<DeviceManagerStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<DeviceManagerStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<DeviceManagerStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<DeviceManagerStateChangeEvent>());

    this.pollDevicesOnInterval = this.pollDevicesOnInterval.bind(this); // contextualize 'this'
  }
  
  private async pollDevicesOnInterval() {
    await this.refresh();
    Log.verbose('Refreshed device list.')
    this._pollDevicesTimeoutId = setTimeout(this.pollDevicesOnInterval, this._pollDevicesTimoutInterval);
  }

  private async setState(state: DeviceManagerState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <DeviceManagerStateChangeEvent>{
      manager: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private async startDeviceMonitor() {
    if (this._isDeviceMonitorRunning) return;    
    this._isDeviceMonitorRunning = true;
    await this.pollDevicesOnInterval();
  }

  private stopDeviceMonitor() {
    this._isDeviceMonitorRunning = false;
    clearTimeout(this._pollDevicesTimeoutId);
  }

  private async update(collection: DeviceCollection, newDevices: Device[], eventOwner: EventOwnerAsync<DevicesEvent>): Promise<void> {
    const newDevicesById: { [id: string]: Device } = {};
    for (const newDevice of newDevices) newDevicesById[newDevice.id] = newDevice;

    let devicesUpdated = false;

    // remove detached devices
    const removed: Device[] = [];
    const updated: Device[] = [];

    for (let index = collection.length - 1; index >= 0; index--) {
      const existingDevice = collection[index];
      const newDevice = newDevicesById[existingDevice.id];
      if (newDevice) {

        // check if device label has changed, mainly for detecting when "default" device has changed, in Chrome
        if (existingDevice.label != newDevice.label) {
          updated.push(newDevice);
          devicesUpdated = true;
        } 

        existingDevice.label = newDevice.label;
        continue;
      }
      removed.push(existingDevice);
      collection.tryRemove(existingDevice.id);
      devicesUpdated = true;
    }

    // add newly attached devices
    const added: Device[] = [];
    for (let i = 0; i < newDevices.length; i++) {
      const newDevice = newDevices[i];
      if (collection.get(newDevice.id)) continue;
      added.push(newDevice);
      collection.tryInsert(newDevice, i);
      devicesUpdated = true;
    }

    if (!devicesUpdated) return;
    await eventOwner.dispatch({
      added: added,
      manager: this,
      removed: removed,
      updated: updated,
    });
  }

  protected async onStarted(): Promise<void> { }
  protected async onStarting(): Promise<void> { }
  protected async onStopped(): Promise<void> { }
  protected async onStopping(): Promise<void> { }

  public async refresh(): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      const infos = await globalThis.navigator?.mediaDevices.enumerateDevices() ?? [];
      const audioInputs = infos.filter(info => info.kind == "audioinput").map(info => <Device>{ id: info.deviceId, groupId: info.groupId, label: info.label });
      const audioOutputs = infos.filter(info => info.kind == "audiooutput").map(info => <Device>{ id: info.deviceId, groupId: info.groupId, label: info.label });
      const videoInputs = infos.filter(info => info.kind == "videoinput").map(info => <Device>{ id: info.deviceId, groupId: info.groupId, label: info.label });
      await this.update(this._audioInputs, audioInputs, this._audioInputsUpdated);
      await this.update(this._audioOutputs, audioOutputs, this._audioOutputsUpdated);
      await this.update(this._videoInputs, videoInputs, this._videoInputsUpdated);
    });
  }

  public async start(): Promise<void> {
    if (this.state == "started") return;
    try {
      await this.setState("starting");
      await this.onStarting();
      await this.startDeviceMonitor();
      await this.setState("started");
      await this.onStarted();
    } catch (error) {
      await this.setState("stopped");
      await this.onStopped();
      throw error;
    }
  }

  public async stop(): Promise<void> {
    if (this.state == "new") await this.setState("stopped");
    if (this.state == "stopped") return;
    try {
      await this.setState("stopping");
      await this.onStopping();
      this.stopDeviceMonitor();
      this._audioInputs.removeAll();
      this._videoInputs.removeAll();
      this._audioOutputs.removeAll();
    } finally {
      await this.setState("stopped");
      await this.onStopped();
    }
  }
}