interface DispatchQueueItem {
    reject: (reason?: any) => void;
    resolve: (value: any) => void;
    task: () => Promise<any>;
}

export default class DispatchQueue {
  private readonly _queue: Array<DispatchQueueItem> = [];

  private _active = false;

  private async processQueue() {
    while (this._queue.length > 0) {
      const item = this._queue.splice(0, 1)[0];
      try {
        item.resolve(await item.task());
      } catch (reason) {
        item.reject(reason);
      }
    }
    this._active = false;
  }

  public dispatch<T>(task: () => Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this._queue.push({
        resolve: resolve,
        reject: reject,
        task: task,
      });
      if (this._active) return;
      this._active = true;
      setTimeout((): void => void this.processQueue(), 0);
    });
  }
}