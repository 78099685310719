import App from "./App.vue"
import { createApp, reactive } from "vue"
import router from "./router"
import { Vue } from "@liveswitch/sdk";

Vue.reactive = reactive;

createApp(App)
  .use(router)
  .mount('#app');
