import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import ConnectionStats from "../ConnectionStats";
import EventLogger from "../event/Logger";
import EventOwner from "../core/EventOwner";
import DataChannelStats from "../DataChannelStats";
import DispatchQueue from "../core/DispatchQueue";
import Guard from "../core/Guard";
import MediaEvent from "./models/MediaEvent";
import MediaStats from "../MediaStats";
import MediaType from "../media/models/MediaType";
import Message from "./models/Message";
import PromiseCompletionSource from "../core/PromiseCompletionSource";
import Reactive from "../core/Reactive";
import RemoteAttendeeUpdateEvent from "../media/models/RemoteAttendeeUpdateEvent";
import RemoteMedia from "../media/RemoteMedia";
import RemoteMediaCollection from "../media/RemoteMediaCollection";
import RemoteTrackPriority from "../models/RemoteTrackPriority";
import RemoteTrackStatus from "../media/models/RemoteTrackStatus";
import RemoteVideoTrack from "../media/RemoteVideoTrack";
import SubscribedView from "../SubscribedView";
import Track from "./models/Track";
import TrackEvent from "./models/TrackEvent";
import TrackStats from "../TrackStats";
import TrackType from "../media/models/TrackType";
import Utility from "../core/Utility";
import VideoFrameSizeEvent from "../media/models/VideoFrameSizeEvent";
import ViewSize from "./models/ViewSize";
import BitrateChangeType from "../models/BitrateChangeType";

// the order here is important as it is the order in which the tracks are processed in the SDP
const mediaTypes: MediaType[] = ["display", "user"];
const trackTypes: TrackType[] = ["audio", "video"];

const payloadTypeAudioLevelsDisplay = 0;
const payloadTypeAudioLevelsUser = 1;
const payloadTypeAudioUpdatedDisplay = 2;
const payloadTypeAudioUpdatedUser = 3;
const payloadTypeAudioRemovedDisplay = 4;
const payloadTypeAudioRemovedUser = 5;

export default class Connection extends ConnectionBase<Message> {
  private readonly _client: ClientModel;
  private readonly _init: ConnectionInit;
  private readonly _mediaActivated = new EventOwner<MediaEvent>();
  private readonly _mediaChannel: RTCDataChannel;
  private readonly _mediaChannelStats = new DataChannelStats();
  private readonly _mediaDeactivated = new EventOwner<MediaEvent>();
  private readonly _mediaNotificationQueue = new DispatchQueue();
  private readonly _mediaStats = new MediaStats();
  private readonly _medias = new Map<MediaType, Map<TrackType, RemoteMediaCollection>>();
  private readonly _mediasActive = new Map<MediaType, Map<TrackType, RemoteMediaCollection>>();
  private readonly _onAudioAttendeeBound: (ev: RemoteAttendeeUpdateEvent) => Promise<void>;
  private readonly _onAudioAttendeeUnbound: (ev: RemoteAttendeeUpdateEvent) => void;
  private readonly _onMediaChannelClose: () => void;
  private readonly _onMediaChannelClosing: () => void;
  private readonly _onMediaChannelError: (ev: any) => void;
  private readonly _onMediaChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onMediaChannelOpen: () => void;
  private readonly _onTrack: (ev: RTCTrackEvent) => void;
  private readonly _onVideoAttendeeBound: (ev: RemoteAttendeeUpdateEvent) => Promise<void>;
  private readonly _onVideoAttendeeUnbound: (ev: RemoteAttendeeUpdateEvent) => void;
  private readonly _onVideoFrameSizeChanged: (ev: VideoFrameSizeEvent) => void;
  private readonly _pixelFeedback: boolean;
  private readonly _stats = new ConnectionStats();
  private readonly _subscribedView: SubscribedView;
  private readonly _tracks = new Map<MediaType, Map<TrackType, Track[]>>();
  private readonly _trackLayerUpdated = new EventOwner<TrackEvent>();
  private readonly _trackPriorityUpdated = new EventOwner<TrackEvent>();
  private readonly _trackStatusUpdated = new EventOwner<TrackEvent>();
  private readonly _videoElements = globalThis.document?.getElementsByTagName("video");

  private readonly _bitrateDecreaseStep: number = 0.10;
  private readonly _bitrateIncreaseStep: number = 0.10;
  private readonly _degradationPreference: BitrateChangeType = "both";
  private readonly _healthyCheckThreshold: number = 10;
  private readonly _packetLossThreshold: number = 0.05;
  private readonly _unhealthyCheckThreshold: number = 3;

  private _answerUpdated: PromiseCompletionSource<Message>;
  private _healthyCounter = 0;
  private _openMediaIndex = 0;
  private _openMediaTypeIndex = 0;
  private _openTrackTypeIndex = 0;
  private _maxVisibleUser: number;
  private _mediaChannelBytesReceived = 0;
  private _mediaChannelBytesSent = 0;
  private _unhealthyCounter = 0;

  private _statsBatchDisplayVideo: Array<TrackStats> = null;
  private _statsBatchDisplayAudio: Array<TrackStats> = null;
  private _statsBatchUserVideo: Array<TrackStats> = null;
  private _statsBatchUserAudio: Array<TrackStats> = null;
  private _statsBatchSize = 15;

  public get audioDeviceId(): string {
    for (const mediaType of mediaTypes) {
      for (const media of this.getMedias(mediaType, "audio")) {
        return media.immutableAudioTrack.deviceId;
      }
    }
    return undefined;
  }
  public get mediaActivated(): EventOwner<MediaEvent> { return this._mediaActivated; }
  public get mediaChannelStats(): DataChannelStats { return this._mediaChannelStats; }
  public get mediaDeactivated(): EventOwner<MediaEvent> { return this._mediaDeactivated; }
  public get mediaStats(): MediaStats { return this._mediaStats; }
  public get requestedAudioDeviceId(): string {
    for (const mediaType of mediaTypes) {
      for (const media of this.getMedias(mediaType, "audio")) {
        return media.immutableAudioTrack.requestedDeviceId;
      }
    }
    return undefined;
  }
  public get stats(): ConnectionStats { return this._stats; }
  public get trackLayerUpdated(): EventOwner<TrackEvent> { return this._trackLayerUpdated; }
  public get trackPriorityUpdated(): EventOwner<TrackEvent> { return this._trackPriorityUpdated; }
  public get trackStatusUpdated(): EventOwner<TrackEvent> { return this._trackStatusUpdated; }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "EdgeConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      meetingId: init.meetingId,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: "Edge",
    });
    this._init = init;
    this._client = init.client;
    this._maxVisibleUser = init.maxVisibleUser;
    this._pixelFeedback = init.pixelFeedback;
    this._subscribedView = init.subscribedView;
    this._statsBatchDisplayAudio = new Array<TrackStats>();
    this._statsBatchDisplayVideo = new Array<TrackStats>();
    this._statsBatchUserAudio = new Array<TrackStats>();
    this._statsBatchUserVideo = new Array<TrackStats>();

    this._onAudioAttendeeBound = this.onAudioAttendeeBound.bind(Reactive.wrap(this));
    this._onAudioAttendeeUnbound = this.onAudioAttendeeUnbound.bind(Reactive.wrap(this));
    this._onMediaChannelClose = this.onMediaChannelClose.bind(Reactive.wrap(this));
    this._onMediaChannelClosing = this.onMediaChannelClosing.bind(Reactive.wrap(this));
    this._onMediaChannelError = this.onMediaChannelError.bind(Reactive.wrap(this));
    this._onMediaChannelMessage = this.onMediaChannelMessage.bind(Reactive.wrap(this));
    this._onMediaChannelOpen = this.onMediaChannelOpen.bind(Reactive.wrap(this));
    this._onTrack = this.onTrack.bind(Reactive.wrap(this));
    this._onVideoAttendeeBound = this.onVideoAttendeeBound.bind(Reactive.wrap(this));
    this._onVideoAttendeeUnbound = this.onVideoAttendeeUnbound.bind(Reactive.wrap(this));
    this._onVideoFrameSizeChanged = this.onVideoFrameSizeChanged.bind(Reactive.wrap(this));

    this._mediaChannel = this.connection.createDataChannel("media");
    this._mediaChannel.binaryType = "arraybuffer";

    for (const mediaType of mediaTypes) {
      this._medias.set(mediaType, new Map<TrackType, RemoteMediaCollection>());
      this._mediasActive.set(mediaType, new Map<TrackType, RemoteMediaCollection>());
      this._tracks.set(mediaType, new Map<TrackType, Track[]>());
      for (const trackType of trackTypes) {
        const medias = new RemoteMediaCollection();
        const mediasActive = new RemoteMediaCollection();
        const tracks: Track[] = [];
        this._medias.get(mediaType).set(trackType, medias);
        this._mediasActive.get(mediaType).set(trackType, mediasActive);
        this._tracks.get(mediaType).set(trackType, tracks);
        let count = 0;
        if (mediaType == "display" && trackType == "audio") count = init.maxAudibleDisplay;
        if (mediaType == "display" && trackType == "video") count = init.maxVisibleDisplay;
        if (mediaType == "user" && trackType == "audio") count = init.maxAudibleUser;
        if (mediaType == "user" && trackType == "video") count = init.maxVisibleUser;
        for (let i = 0; i < count; i++) {
          const media = new RemoteMedia(Reactive.wrap(this), init.attendeeId, mediaType);
          if (trackType == "audio") {
            media.audioTrackIndex = i;
            media.attendeeBound.bind(this._onAudioAttendeeBound);
            media.attendeeUnbound.bind(this._onAudioAttendeeUnbound);
            mediasActive.tryAdd(media); // audio is always active
          }
          if (trackType == "video") {
            media.attendeeBound.bind(this._onVideoAttendeeBound);
            media.attendeeUnbound.bind(this._onVideoAttendeeUnbound);
            media.videoTrack.frameSizeChanged.bind(this._onVideoFrameSizeChanged);
            media.videoTrackIndex = i;
          }

          const transceiver = this.connection.addTransceiver(trackType, { direction: "recvonly" });
          tracks.push({
            index: i,
            media: media,
            priority: "low",
            receiver: transceiver.receiver,
            receiverStats: new TrackStats(),
            spatialLayerIndex: 0,
            status: "enabled",
            temporalLayerIndex: 0,
            viewSize: { width: -1, height: -1 },
          });
          medias.tryAdd(media);
        }
      }
    }
    this.attachEventHandlers();
  }

  private static isHidden(element: HTMLElement): boolean {
    if (element.style.display == "none") return true;
    if (element.style.visibility == "hidden") return true;
    if (!Utility.isNullOrUndefined(element.style.opacity)) {
      const opacity = parseFloat(element.style.opacity);
      if (opacity <= 0.05) return true;
    }
    return false;
  }

  private async activateMedia(media: RemoteMedia, mediaType: MediaType, trackType: TrackType, attendeeId: string, add: boolean): Promise<void> {
    const mediasActive = this.getMediasActive(mediaType, trackType);
    const existingMedia = mediasActive.getByAttendeeId(attendeeId);
    if (existingMedia) this.deactivateMedia(existingMedia, mediaType, trackType);
    const attendee = Utility.isReplicatedAttendeeId(attendeeId) ? null : await this._subscribedView.subscribeToAttendee(attendeeId, "visibleMedia");
    const previousAttendee = media.attendee;
    if (attendee) await media.bindAttendee(attendee);
    if (add) mediasActive.tryAdd(media);
    this._mediaActivated.dispatch({
      attendee: attendee,
      attendeeId: attendeeId,
      connection: this,
      media: media,
      mediaType: mediaType,
      previousAttendee: previousAttendee,
      previousAttendeeId: previousAttendee?.id,
      trackType: trackType,
    });
  }

  private deactivateMedia(media: RemoteMedia, mediaType: MediaType, trackType: TrackType): void {
    const oldAttendee = media.attendee;
    if (!oldAttendee) return;
    if (trackType == "video") this.getMediasActive(mediaType, trackType).tryRemove(media.id);
    media.unbindAttendee();
    this._mediaDeactivated.dispatch({
      attendee: oldAttendee,
      attendeeId: oldAttendee?.id,
      connection: this,
      media: media,
      mediaType: mediaType,
      trackType: trackType,
    });
    if (trackType == "video") {
      const track = this._tracks.get(mediaType).get(trackType).find(t => t.media == media);
      if (track) {
        track.spatialLayerIndex = 0;
        track.status = "enabled";
        track.temporalLayerIndex = 0;
        track.viewSize = { width: -1, height: -1 };
      }
    }
  }

  private getNextRemoteMedia(): RemoteMedia {
    while (this._openMediaTypeIndex < mediaTypes.length) {
      const mediaType = mediaTypes[this._openMediaTypeIndex];
      const trackType = trackTypes[this._openTrackTypeIndex];
      const medias = this.getMedias(mediaType, trackType);
      if (this._openMediaIndex < medias.length) return medias[this._openMediaIndex++];
      this._openMediaIndex = 0;
      this._openTrackTypeIndex++;
      if (this._openTrackTypeIndex < trackTypes.length) continue;
      this._openTrackTypeIndex = 0;
      this._openMediaTypeIndex++;
    }
  }

  private async onAudioAttendeeBound(e: RemoteAttendeeUpdateEvent): Promise<void> {
    this.tryLinkAudio(e.media, e.attendee?.id);
  }

  private onAudioAttendeeUnbound(e: RemoteAttendeeUpdateEvent): void {
    this.tryLinkAudio(e.media, e.attendee?.id);
  }

  private onMediaChannelClose(): void {
    const message = `Edge connection media channel has closed.`;
    void this.eventLogger.debug("onMediaChannelClose", message);
  }

  private onMediaChannelClosing(): void {
    const message = `Edge connection media channel is closing.`;
    void this.eventLogger.debug("onMediaChannelClosing", message);
  }

  private onMediaChannelError(ev: RTCErrorEvent): void {
    const message = `Edge connection media channel has failed. ${ev.error?.errorDetail ?? ev.error ?? ""}`.trimEnd();
    void this.eventLogger.debug("onMediaChannelError", message);
  }

  private onMediaChannelMessage(ev: MessageEvent<any>): void {
    const arrayBuffer = <ArrayBuffer>ev.data;
    this._mediaChannelBytesReceived += arrayBuffer.byteLength;
    const buffer = new Uint8Array(arrayBuffer);
    if (buffer.length == 0) return;
    const payloadType = buffer[0];
    if (payloadType == payloadTypeAudioLevelsDisplay || payloadType == payloadTypeAudioLevelsUser) {
      if (buffer.length % 2 != 1) return;
      const mediaType = payloadType == payloadTypeAudioLevelsDisplay ? "display" : "user";
      const mediasActive = this.getMediasActive(mediaType, "audio");
      for (let i = 1; i < buffer.length; i += 2) {
        const trackIndex = buffer[i];
        const audioLevel = buffer[i + 1] / 255;
        mediasActive.getByTrackIndex(trackIndex, mediaType, "audio")?.audioTrack?.updateLevel(audioLevel);
      }
    } else if (payloadType == payloadTypeAudioUpdatedDisplay || payloadType == payloadTypeAudioUpdatedUser) {
      const mediaType = payloadType == payloadTypeAudioUpdatedDisplay ? "display" : "user";
      const trackIndex = buffer[1];
      const attendeeId = Utility.parseGuid(buffer.subarray(2, 18));
      void this._mediaNotificationQueue.dispatch(async () => {
        try {
          await this.tryActivateMedia(attendeeId, mediaType, "audio", trackIndex);
        } catch (error) {
          if (this.state != "closed") throw error;
        }
      });
    } else if (payloadType == payloadTypeAudioRemovedDisplay || payloadType == payloadTypeAudioRemovedUser) {
      const mediaType = payloadType == payloadTypeAudioRemovedDisplay ? "display" : "user";
      const trackIndex = buffer[1];
      const attendeeId = Utility.parseGuid(buffer.subarray(2, 18));
      void this._mediaNotificationQueue.dispatch(async () => {
        this.tryDeactivateMedia(attendeeId, mediaType, "audio", trackIndex);
      });
    } else {
      void this.eventLogger.warning("onMediaChannelMessage", `Unexpected "${payloadType}" edge media notification.`);
    }
  }

  private onMediaChannelOpen(): void {
    const message = `Edge connection media channel has opened.`;
    void this.eventLogger.debug("onMediaChannelOpen", message);
  }

  private onTrack(ev: RTCTrackEvent): void {
    const track = ev.track;
    void this.eventLogger.verbose("onTrack", `Remote ${track.kind} track raised.`);
    void this.eventQueue.dispatch(async () => {
      const start = performance.now();
      const media = this.getNextRemoteMedia();
      if (track.kind == "audio") {
        await media.startAudioStream(track);
      } else {
        await media.startVideoStream(track);
      }
      void this.eventLogger.debug("onTrack", `Remote ${track.kind} track ${track.id} bound to remote ${media.type} media.`, performance.now() - start);
    });
  }

  private async onVideoAttendeeBound(e: RemoteAttendeeUpdateEvent): Promise<void> {
    this.tryLinkVideo(e.media, e.attendee?.id);
  }

  private onVideoAttendeeUnbound(e: RemoteAttendeeUpdateEvent): void {
    this.tryLinkVideo(e.media, e.attendee?.id);
  }

  private onVideoFrameSizeChanged(e: VideoFrameSizeEvent): void {
    if (e.track.media.attendee?.isLocal) return;
    const status = (e.frameSize.width == 1 && e.frameSize.height == 1) ? "disabled" : "enabled";
    const track = <RemoteVideoTrack>e.track;
    this.updateTrackStatus(status, track.media.type, "video", track.media.videoTrackIndex, true);
  }

  private async tryActivateMedia(attendeeId: string, mediaType: MediaType, trackType: TrackType, trackIndex: number): Promise<boolean> {
    if (Utility.isFakeAttendeeId(attendeeId)) attendeeId = null;
    const mediasActive = this.getMediasActive(mediaType, trackType);
    const media = mediasActive.getByTrackIndex(trackIndex, mediaType, trackType);
    if (media) {
      if (attendeeId == media.attendee?.id) return false;
      if (attendeeId) {
        await this.activateMedia(media, mediaType, trackType, attendeeId, false);
        return true;
      }
      if (media.attendee?.id) {
        this.deactivateMedia(media, mediaType, trackType);
        return true;
      }
      return false;
    }
    if (attendeeId) {
      // audio is always active, so trackType is 'video' here
      const media = this.getMedias(mediaType, trackType).getByTrackIndex(trackIndex, mediaType, trackType);
      if (!media) return false;
      await this.activateMedia(media, mediaType, trackType, attendeeId, true);
      return true;
    }
  }

  private tryDeactivateMedia(attendeeId: string, mediaType: MediaType, trackType: TrackType, trackIndex: number): boolean {
    if (Utility.isFakeAttendeeId(attendeeId)) attendeeId = null;
    const mediasActive = this.getMediasActive(mediaType, trackType);
    const media = mediasActive.getByTrackIndex(trackIndex, mediaType, trackType);
    if (!media || !media.attendee?.id || media.attendee?.id != attendeeId) return false;
    this.deactivateMedia(media, mediaType, trackType);
    return true;
  }

  private isHealthy(packetLoss: number): boolean {
    return packetLoss < this._packetLossThreshold;
  }

  private tryHealthCheck(): boolean {
    let unhealthyCount = 0;

    const trackStatsDisplay = this.getTrackReceiverStats("display", "video");
    for (const trackStatDisplay of trackStatsDisplay) {
      if (this.isHealthy(trackStatDisplay.packetLoss)) continue;
      unhealthyCount++;
    }

    const trackStatsUser = this.getTrackReceiverStats("user", "video");
    for (const trackStatUser of trackStatsUser) {
      if (this.isHealthy(trackStatUser.packetLoss)) continue;
      unhealthyCount++;
    }

    if (unhealthyCount == 0) {
      void this.eventLogger.verbose("tryHealthCheck", "All edge video tracks are healthy.");
      return this.tryHealthCheckIncrease();
    }

    void this.eventLogger.debug("tryHealthCheck", `Some edge video tracks (${unhealthyCount}) are unhealthy.`);
    return this.tryHealthCheckDecrease();
  }

  private tryHealthCheckDecrease(): boolean {
    this._healthyCounter = 0;
    this._unhealthyCounter++;

    if (this._unhealthyCounter < this._unhealthyCheckThreshold) return false;
    this._unhealthyCounter = 0;

    this.decreaseBitrate();
    return true;
  }

  private tryHealthCheckIncrease(): boolean {
    this._unhealthyCounter = 0;
    this._healthyCounter++;

    if (this._healthyCounter < this._healthyCheckThreshold) return false;
    this._healthyCounter = 0;

    this.increaseBitrate();
    return true;
  }

  private tryLinkAudio(audioMedia: RemoteMedia, attendeeId?: string): boolean {
    if (audioMedia.linkedRemoteVideo) {
      audioMedia.linkedRemoteVideo.linkedRemoteAudio = null;
      audioMedia.linkedRemoteVideo = null;
    }
    if (!attendeeId) return false;
    const videoMedia = this.getMedias(audioMedia.type, "video").getByAttendeeId(attendeeId);
    if (!videoMedia) return false;
    audioMedia.linkedRemoteVideo = videoMedia;
    videoMedia.linkedRemoteAudio = audioMedia;
    return true;
  }

  private tryLinkVideo(videoMedia: RemoteMedia, attendeeId?: string): boolean {
    if (videoMedia.linkedRemoteAudio) {
      videoMedia.linkedRemoteAudio.linkedRemoteVideo = null;
      videoMedia.linkedRemoteAudio = null;
    }
    if (!attendeeId) return false;
    const audioMedia = this.getMedias(videoMedia.type, "audio").getByAttendeeId(attendeeId);
    if (!audioMedia) return false;
    audioMedia.linkedRemoteVideo = videoMedia;
    videoMedia.linkedRemoteAudio = audioMedia;
    return true;
  }

  private tryViewSizeUpdate(): boolean {
    if (!this._pixelFeedback) return false;
    if (!this._videoElements) return false;
    const videoElements = Array.from(this._videoElements);
    const viewSizes: { [key in MediaType]: ViewSize[] } = {
      display: [],
      user: [],
    };
    const viewportHeight = Math.max(globalThis.document.documentElement.clientHeight, globalThis.window.innerHeight);
    const viewportWidth = Math.max(globalThis.document.documentElement.clientWidth, globalThis.window.innerWidth);
    for (const mediaType of mediaTypes) {
      const tracks = this.getTracks(mediaType, "video");
      for (const track of tracks) {
        if (!track.media.attendee) continue;
        if (track.media.attendee.isLocal) continue;
        const trackIndex = track.index;
        const viewSize: ViewSize = { trackIndex, width: 0, height: 0 };
        if (track.media.stream) {
          const videoSizes = videoElements.filter(ve => ve.srcObject == track.media.stream).map(ve => {
            if (Connection.isHidden(ve)) return { width: 0, height: 0, pixels: 0 };
            let rect = ve.getBoundingClientRect();
            let width = rect.width;
            let height = rect.height;
            if (width > 0 && height > 0) {
              if (rect.top < 0) height += rect.top;
              if (rect.left < 0) width += rect.left;
              if (rect.bottom > viewportHeight) height += viewportHeight - rect.bottom;
              if (rect.right > viewportWidth) width += viewportWidth - rect.right;
              width = Math.round(Math.max(width, 0));
              height = Math.round(Math.max(height, 0));
            }
            return { width, height, pixels: width * height };
          });
          if (videoSizes.length) {
            videoSizes.sort((a, b) => b.pixels - a.pixels);
            const [{ width, height }] = videoSizes;
            viewSize.width = width;
            viewSize.height = height;
          }
        }
        if (track.viewSize.width == viewSize.width && track.viewSize.height == viewSize.height) continue;
        viewSizes[mediaType].push(viewSize);
        track.viewSize = viewSize;
        void this.eventLogger.debug("tryViewSizeUpdate", `View size of ${mediaType} video track ${trackIndex} updated to ${viewSize.width}x${viewSize.height}.`);
      }
    }
    if (!viewSizes.display.length && !viewSizes.user.length) return false;
    this.sendNotification({
      type: "viewSizesUpdated",
      viewSizes: viewSizes,
    });
    return true;
  }

  private updateTrackLayer(spatialLayerIndex: number, temporalLayerIndex: number, mediaType: MediaType, trackType: TrackType, trackIndex: number): void {
    const track = this._tracks.get(mediaType).get(trackType)[trackIndex];
    const media = this.getMedias(mediaType, trackType).getByTrackIndex(trackIndex, mediaType, trackType);
    let updated = false;
    if (!Utility.isNullOrUndefined(spatialLayerIndex) && spatialLayerIndex != track.spatialLayerIndex) {
      updated = true;
      track.spatialLayerIndex = spatialLayerIndex;
      void this.eventLogger.debug("updateTrackLayer", `Spatial layer index of ${mediaType} ${trackType} track ${trackIndex + 1} updated to ${spatialLayerIndex}.`);
    }
    if (!Utility.isNullOrUndefined(temporalLayerIndex) && temporalLayerIndex != track.temporalLayerIndex) {
      updated = true;
      track.temporalLayerIndex = temporalLayerIndex;
      void this.eventLogger.debug("updateTrackLayer", `Temporal layer index of ${mediaType} ${trackType} track ${trackIndex + 1} updated to ${temporalLayerIndex}.`);
    }
    if (!updated) return;
    this._trackLayerUpdated.dispatch({
      connection: this,
      media: media,
      mediaType: mediaType,
      track: track,
      trackIndex: trackIndex,
      trackType: trackType,
    });
  }

  private updateTrackStatus(status: RemoteTrackStatus, mediaType: MediaType, trackType: TrackType, trackIndex: number, inband: boolean): void {
    const track = this._tracks.get(mediaType).get(trackType)[trackIndex];
    const media = this.getMedias(mediaType, trackType).getByTrackIndex(trackIndex, mediaType, trackType);
    if (status == track.status) return;
    track.status = status;
    void this.eventLogger.debug("updateTrackStatus", `Status of ${mediaType} ${trackType} track ${trackIndex + 1} updated to ${status}.`, undefined, {
      inband: inband ? "true" : "false"
    });
    this._trackStatusUpdated.dispatch({
      connection: this,
      media: media,
      mediaType: mediaType,
      track: track,
      trackIndex: trackIndex,
      trackType: trackType,
    });
  }

  protected attachEventHandlers(): void {
    super.attachEventHandlers();
    this.connection.addEventListener("track", this._onTrack);
    this._mediaChannel.addEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.addEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.addEventListener("error", this._onMediaChannelError);
    this._mediaChannel.addEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.addEventListener("open", this._onMediaChannelOpen);
  }

  protected detachEventHandlers(): void {
    this.connection.removeEventListener("track", this._onTrack);
    this._mediaChannel.removeEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.removeEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.removeEventListener("error", this._onMediaChannelError);
    this._mediaChannel.removeEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.removeEventListener("open", this._onMediaChannelOpen);
    super.detachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    const response = await this._client.negotiate({
      audioLevelIntervalDisplay: this._init.audioLevelIntervalDisplay,
      audioLevelIntervalUser: this._init.audioLevelIntervalUser,
      compatibilityMode: this._init.compatibilityMode,
      degradationPreference: this._degradationPreference,
      discardAudio: this._init.discardAudio,
      discardVideo: this._init.discardVideo,
      localLoopbackAudio: this._init.localLoopbackAudio,
      localLoopbackVideo: this._init.localLoopbackVideo,
      maxAudibleUser: this.getMedias("user", "audio").count,
      maxAudibleDisplay: this.getMedias("display", "audio").count,
      maxVisibleUser: this.getMedias("user", "video").count,
      maxVisibleDisplay: this.getMedias("display", "video").count,
      offer: offer,
    }, abortSignal);
    if (!Utility.isNullOrUndefined(response.answer)) return response.answer;
    if (Utility.isNullOrUndefined(response.serverUrl)) throw new Error(`Unexpected response from edge server: ${JSON.stringify(response)}`);
    this._client.reassign(response.serverUrl);
    return await this.negotiate(offer, abortSignal);
  }

  protected async onOpening(): Promise<void> {
    await super.onOpening();
    this._openMediaIndex = 0;
    this._openMediaTypeIndex = 0;
    this._openTrackTypeIndex = 0;
  }

  protected onTerminating(): void {
    for (const mediaType of mediaTypes) {
      for (const trackType of trackTypes) {
        const mediasActive = this.getMediasActive(mediaType, trackType);
        for (let i = mediasActive.length - 1; i >= 0; i--) {
          this.deactivateMedia(mediasActive[i], mediaType, trackType);
        }
      }
      for (const media of this.getMedias(mediaType, "audio")) media.stopAudioStream();
      for (const media of this.getMedias(mediaType, "video")) media.stopVideoStream();
    }
    super.onTerminating();
  }

  protected onTerminated(): void {
    this._answerUpdated?.reject("Connection closed.");
    super.onTerminated();
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "answerUpdated") {
      if (this._answerUpdated) this._answerUpdated.resolve(notification);
    } else if (notification.type == "layerUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        this.updateTrackLayer(notification.spatialLayerIndex, notification.temporalLayerIndex, notification.mediaType, notification.trackType, notification.trackIndex);
      });
    } else if (notification.type == "mediaUpdated" && notification.trackType != "audio") {
      void this._mediaNotificationQueue.dispatch(async () => {
        try {
          await this.tryActivateMedia(notification.attendeeId, notification.mediaType, notification.trackType, notification.trackIndex);
        } catch (error) {
          if (this.state != "closed") throw error;
        }
      });
    } else if (notification.type == "mediaRemoved" && notification.trackType != "audio") {
      void this._mediaNotificationQueue.dispatch(async () => {
        this.tryDeactivateMedia(notification.attendeeId, notification.mediaType, notification.trackType, notification.trackIndex);
      });
    } else if (notification.type == "statusUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        this.updateTrackStatus(notification.status, notification.mediaType, notification.trackType, notification.trackIndex, false);
      });
    } else {
      void this.eventLogger.warning("processNotification", `Unexpected "${notification.type}" edge notification.`);
    }
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.renegotiate({
      offer: offer,
    }, abortSignal)).answer;
  }

  /** @internal */
  public getMedias(mediaType: MediaType, trackType: TrackType): RemoteMediaCollection {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._medias.get(mediaType).get(trackType);
  }

  /** @internal */
  public getMediasActive(mediaType: MediaType, trackType: TrackType): RemoteMediaCollection {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._mediasActive.get(mediaType).get(trackType);
  }

  /** @internal */
  public getTracks(mediaType: MediaType, trackType: TrackType): Track[] {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._tracks.get(mediaType).get(trackType);
  }

  /** @internal */
  public getTrackReceivers(mediaType: MediaType, trackType: TrackType): RTCRtpReceiver[] {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._tracks.get(mediaType).get(trackType).map(x => x.receiver);
  }

  /** @internal */
  public getTrackReceiverStats(mediaType: MediaType, trackType: TrackType): TrackStats[] {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._tracks.get(mediaType).get(trackType).map(x => x.receiverStats);
  }

  protected processConnectionStats(stats: Map<string, any>): void {
    this._stats.updateFromConnection(stats);
  }

  protected processRTCRtpReceiversToBatches(stats: TrackStats[], type: TrackType, mediaType: MediaType ): void {
    for (var i = 0, l = stats.length; i < l; i++) {
      var stat = stats[i];
      if (!stat.originAttendeeId) continue;
      if (type === 'audio') {
        if (mediaType == 'display') {
          this._statsBatchDisplayAudio.push();
        } else if (mediaType == 'user') {
          this._statsBatchUserAudio.push(stat.toJson());
        }
      } else if (type === 'video') {
        if (mediaType == 'display') {
          this._statsBatchDisplayVideo.push(stat.toJson());
        } else if (mediaType == 'user') {
          this._statsBatchUserVideo.push(stat.toJson());
        }
      }
    }
  }

  protected processReceiverStats(stats: Map<number, Map<string, any>>): void {
    for (const mediaType of mediaTypes) {
      for (const trackType of trackTypes) {
        const receivers = this.getTrackReceivers(mediaType, trackType);
        const trackReceiverStats = this.getTrackReceiverStats(mediaType, trackType);
        for (let i = 0; i < receivers.length; i++) {
          const ssrcs = receivers[i].getSynchronizationSources();
          if (ssrcs.length == 0) continue;
          trackReceiverStats[i].updateFromReceiver(stats.get(ssrcs[0].source));
        }
      }
    }

    for (const mediaType of mediaTypes) {
      for (const trackType of trackTypes) {
        const medias = this.getMedias(mediaType, trackType);
        const trackReceiverStats = this.getTrackReceiverStats(mediaType, trackType);
        for (let i = 0; i < medias.length; i++) {
          let media = medias[i];
          let trackReceiver = trackReceiverStats[i];
          if (trackType == "audio") { 
            media.immutableAudioTrack.updateStats(trackReceiver, this._stats)
          }
          if (trackType == "video") {
            media.immutableVideoTrack.updateStats(trackReceiver, this._stats);
          }
          trackReceiver.originAttendeeId = media.attendee?.id;
        }
      }
    }
    
    this._mediaChannelStats.update(this._mediaChannelBytesSent, this._mediaChannelBytesReceived, performance.now());

    let displayAudioStats = this.getTrackReceiverStats("display", "audio");
    let displayVideoStats = this.getTrackReceiverStats("display", "video");
    let userAudioStats = this.getTrackReceiverStats("user", "audio");
    let userVideoStats = this.getTrackReceiverStats("user", "video");

    this._mediaStats.update([]
      .concat(displayAudioStats)
      .concat(displayVideoStats)
      .concat(userAudioStats)
      .concat(userVideoStats), performance.now());

    this.processRTCRtpReceiversToBatches(displayAudioStats, 'audio', 'display');
    this.processRTCRtpReceiversToBatches(displayVideoStats, 'video', 'display');
    this.processRTCRtpReceiversToBatches(userAudioStats, 'audio', 'user');
    this.processRTCRtpReceiversToBatches(userVideoStats, 'video', 'user');
      
    if (this._statsBatchDisplayAudio.length >= this._statsBatchSize) {
      const batch = this._statsBatchDisplayAudio.splice(0);
      this._statsBatchDisplayAudio = [];
        this.sendNotification({
        type: "clientStats",
        clientStatisticsTrackType: 'audio',
        mediaType: 'display',
        clientStats: batch
      });
    }
    if (this._statsBatchDisplayVideo.length >= this._statsBatchSize) {
      const batch = this._statsBatchDisplayVideo.splice(0);
      this._statsBatchDisplayVideo = [];
        this.sendNotification({
        type: "clientStats",
        clientStatisticsTrackType: 'video',
        mediaType: 'display',
        clientStats: batch
      });
    }
    if (this._statsBatchUserAudio.length >= this._statsBatchSize) {
      const batch = this._statsBatchUserAudio.splice(0);
      this._statsBatchUserAudio = [];
        this.sendNotification({
        type: "clientStats",
        clientStatisticsTrackType: 'audio',
        mediaType: 'user',
        clientStats: batch
      });
    }
    if (this._statsBatchUserVideo.length >= this._statsBatchSize) {
      const batch = this._statsBatchUserVideo.splice(0);
      this._statsBatchUserVideo = [];
        this.sendNotification({
        type: "clientStats",
        clientStatisticsTrackType: 'video',
        mediaType: 'user',
        clientStats: batch
      });
    }

    this.tryHealthCheck();
    this.tryViewSizeUpdate();
  }

  public decreaseBitrate(step?: number): void {
    this.sendNotification({
      bitrateStep: Math.max(0, Math.min(1, step ?? this._bitrateDecreaseStep)),
      type: "bitrateDecrease",
    });
  }

  public getTrackPriority(mediaType: MediaType, trackType: TrackType, trackIndex: number): RemoteTrackPriority {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    Guard.isNotNullOrUndefined(trackType, "trackIndex");
    const tracks = this._tracks.get(mediaType).get(trackType);
    Guard.isGreaterThanOrEqualTo(trackIndex, 0, "trackIndex");
    Guard.isLessThan(trackIndex, tracks.length, "trackIndex");
    return tracks[trackIndex].priority;
  }

  public getTrackSpatialLayerIndex(mediaType: MediaType, trackType: TrackType, trackIndex: number): number {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    Guard.isNotNullOrUndefined(trackType, "trackIndex");
    const tracks = this._tracks.get(mediaType).get(trackType);
    Guard.isGreaterThanOrEqualTo(trackIndex, 0, "trackIndex");
    Guard.isLessThan(trackIndex, tracks.length, "trackIndex");
    return tracks[trackIndex].spatialLayerIndex;
  }

  public getTrackStatus(mediaType: MediaType, trackType: TrackType, trackIndex: number): RemoteTrackStatus {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    Guard.isNotNullOrUndefined(trackType, "trackIndex");
    const tracks = this._tracks.get(mediaType).get(trackType);
    Guard.isGreaterThanOrEqualTo(trackIndex, 0, "trackIndex");
    Guard.isLessThan(trackIndex, tracks.length, "trackIndex");
    return tracks[trackIndex].status;
  }

  public getTrackTemporalLayerIndex(mediaType: MediaType, trackType: TrackType, trackIndex: number): number {
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    Guard.isNotNullOrUndefined(trackType, "trackIndex");
    const tracks = this._tracks.get(mediaType).get(trackType);
    Guard.isGreaterThanOrEqualTo(trackIndex, 0, "trackIndex");
    Guard.isLessThan(trackIndex, tracks.length, "trackIndex");
    return tracks[trackIndex].temporalLayerIndex;
  }

  public increaseBitrate(step?: number): void {
    this.sendNotification({
      bitrateStep: Math.max(0, Math.min(1, step ?? this._bitrateIncreaseStep)),
      type: "bitrateIncrease",
    });
  }

  public async setAudioDevice(deviceId?: string): Promise<void> {
    for (const mediaType of mediaTypes) {
      for (const media of this.getMedias(mediaType, "audio")) {
        await media.immutableAudioTrack.setDevice(deviceId);
      }
    }
  }

  public setMaxVisibleUser(maxVisibleUser: number): Promise<void> {
    Guard.isNotNullOrUndefined(maxVisibleUser, "maxVisibleUser");
    return this.eventQueue.dispatch(async () => {
      const maxVisibleUserUpdated = Math.min(maxVisibleUser, this.getMedias("user", "video").length);
      if (maxVisibleUserUpdated == this._maxVisibleUser) return;
      this._maxVisibleUser = maxVisibleUserUpdated;
      void this.eventLogger.debug("setMaxVisibleUser", `Setting max visible user media to ${maxVisibleUserUpdated}.`);
      this.sendNotification({
        type: "videoCountsUpdated",
        userVideoCount: maxVisibleUserUpdated
      });
    });
  }

  public async setTrackPriority(priority: RemoteTrackPriority, mediaType: MediaType, trackType: TrackType, trackIndex: number): Promise<void> {
    Guard.isNotNullOrUndefined(priority, "priority");
    Guard.isNotNullOrUndefined(mediaType, "mediaType");
    Guard.isNotNullOrUndefined(trackType, "trackType");
    Guard.isNotNullOrUndefined(trackType, "trackIndex");
    const tracks = this._tracks.get(mediaType).get(trackType);
    Guard.isGreaterThanOrEqualTo(trackIndex, 0, "trackIndex");
    Guard.isLessThan(trackIndex, tracks.length, "trackIndex");
    const track = tracks[trackIndex];
    const media = this.getMedias(mediaType, trackType).getByTrackIndex(trackIndex, mediaType, trackType);
    Guard.isNotNullOrUndefined(media, "media");
    return this.eventQueue.dispatch(async () => {
      if (priority == track.priority) return;
      track.priority = priority;
      void this.eventLogger.debug("setTrackPriority", `Setting priority of ${mediaType} ${trackType} track ${trackIndex + 1} to ${priority}.`);
      this.sendNotification({
        type: "priorityUpdated",
        priority: priority,
        mediaType: mediaType,
        trackType: trackType,
        trackIndex: trackIndex,
      });
      this._trackPriorityUpdated.dispatch({
        connection: this,
        media: media,
        mediaType: mediaType,
        track: track,
        trackIndex: trackIndex,
        trackType: trackType,
      });
    });
  }

  public async useNextAudioDevice(): Promise<void> {
    for (const mediaType of mediaTypes) {
      for (const media of this.getMedias(mediaType, "audio")) {
        await media.immutableAudioTrack.useNextDevice();
      }
    }
  }

  public async usePreviousAudioDevice(): Promise<void> {
    for (const mediaType of mediaTypes) {
      for (const media of this.getMedias(mediaType, "audio")) {
        await media.immutableAudioTrack.usePreviousDevice();
      }
    }
  }
}