export enum TraceLogEnvironmentLength {
    Max = 20
}
 
export enum TraceLogComponentLength {
    Max = 255
}

export enum TraceLogOperationLength {
    Max = 255
}