
import UserMedia from "@/classes/QRUserMedia";
import { defineComponent } from "vue";

type State = "starting" | "started" | "stopping" | "stopped";

export default defineComponent({
  data(): {
    state: State;
    userMedia: UserMedia;
  } {
    return {
      state: "stopped",
      userMedia: new UserMedia(true, true),
    };
  },
  methods: {
    async start() {
      this.state = "starting";
      try {
        await this.userMedia.start();
        this.state = "started";
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        alert(error);
        await this.stop();
      }
    },
    async stop() {
      this.state = "stopping";
      try {
        await this.userMedia.stop();
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        alert(error);
      } finally {
        this.state = "stopped";
      }
    },
  },
});
