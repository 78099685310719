import { LocalVideoTrack } from "@liveswitch/sdk";
import FrameRateMonitor from "./FrameRateMonitor";
import QR from "./QR";
import QRFrameData from "./QRFrameData";

export default class QRTransformer {
  private readonly _frameRateMonitor = new FrameRateMonitor();
  private readonly _generator: MediaStreamVideoTrackGenerator;
  private readonly _processor: MediaStreamTrackProcessor<VideoFrame>;
  private readonly _qr = new QR();
  private readonly _targetFrameRate: number | undefined;
  private readonly _targetHeight: number | undefined;
  private readonly _targetWidth: number | undefined;
  private readonly _track: LocalVideoTrack;
  private readonly _transform: TransformStream<VideoFrame, VideoFrame>;

  public get trackStream(): MediaStreamVideoTrack { return this._generator; }

  public constructor(track: LocalVideoTrack, trackStream: MediaStreamVideoTrack, constraints: MediaTrackConstraints) {
    this._track = track;
    this._targetFrameRate = this.getConstraintValue(constraints.frameRate);
    this._targetHeight = this.getConstraintValue(constraints.height);
    this._targetWidth = this.getConstraintValue(constraints.width);
    this._generator = new MediaStreamTrackGenerator({ kind: "video" });
    this._processor = new MediaStreamTrackProcessor({ track: trackStream });
    this._transform = new TransformStream<VideoFrame, VideoFrame>(this);
    void this._processor.readable.pipeThrough(this._transform).pipeTo(this._generator.writable);
  }

  private getConstraintValue(value: ConstrainDouble | ConstrainULong | undefined): number | undefined {
    if (value == undefined) return undefined;
    if (typeof value == "number" || value instanceof Number) return <number>value;
    if (value.exact != undefined) return value.exact;
    if (value.ideal != undefined) return value.ideal;
    if (value.max != undefined) return value.max;
    return undefined;
  }

  public async transform(inputFrame: VideoFrame, controller: TransformStreamDefaultController<VideoFrame>) {
    const timestamp = new Date().getTime();
    this._frameRateMonitor.addTimestamp(timestamp);
    const constraints = this._generator.getConstraints();
    const text = JSON.stringify(<QRFrameData>{
      o: {
        t: timestamp,
        c: {
          f: this.getConstraintValue(constraints.frameRate) ?? this._targetFrameRate,
          h: this.getConstraintValue(constraints.height) ?? this._targetHeight,
          w: this.getConstraintValue(constraints.width) ?? this._targetWidth,
          b: this._track.bitrateMax,
        },
        a: {
          f: this._frameRateMonitor.frameRate,
          h: inputFrame.displayHeight,
          w: inputFrame.displayWidth,
          b: this._track.bitrate,
        },
      },
    });
    const outputFrame = await this._qr.write(text, inputFrame);
    inputFrame.close();
    controller.enqueue(outputFrame);
  }
}