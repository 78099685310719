import DocumentObserverEvent from "./models/DocumentObserverEvent";
import EventOwner from "../core/EventOwner";
import Reactive from "../core/Reactive";
import Utility from "../core/Utility";

export default class DocumentObserver {
  private static _shared: DocumentObserver;

  public static get shared(): DocumentObserver {
    if (!this._shared) this._shared = new DocumentObserver();
    return this._shared;
  }

  private readonly _audioElementCollection: HTMLCollectionOf<HTMLAudioElement>;
  private readonly _audioElementMap = new Map<string, HTMLAudioElement>();
  private readonly _mutationCallback: MutationCallback;
  private readonly _mutationObserver: MutationObserver;
  private readonly _updated = new EventOwner<DocumentObserverEvent>();
  private readonly _videoElementCollection: HTMLCollectionOf<HTMLVideoElement>;
  private readonly _videoElementMap = new Map<string, HTMLVideoElement>();

  private _audioElements: HTMLAudioElement[];
  private _isStarted: boolean = false;
  private _videoElements: HTMLVideoElement[];

  public get audioElements(): HTMLAudioElement[] { return this._audioElements; }
  public get isStarted(): boolean { return this._isStarted; }
  public get isStopped(): boolean { return !this._isStarted; }
  public get updated(): EventOwner<DocumentObserverEvent> { return this._updated; }
  public get videoElements(): HTMLVideoElement[] { return this._videoElements; }
  
  public constructor() {
    this._audioElementCollection = document.getElementsByTagName("audio");
    this._videoElementCollection = document.getElementsByTagName("video");
    DocumentObserver.initialize(this._audioElementCollection, this._audioElementMap);
    DocumentObserver.initialize(this._videoElementCollection, this._videoElementMap);
    this._audioElements = Array.from(this._audioElementCollection);
    this._videoElements = Array.from(this._videoElementCollection);
    this._mutationCallback = this.mutationCallback.bind(Reactive.wrap(this));
    this._mutationObserver = new MutationObserver(this._mutationCallback);
  }

  private static getId<T extends Element>(element: T): string | null {
    return (element as any).__lsid ?? null;
  }

  private static setId<T extends Element>(element: T): string {
    const id = Utility.generateGuid();
    (element as any).__lsid = id;
    return id;
  }

  private static getUpdate<T extends Element>(collection: HTMLCollectionOf<T>, map: Map<string, T>): { added: T[], removed: T[] } {
    for (const element of collection) {
      if (!this.getId(element)) this.setId(element);
    }

    const collectionById: { [id: string]: T } = {};
    for (const element of collection) collectionById[this.getId(element)] = element;

    // remove old
    const removed: T[] = [];
    for (const elementId of map.keys()) {
      if (collectionById[elementId]) continue;
      removed.push(map.get(elementId));
      map.delete(elementId);
    }

    // add new
    const added: T[] = [];
    for (const element of collection) {
      const elementId = this.getId(element);
      if (map.get(elementId)) continue;
      added.push(element);
      map.set(elementId, element);
    }

    return { added, removed };
  }

  private static initialize(collection: HTMLCollectionOf<HTMLMediaElement>, map: Map<string, HTMLMediaElement>) {
    for (const element of collection) map.set(this.setId(element), element);
  }

  private mutationCallback(): void {
    const audioUpdate = DocumentObserver.getUpdate(this._audioElementCollection, this._audioElementMap);
    const videoUpdate = DocumentObserver.getUpdate(this._videoElementCollection, this._videoElementMap);
    if (audioUpdate.added.length == 0 && audioUpdate.removed.length == 0 && videoUpdate.added.length == 0 && videoUpdate.removed.length == 0) return;
    this._audioElements = Array.from(this._audioElementCollection);
    this._videoElements = Array.from(this._videoElementCollection);
    this._updated.dispatch({
      audio: audioUpdate,
      video: videoUpdate,
    });
  }

  public start(): void {
    if (this._isStarted) return;
    this._mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
    this._isStarted = true;
  }

  public stop(): void {
    if (!this._isStarted) return;
    this._mutationObserver.disconnect();
    this._isStarted = false;
  }
}
