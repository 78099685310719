
import { defineComponent } from 'vue';

export default defineComponent({
  watch: {
    '$route' (to, from) {
      document.title = to.name || 'LiveSwitch'
    }
  },
});
