
  import { defineComponent, PropType } from "vue";
  import { Meeting } from "@liveswitch/sdk";
  
  export default defineComponent({
    props: {
      meeting: {
        type: Object as PropType<Meeting>,
        default: null,
      },
    }
  })
  