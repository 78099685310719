import DevicesEvent from "./models/DevicesEvent";
import DeviceManager from "./DeviceManager";
import LocalVideoTrack from "./LocalVideoTrack";
import Reactive from "../core/Reactive";

export default class UserVideoTrack extends LocalVideoTrack {
  private static _contentHint: string = "motion";

  public static get contentHint(): string { return this._contentHint; }
  public static set contentHint(value: string) { this._contentHint = value; }

  private readonly _onDevicesUpdated: (e: DevicesEvent) => Promise<void>;

  private _deviceId: string = null;
  private _facingMode: string = null;
  private _isMuted = false;
  private _requestedDeviceId: string = null;
  private _requestedDeviceRequired: boolean = false;

  public get deviceId(): string { return this._deviceId; }
  public get facingMode(): string { return this._facingMode; }
  public get isMuted(): boolean { return this._isMuted; }
  public get isPaused(): boolean { return this.media?.attendee?.isVideoPaused ?? false; }
  public get requestedDeviceId(): string { return this._requestedDeviceId; }
  public get requestedDeviceRequired(): boolean { return this._requestedDeviceRequired; }

  public constructor() {
    super();
    this._onDevicesUpdated = this.onDevicesUpdated.bind(Reactive.wrap(this));
  }

  private async onDevicesUpdated(e: DevicesEvent): Promise<void> {
    if (this._deviceId == "default" || e.removed.find(d => d.id == this._deviceId) || e.added.find(d => d.id == this._requestedDeviceId)) {
      this._deviceId = null;
      this._facingMode = null;
      await this.replaceStream();
      await this.updateDeviceId();
      await this.updateFacingMode();
    }
  }

  private async updateDeviceId(): Promise<void> {
    if (!this.stream) return;
    this._deviceId = this._requestedDeviceId;
    const settings = this.stream.getSettings();
    if (!settings.deviceId) return;
    this._deviceId = settings.deviceId;
  }

  private async updateFacingMode(): Promise<void> {
    if (!this.stream) return;
    const settings = this.stream.getSettings();
    if (!settings.facingMode) return;
    this._facingMode = settings.facingMode;
  }

  /** @internal */
  public muteInternal(): void {
    this._isMuted = true;
    if (this.stream) this.stream.enabled = false;
  }

  /** @internal */
  public unmuteInternal(): void {
    this._isMuted = false;
    if (this.stream) this.stream.enabled = true;
  }

  protected async onStarted(): Promise<void> {
    await DeviceManager.shared.refresh();
    DeviceManager.shared.videoInputsUpdated.bind(this._onDevicesUpdated);
    await this.updateDeviceId();
    await this.updateFacingMode();
  }

  protected async onStarting(): Promise<void> {
    await DeviceManager.shared.start();
  }

  protected async onStopping(): Promise<void> {
    DeviceManager.shared.videoInputsUpdated.unbind(this._onDevicesUpdated);
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    try {
      if ("contentHint" in stream) stream.contentHint = UserVideoTrack.contentHint;
    } catch { /* best effort */ }
  }

  public async getConstraints(): Promise<MediaTrackConstraints> {
    // the super pulls in the right values for framerate
    const constraints = await super.getConstraints();
    // Assuming this is still needed
    if (this._requestedDeviceId) {
      if (this._requestedDeviceRequired) {
        constraints.deviceId = { exact: this._requestedDeviceId };
      } else {
        constraints.deviceId = { ideal: this._requestedDeviceId };
      }
    }
    return constraints;
  }

  public async mute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.muteVideo();
    else this.muteInternal();
  }

  public async setDevice(deviceId?: string, required?: boolean): Promise<void> {
    this._deviceId = null;
    this._requestedDeviceId = deviceId ?? null;
    this._requestedDeviceRequired = required ?? false;
    await this.replaceStream();
    await this.updateDeviceId();
    await this.updateFacingMode();
  }

  public async unmute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.unmuteVideo();
    else this.unmuteInternal();
  }

  public async useNextDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.videoInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.next(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }

  public async usePreviousDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.videoInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.previous(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }
}
