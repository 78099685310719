export enum ApiKeyNameLength {
  Max = 50
}

export enum ApiKeySecretLength {
  Max = 255
}

export enum ApiKeyTypeLength {
  Max = 20
}

export enum ApiKeyValueLength {
  Max = 50
}