export default class EventOwner<T> {
  private _handlers: Array<(args: T) => void> = [];

  /** @internal */
  public get handlerCount(): number { return this._handlers.length; }

  /** @internal */
  public dispatch(args: T): void {
    for (const handler of this._handlers) {
      handler(args);
    }
  }
    
  public bind(handler: (args: T) => void): boolean {
    if (this._handlers.indexOf(handler) != -1) return false;
    this._handlers.push(handler);
    return true;
  }

  public unbind(handler: (args: T) => void): boolean {
    const index = this._handlers.indexOf(handler);
    if (index == -1) return false;
    this._handlers.splice(index, 1);
    return true;
  }
}