import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MessageEvent from "./models/MessageEvent";
import MessageInit from "./models/MessageInit";
import MessageModel from "../api/models/ChatMessage";
import MessagePriority from "./models/MessagePriority";
import MessageType from "./models/MessageType";
import MessageUpdateOptions from "./models/MessageUpdateOptions";
import Utility from "../core/Utility";

export default class Message {
  private readonly _apiClient: ApiClient;
  private readonly _attendee: Attendee;
  private readonly _channel: Channel;
  private readonly _controlConnection: ControlConnection;
  private readonly _updated = new EventOwnerAsync<MessageEvent>();
  
  private _file?: File;
  private _model: MessageModel;
  private _uri?: string;

  public get attendee(): Attendee { return this._attendee; }
  public get channel(): Channel { return this._channel; }
  public get file(): File { return this._file; }
  public get id(): string { return this._model.id; }
  public get isAudio(): boolean { return this._model.type == "AUDIO"; }
  public get isCard(): boolean { return this._model.type == "CARD"; }
  public get isImage(): boolean { return this._model.type == "IMAGE"; }
  public get isFile(): boolean { return this._model.type == "FILE"; }
  public get isFlagged(): boolean { return this._model.status == "FLAGGED"; }
  public get isText(): boolean { return this._model.type == "TEXT"; }
  public get isVideo(): boolean { return this._model.type == "VIDEO"; }
  public get priority(): MessagePriority { return this._model.priority; }
  public get text(): string { return this._model.content; }
  public get timestamp(): Date { return this._model.createdOn; }
  public get type(): MessageType { return this._model.type; }
  /** @event */
  public get updated(): EventOwnerAsync<MessageEvent> { return this._updated; }
  public get uri(): string { return this._uri; }

  /** @internal */
  constructor(init: MessageInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.attendee, "init.attendee");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.model, "init.model");
    this._apiClient = init.apiClient;
    this._attendee = init.attendee;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._model = init.model;
  }

  /** @internal */
  public async load(): Promise<void> {
    await this.download();
  }

  /** @internal */
  public async refreshModel(): Promise<void> {
    const model = (await this._controlConnection.getChatChannelMessage({
      messageId: this._model.id,
    })).chatMessage;
    if (!model) return;

    this._model = model;
    await this.download();
    await this._updated.dispatch({
      message: this
    });
  }

  public async delete(): Promise<void> {
    await this._controlConnection.deleteChatChannelMessage(this._model, {
      channelId: this.channel.id,
      messageId: this._model.id,
    });
    this._channel.removeMessageInternal(this._model.id);
  }

  public async download(): Promise<void> {
    if (!this._model.uri) return;
    const fileId = this._model.uri.startsWith("file:") ? this._model.uri.substring("file:".length) : this._model.id;
    const data = await this._apiClient.getFileData(this._controlConnection.meetingId, fileId);
    this._file = new File([data], this._model.fileName, {
      type: this._model.mimeType
    });
    this._uri = await Utility.blobToDataUrl(this._file);
  }

  public async flag(): Promise<void> {
    await this._controlConnection.flagChatChannelMessage({ }, {
      channelId: this._channel.id,
      messageId: this._model.id                   
    });
    await this.refreshModel();
  }

  public async update(options: MessageUpdateOptions) {
    Guard.isNotNullOrUndefined(options, "options");
    await this._controlConnection.updateChatChannelMessage({
      content: options.content,
      priority: options.priority,
    }, {
      channelId: this._channel.id,
      messageId: this._model.id
    });
    await this.refreshModel();
  }
}