import Utility from "./core/Utility";

export function parseIPAddressFromSdp(sdp: string) : string | null {
  const results = Utility.extractCaptureGroups(sdp, /IN IP4 (\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/g)
    .filter(x => x != "0.0.0.0");
  return results.length > 0 ? results[0] : null;
}

export function parseIcePasswordFromSdp(sdp: string) : string | null {
  const results = Utility.extractCaptureGroups(sdp, /a=ice-pwd:(.+)\r\n/g);
  return results.length > 0 ? results[0] : null;
}

export function parseRelayPortFromIceCandidate(sdpCandidate: string) : number | null {
  const results =  Utility.extractCaptureGroups(sdpCandidate, /\s(\d+)\styp\srelay/g)
    .map(x => parseInt(x));
  return results.length > 0 ? results[0] : null;
}

export function parseSdpUserNameFragment(sdp: string) : string | null {
  const results =  Utility.extractCaptureGroups(sdp, /a=ice-ufrag:(.+)\r\n/g)
  return results.length > 0 ? results[0] : null;
}