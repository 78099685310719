export default class EventOwnerAsync<T> {
  private _handlers: Array<(args: T) => Promise<any>> = [];

  /** @internal */
  public get handlerCount(): number { return this._handlers.length; }

  /** @internal */
  public async dispatch(args: T): Promise<any> {
    for (const handler of this._handlers) {
      await handler(args);
    }
  }
    
  public bind(handler: (args: T) => Promise<any>) {
    this._handlers.push(handler);
  }

  public unbind(handler: (args: T) => Promise<any>) {
    this._handlers = this._handlers.filter(h => h != handler);
  }
}