import ElementObserverEvent from "./models/ElementObserverEvent";
import EventOwner from "../core/EventOwner";
import Reactive from "../core/Reactive";

export default class ElementObserver {
  private readonly _element: HTMLMediaElement;
  private readonly _mutationCallback: MutationCallback;
  private readonly _mutationObserver: MutationObserver;
  private readonly _updated = new EventOwner<ElementObserverEvent>();
  
  private _isStarted: boolean = false;
  
  public get isStarted(): boolean { return this._isStarted; }
  public get isStopped(): boolean { return !this._isStarted; }
  public get updated(): EventOwner<ElementObserverEvent> { return this._updated; }

  public constructor(element: HTMLMediaElement) {
    this._element = element;
    this._mutationCallback = this.mutationCallback.bind(Reactive.wrap(this));
    this._mutationObserver = new MutationObserver(this._mutationCallback);
  }

  private mutationCallback(): void {
    this._updated.dispatch({
      element: this._element,
    });
  }

  /**
   * For testing only.
   */
  /** @internal */
  public mutate(): void {
    this.mutationCallback();
  }

  public start(): void {
    if (this._isStarted) return;
    this._mutationObserver.observe(this._element, {
      attributes: true,
      attributeFilter: ["srcObject"],
    });
    this._isStarted = true;
  }

  public stop(): void {
    if (!this._isStarted) return;
    this._mutationObserver.disconnect();
    this._isStarted = false;
  }
}
