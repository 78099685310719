
  import { ApiClient, MediaDeviceManager, models } from "@liveswitch/sdk";
  import { defineComponent } from "vue";
  import Lobby from "@/components/Lobby.vue";
  import Meeting from "@/components/Meeting.vue";
  import Register from "@/components/Register.vue";
  import RoomManagement from "@/components/RoomManagement.vue";
  
  export default defineComponent({
    components: {
      Lobby,
      Meeting,
      Register,
      RoomManagement,
    },
    data(): {
      apiKey: string;
      apiClient?: ApiClient;
      exitLobby: () => Promise<void>;
      identityServiceUrl: string;
      inLobby: boolean;
      isManagingRoom: boolean;
      isRegistering: boolean;
      roomComponentKey: number;
      roomKey: string;
      roomInfo?: models.RoomInfo;
    } {
      return {
        apiKey: "",
        exitLobby: async () => {},
        identityServiceUrl: "",
        inLobby: false,
        isManagingRoom: false,
        isRegistering: false,
        roomComponentKey: 0,
        roomKey: "",
      };
    },
    methods: {
      async updateRoomInfo(roomKey: string) {
        const meetingComponent = (this.$refs.meeting as any);
        meetingComponent.roomKey = roomKey;
        await meetingComponent.updateRoomInfo();
        this.roomInfo = meetingComponent.roomInfo;
        this.roomKey = roomKey;
        this.roomComponentKey++;
      },  
      onEnterLobby(exitLobby: () => Promise<void>) {
        this.exitLobby = exitLobby;
        this.inLobby = true;
      },
      async onExitLobby() {
        await this.exitLobby();
        this.inLobby = false;
      },
      onRegister(identityServiceUrl: string, apiKey: string) {
        this.identityServiceUrl = identityServiceUrl;
        this.apiKey = apiKey;
        this.isRegistering = true;
      },
      onManageRoom(roomKey: string, roomInfo: models.RoomInfo, apiClient: ApiClient) {
        this.roomKey = roomKey;
        this.roomInfo = roomInfo;
        this.apiClient = apiClient;
        this.isManagingRoom = true;
      }
    },
    async mounted() {
      await MediaDeviceManager.shared.start();
    }
  })
  