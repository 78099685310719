
import { defineComponent } from "vue";
import Spinner from "./Spinner.vue";

export default defineComponent({
  components: {
    Spinner
  },
  data(): {
    exitDisabled: boolean
  } {
    return {
      exitDisabled: false,
    };
  },
  methods: {
    exit() {
      this.exitDisabled = true;
      this.$emit("exit");
    }
  }
})
