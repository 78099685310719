
import { defineComponent, PropType } from "vue";
import { ApiClient, Meeting, models, Identity } from "@liveswitch/sdk"

export default defineComponent({
  data(): {
    apiClient?: ApiClient,
    blockedUsers: models.RoomUserAccount[],
  } {
    return {
      blockedUsers: [],
    }
  },
  async mounted() {
    if (this.meeting && this.identity) {
      this.apiClient = new ApiClient({
        identity: this.identity
      });
      const response = await this.apiClient.listRoomUserAccounts({
        filterListStatus: ["BLOCKED"],
        roomId: this.meeting.roomId,
      });
      this.blockedUsers = [];
      response.values.forEach(element => {
        this.blockedUsers.push(element);  
      });
    }
  },
  computed: {
  },
  props: {    
    meeting: {
      required: true,
      type: Object as PropType<Meeting>
    },        
    identity: {
      required: true,
      type: Object as PropType<Identity>
    },
  },
  methods: {
    async unblock(id?: string) {
      await this.apiClient!.deleteRoomUserAccount(id!);
      let index = this.blockedUsers.findIndex((x) => x.id == id);
      if (index != -1) {
        this.blockedUsers.splice(index, 1);
      }
    },
  }
})
