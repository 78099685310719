import Logger from "./Logger";
import Item from "./Item";
import Level from "./Level";

export default class ConsoleLogger implements Logger {
  private getFunction(level: Level): (message?: any, ...optionalParams: any[]) => void {
    if (level == "fatal") return console.error;
    if (level == "error") return console.error;
    if (level == "warn") return console.warn;
    if (level == "info") return console.info;
    if (level == "debug") return console.debug;
    return console.debug;
  }

  public write(item: Item): void {
    const fn = this.getFunction(item.level);
    if (item.debug) {
      item.debug = "\n" + item.debug; // force debug printout onto new line
      if (item.error) {
        fn(item.timestamp.toISOString(), item.message, item.error, item.debug,);
      } else {
        fn(item.timestamp.toISOString(), item.message, item.debug);
      }
    } else {
      if (item.error) {
        fn(item.timestamp.toISOString(), item.message, item.error);
      } else {
        fn(item.timestamp.toISOString(), item.message);
      }
    }
  }
}

