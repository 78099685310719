import ConnectionStatsModel from "./models/ConnectionStats";
import Utility from "./core/Utility";

export default class ConnectionStats implements ConnectionStatsModel {
  private _availableOutgoingBitrate: number = 0;
  private _candidateAddress: string = null;
  private _candidateRelayProtocol: string = null;
  private _candidateType: string = null;
  private _keepAliveRequestsSent: number = 0;
  private _keepAliveRequestsReceived: number = 0;
  private _keepAliveResponsesSent: number = 0;
  private _keepAliveResponsesReceived: number = 0;
  private _lastAvailableOutgoingBitrate: number = 0;
  private _ready: boolean = false;
  private _remoteCandidateAddress: string = null;
  private _remoteCandidateType: string = null;

  public get availableOutgoingBitrate(): number { return this._availableOutgoingBitrate; }
  public get candidateAddress(): string { return this._candidateAddress; }
  public get candidateRelayProtocol(): string { return this._candidateRelayProtocol; }
  public get candidateType(): string { return this._candidateType; }
  public get keepAliveRequestsSent(): number { return this._keepAliveRequestsSent; }
  public get keepAliveRequestsReceived(): number { return this._keepAliveRequestsReceived; }
  public get keepAliveResponsesSent(): number { return this._keepAliveResponsesSent; }
  public get keepAliveResponsesReceived(): number { return this._keepAliveResponsesReceived; }
  public get lastAvailableOutgoingBitrate(): number { return this._lastAvailableOutgoingBitrate; }
  public get ready(): boolean { return this._ready; }
  public get remoteCandidateAddress(): string { return this._remoteCandidateAddress; }
  public get remoteCandidateType(): string { return this._remoteCandidateType; }

  public reset(): void {
    this._lastAvailableOutgoingBitrate = this._availableOutgoingBitrate;
    this._availableOutgoingBitrate = null;
    this._candidateAddress = null;
    this._candidateRelayProtocol = null;
    this._candidateType = null;
    this._keepAliveRequestsReceived = null;
    this._keepAliveRequestsSent = null;
    this._keepAliveResponsesReceived = null;
    this._keepAliveResponsesSent = null;
    this._remoteCandidateAddress = null;
    this._remoteCandidateType = null;
  }

  public didAvailableOutgoingBitrateDecrease(): boolean {
    if (!this._availableOutgoingBitrate || !this._lastAvailableOutgoingBitrate) return false;
    return this._availableOutgoingBitrate < this._lastAvailableOutgoingBitrate;
  }

  public didAvailableOutgoingBitrateIncrease(): boolean {
    if (!this._availableOutgoingBitrate || !this._lastAvailableOutgoingBitrate) return false;
    return this._availableOutgoingBitrate > this._lastAvailableOutgoingBitrate;
  }

  public updateFromConnection(stats: Map<string, any>): void {
    if (Utility.isNullOrUndefined(stats)) return;
    this.reset();
    this._ready = true;
    let selectedCandidatePairStats: any = null;
    let selectedLocalCandidateStats: any = null;
    let selectedRemoteCandidateStats: any = null;
    for (const value of stats.values()) {
      if (value.type == "candidate-pair" && (value.selected || value.nominated)) {
        selectedCandidatePairStats = value;
        if (value.localCandidateId) selectedLocalCandidateStats = stats.get(value.localCandidateId);
        if (value.remoteCandidateId) selectedRemoteCandidateStats = stats.get(value.remoteCandidateId);
      }
      if (value.type == "transport" && value.selectedCandidatePairId) {
        selectedCandidatePairStats = stats.get(value.selectedCandidatePairId);
        if (selectedCandidatePairStats.localCandidateId) selectedLocalCandidateStats = stats.get(selectedCandidatePairStats.localCandidateId);
        if (selectedCandidatePairStats.remoteCandidateId) selectedRemoteCandidateStats = stats.get(selectedCandidatePairStats.remoteCandidateId);
      }
    }
    if (selectedCandidatePairStats) {
      if (selectedCandidatePairStats?.availableOutgoingBitrate) this._availableOutgoingBitrate = Math.ceil(selectedCandidatePairStats?.availableOutgoingBitrate / 1000);
      this._keepAliveRequestsSent = selectedCandidatePairStats?.requestsSent ?? null;
      this._keepAliveRequestsReceived = selectedCandidatePairStats?.requestsReceived ?? null;
      this._keepAliveResponsesSent = selectedCandidatePairStats?.responsesSent ?? null;
      this._keepAliveResponsesReceived = selectedCandidatePairStats?.responsesReceived ?? null;
    }
    if (selectedLocalCandidateStats) {
      this._candidateAddress = selectedLocalCandidateStats?.address ?? null;
      this._candidateRelayProtocol = selectedLocalCandidateStats?.relayProtocol ?? null;
      this._candidateType = selectedLocalCandidateStats?.candidateType ?? null;
    }
    if (selectedRemoteCandidateStats) {
      this._remoteCandidateAddress = selectedRemoteCandidateStats?.address ?? null;
      this._remoteCandidateType = selectedRemoteCandidateStats?.candidateType ?? null;
    }
  }
}