import ConnectionStatsModel from "../models/ConnectionStats";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Media from "./Media";
import Size from "../core/Size";
import TrackStatsModel from "../models/TrackStats";
import VideoFrameSizeEvent from "./models/VideoFrameSizeEvent";
import VideoTrackEvent from "./models/VideoTrackEvent";

export default abstract class VideoTrack {
  private readonly _frameSizeChanged = new EventOwner<VideoFrameSizeEvent>();

  private _bitrate: number = null;
  private _bytesReceived: number = null;
  private _bytesSent: number = null;
  private _candidateAddress: string = null;
  private _candidateRelayProtocol: string = null;
  private _candidateType: string = null;
  private _frameRate: number = null;
  private _frameSize: Size = null;
  private _jitter: number = null;
  private _keepAliveRequestsReceived: number;
  private _keepAliveRequestsSent: number;
  private _keepAliveResponsesReceived: number;
  private _keepAliveResponsesSent: number;
  private _keyFrameRequestsReceived: number = null;
  private _keyFrameRequestsSent: number = null;
  private _keyFramesReceived: number = null;
  private _keyFramesSent: number = null;
  private _nacksReceived: number = null;
  private _nacksSent: number = null;
  private _networkQuality: number = null;
  private _packetLoss: number = null;
  private _packetsLost: number = null;
  private _packetsReceived: number = null;
  private _packetsSent: number = null;
  private _remoteCandidateAddress: string = null;
  private _remoteCandidateType: string = null;
  private _roundTripTime: number = null;

  public get bitrate(): number { return this._bitrate; }
  public get bytesReceived(): number { return this._bytesReceived; }
  public get bytesSent(): number { return this._bytesSent; }
  public get candidateAddress(): string { return this._candidateAddress; }
  public get candidateRelayProtocol(): string { return this._candidateRelayProtocol; }
  public get candidateType(): string { return this._candidateType; }
  public get frameRate(): number { return this._frameRate;  }
  public get frameSize(): Size { return this._frameSize; }
  public abstract get isDisabled(): boolean;
  public get isLocal(): boolean { return !this.isRemote; }
  public abstract get isMuted(): boolean;
  public abstract get isPaused(): boolean;
  public abstract get isRemote(): boolean;
  public get jitter(): number { return this._jitter; }
  public get keepAliveRequestsReceived(): number { return this._keepAliveRequestsReceived; }
  public get keepAliveRequestsSent(): number { return this._keepAliveRequestsSent; }
  public get keepAliveResponsesReceived(): number { return this._keepAliveResponsesReceived; }
  public get keepAliveResponsesSent(): number { return this._keepAliveResponsesSent; }
  public get keyFrameRequestsReceived(): number { return this._keyFrameRequestsReceived; }
  public get keyFrameRequestsSent(): number { return this._keyFrameRequestsSent; }
  public get keyFramesReceived(): number { return this._keyFramesReceived; }
  public get keyFramesSent(): number { return this._keyFramesSent; }
  public abstract get media(): Media;
  public get nacksReceived(): number { return this._nacksReceived; }
  public get nacksSent(): number { return this._nacksSent; }
  public get networkQuality(): number { return this._networkQuality; }
  public get packetLoss(): number { return this._packetLoss; }
  public get packetsLost(): number { return this._packetsLost; }
  public get packetsReceived(): number { return this._packetsReceived; }
  public get packetsSent(): number { return this._packetsSent; }
  public get remoteCandidateAddress(): string { return this._remoteCandidateAddress; }
  public get remoteCandidateType(): string { return this._remoteCandidateType; }
  public get roundTripTime(): number { return this._roundTripTime; }
  public abstract get spatialLayerIndex(): number;
  public abstract get stream(): MediaStreamTrack;
  public abstract get temporalLayerIndex(): number;

  /** @event */
  public get frameSizeChanged(): EventOwner<VideoFrameSizeEvent> { return this._frameSizeChanged; }
  /** @event */
  public abstract get streamBound(): EventOwnerAsync<VideoTrackEvent>;
  /** @event */
  public abstract get streamUnbound(): EventOwner<VideoTrackEvent>;

  private updateFrameSize(frameSize: Size): void {
    if (frameSize.width == this._frameSize?.width && frameSize.height == this._frameSize?.height) return;
    const previousFrameSize = this._frameSize;
    this._frameSize = frameSize;
    this._frameSizeChanged.dispatch({
      frameSize: frameSize,
      previousFrameSize: previousFrameSize,
      track: this,
    })
  }
  
  /** @internal */
  public updateStats(stats: TrackStatsModel, connectionStats: ConnectionStatsModel): void {
    if (stats.bitrate != null) this._bitrate = stats.bitrate;
    if (stats.bytesReceived != null) this._bytesReceived = stats.bytesReceived;
    if (stats.bytesSent != null) this._bytesSent = stats.bytesSent;
    if (stats.frameRate != null) this._frameRate = stats.frameRate;
    if (stats.frameSize != null) this.updateFrameSize(stats.frameSize);
    if (stats.jitter != null) this._jitter = stats.jitter;
    if (stats.keyFrameRequestsReceived != null) this._keyFrameRequestsReceived = stats.keyFrameRequestsReceived;
    if (stats.keyFrameRequestsSent != null) this._keyFrameRequestsSent = stats.keyFrameRequestsSent;
    if (stats.keyFramesReceived != null) this._keyFramesReceived = stats.keyFramesReceived;
    if (stats.keyFramesSent != null) this._keyFramesSent = stats.keyFramesSent;
    if (stats.nacksReceived != null) this._nacksReceived = stats.nacksReceived;
    if (stats.nacksSent != null) this._nacksSent = stats.nacksSent;
    if (stats.networkQuality != null) this._networkQuality = stats.networkQuality;
    if (stats.packetLoss != null) this._packetLoss = stats.packetLoss;
    if (stats.packetsLost != null) this._packetsLost = stats.packetsLost;
    if (stats.packetsReceived != null) this._packetsReceived = stats.packetsReceived;
    if (stats.packetsSent != null) this._packetsSent = stats.packetsSent;
    if (stats.roundTripTime != null) this._roundTripTime = stats.roundTripTime;
    if (connectionStats.candidateAddress != null) this._candidateAddress = connectionStats.candidateAddress;
    if (connectionStats.candidateRelayProtocol != null) this._candidateRelayProtocol = connectionStats.candidateRelayProtocol;
    if (connectionStats.candidateType != null) this._candidateType = connectionStats.candidateType;
    if (connectionStats.keepAliveRequestsReceived != null) this._keepAliveRequestsReceived = connectionStats.keepAliveRequestsReceived;
    if (connectionStats.keepAliveRequestsSent != null) this._keepAliveRequestsSent = connectionStats.keepAliveRequestsSent;
    if (connectionStats.keepAliveResponsesReceived != null) this._keepAliveResponsesReceived = connectionStats.keepAliveResponsesReceived;
    if (connectionStats.keepAliveResponsesSent != null) this._keepAliveResponsesSent = connectionStats.keepAliveResponsesSent;
    if (connectionStats.remoteCandidateAddress != null) this._remoteCandidateAddress = connectionStats.remoteCandidateAddress;
    if (connectionStats.remoteCandidateType != null) this._remoteCandidateType = connectionStats.remoteCandidateType;
  }
}
