import ConnectionState from "./models/ConnectionState";

export default class ConnectionStateMachine {
  private _lastStateDuration: number | undefined;
  private _lastTransitionTimestamp: number;
  private _state: ConnectionState;

  public get previousStateDuration(): number { return this._lastStateDuration; }
  public get state(): ConnectionState { return this._state; }

  public constructor(initialState?: ConnectionState) {
    this._lastTransitionTimestamp = performance.now();
    this._state = initialState ?? "new";
  }

  public setState(state: ConnectionState): void {
    if (this.trySetState(state)) return;
    throw new Error(`Cannot transition to '${state}' state while in '${this._state}' state.`);
  }

  public trySetState(state: ConnectionState): boolean {
    switch (state) {
      case "closed":
        if (this._state == "closed" || this._state == "failed") return false;
        break;
      case "connected":
        if (this._state != "connecting" && this._state != "reconnecting") return false;
        break;
      case "connecting":
        if (this._state != "new") return false;
        break;
      case "failed":
        if (this._state == "closed" || this._state == "failed") return false;
        break;
      case "reconnecting":
        if (this._state != "connected") return false;
        break;
      default:
        return false;
    }
    this._state = state;
    const now = performance.now();
    if (this._lastTransitionTimestamp) this._lastStateDuration = now - this._lastTransitionTimestamp
    this._lastTransitionTimestamp = now;
    return true;
  }
}