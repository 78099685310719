import ApiClient from "../api/Client";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import ClusterLogCreateRequest from "../api/clusterLog/ClusterLogCreateRequest";
import Log from "../logging/Log";

export default class ApplicationInsightsLogProvider {
    private static _applicationInsightsLogger: ApplicationInsights;
    private static _failedGettingConnectionString: boolean;
    private static _initializing: boolean;

    private constructor() { }

    public static async initialize(apiClient: ApiClient, clusterId: string): Promise<void> {
        if (this._applicationInsightsLogger) return;
        if (this._failedGettingConnectionString) return;
        if (this._initializing) return;
        this._initializing = true;

        try {
            const connectionString = (await apiClient.getClusterSetting(clusterId, 'AI:CONNECTIONSTRING')).value?.value;
            if (!connectionString) {
                this._failedGettingConnectionString = true;
                await apiClient.createClusterLog(<ClusterLogCreateRequest> {
                    clusterId: clusterId,
                    eventName: "Could not get Application Insights connection string",
                    eventSeverity: "ERROR",
                    eventType: "TRACE"
                })
                return;
            }
            this._applicationInsightsLogger = new ApplicationInsights({ 
                config: { connectionString: connectionString }
            });
            this._applicationInsightsLogger.loadAppInsights();
        } catch (error) {
            Log.write({
                error: (error as Error),
                level: "error",
                message: `Could not initialize Application Insights Log Provider. Error: ${(error as Error).message}`,
                timestamp: new Date(),
              });
        } finally {
            this._initializing = false;
        }
    }

    public static logException(error: Error, parameters: {[key: string]: string | number}) {
        if (!this._applicationInsightsLogger) return;
        this._applicationInsightsLogger.trackException({exception: error}, parameters);
    }

    public static logEvent(name: string, parameters: {[key: string]: string | number}) {
        if (!this._applicationInsightsLogger) return;
        this._applicationInsightsLogger.trackEvent({name: name}, parameters);
    }
}