import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import Channel from "./Channel";
import ChannelCollection from "./ChannelCollection";
import ChannelCreateOptions from "./models/ChannelCreateOptions";
import ChannelEvent from "./models/ChannelEvent";
import ChatEvent from "../control/models/ChatEvent";
import ChatInit from "./models/ChatInit";
import ChatSearchOptions from "./models/ChatSearchOptions";
import ChatState from "./models/ChatState";
import ChatStateChangeEvent from "./models/ChatStateChangeEvent";
import ChatStateMachine from "./ChatStateMachine";
import Collection from "../models/Collection";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MemberEvent from "./models/MemberEvent";
import Message from "./Message";
import MessageEvent from "./models/MessageEvent";
import Reactive from "../core/Reactive";
import ReadOnlyCollectionEvent from "../core/models/ReadOnlyCollectionEvent";
import SubscribedView from "../SubscribedView";

export default class Chat {
  private readonly _channelAdded = new EventOwnerAsync<ChannelEvent>();
  private readonly _channelRemoved = new EventOwnerAsync<ChannelEvent>();
  private readonly _memberAdded = new EventOwnerAsync<MemberEvent>();
  private readonly _memberRemoved = new EventOwnerAsync<MemberEvent>();
  private readonly _messageDeleted = new EventOwnerAsync<MessageEvent>();
  private readonly _messageReceived = new EventOwnerAsync<MessageEvent>();
  private readonly _messageSent = new EventOwnerAsync<MessageEvent>();
  private readonly _onChannelDeleted: (e: ChatEvent) => Promise<void>;
  private readonly _onChannelUpdated: (e: ChatEvent) => Promise<void>;
  private readonly _onLocalChannelAdded: (e: ReadOnlyCollectionEvent<Channel>) => void;
  private readonly _onLocalChannelRemoved: (e: ReadOnlyCollectionEvent<Channel>) => void;
  private readonly _onLocalMemberAdded: (e: MemberEvent) => Promise<void>;
  private readonly _onLocalMemberRemoved: (e: MemberEvent) => Promise<void>;
  private readonly _onLocalMessageDeleted: (e: MessageEvent) => Promise<void>;
  private readonly _onLocalMessageReceived: (e: MessageEvent) => Promise<void>;
  private readonly _onLocalMessageSent: (e: MessageEvent) => Promise<void>;
  private readonly _onMemberCreated: (e: ChatEvent) => Promise<void>;
  private readonly _onMemberDeleted: (e: ChatEvent) => Promise<void>;
  private readonly _onMemberUpdated: (e: ChatEvent) => Promise<void>;
  private readonly _onMessageCreated: (e: ChatEvent) => Promise<any>;
  private readonly _onMessageDeleted: (e: ChatEvent) => Promise<void>;
  private readonly _onMessageUpdated: (e: ChatEvent) => Promise<void>;
  private readonly _stateChanged: EventOwnerAsync<ChatStateChangeEvent> = new EventOwnerAsync<ChatStateChangeEvent>();
  private readonly _stateEvents = new Map<ChatState, EventOwnerAsync<ChatStateChangeEvent>>();
  private readonly _stateMachine = new ChatStateMachine();

  private _apiClient: ApiClient;
  private _channels: ChannelCollection;
  private _controlConnection: ControlConnection;
  private _defaultChannel: Channel;
  private _localAttendee: Attendee;
  private _subscribedView: SubscribedView;

  public get channels(): ChannelCollection { return this._channels; }
  public get defaultChannel(): Channel { return this._defaultChannel; }
  /** @event */
  public get channelAdded(): EventOwnerAsync<ChannelEvent> { return this._channelAdded; }
  /** @event */
  public get channelRemoved(): EventOwnerAsync<ChannelEvent> { return this._channelRemoved; }
  public get isBusy(): boolean { return this.state == "busy"; }
  public get isError(): boolean { return this.state == "error"; }
  public get isImpaired(): boolean { return this.state == "impaired"; }
  public get isInitialized(): boolean { return this.state == "initialized"; }
  public get isNew(): boolean { return this.state == "new"; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  /** @event */
  public get memberAdded(): EventOwnerAsync<MemberEvent> { return this._memberAdded; }
  /** @event */
  public get memberRemoved(): EventOwnerAsync<MemberEvent> { return this._memberRemoved; }
  /** @event */
  public get messageDeleted(): EventOwnerAsync<MessageEvent> { return this._messageDeleted; }
  /** @event */
  public get messageReceived(): EventOwnerAsync<MessageEvent> { return this._messageReceived; }
  /** @event */
  public get messageSent(): EventOwnerAsync<MessageEvent> { return this._messageSent; }
  public get state(): ChatState { return this._stateMachine.state; }
  /** @event */
  public get stateChanged(): EventOwnerAsync<ChatStateChangeEvent> { return this._stateChanged; }

  /** @internal */
  constructor() {
    this._onChannelDeleted = this.onChannelDeleted.bind(Reactive.wrap(this));
    this._onChannelUpdated = this.onChannelUpdated.bind(Reactive.wrap(this));
    this._onLocalChannelAdded = this.onLocalChannelAdded.bind(Reactive.wrap(this));
    this._onLocalChannelRemoved = this.onLocalChannelRemoved.bind(Reactive.wrap(this));
    this._onLocalMemberAdded = this.onLocalMemberAdded.bind(Reactive.wrap(this));
    this._onLocalMemberRemoved = this.onLocalMemberRemoved.bind(Reactive.wrap(this));
    this._onLocalMessageDeleted = this.onLocalMessageDeleted.bind(Reactive.wrap(this));
    this._onLocalMessageReceived = this.onLocalMessageReceived.bind(Reactive.wrap(this));
    this._onLocalMessageSent = this.onLocalMessageSent.bind(Reactive.wrap(this));
    this._onMemberCreated = this.onMemberCreated.bind(Reactive.wrap(this));
    this._onMemberDeleted = this.onMemberDeleted.bind(Reactive.wrap(this));
    this._onMemberUpdated = this.onMemberUpdated.bind(Reactive.wrap(this));
    this._onMessageCreated = this.onMessageCreated.bind(Reactive.wrap(this));
    this._onMessageDeleted = this.onMessageDeleted.bind(Reactive.wrap(this));
    this._onMessageUpdated = this.onMessageUpdated.bind(Reactive.wrap(this));

    this._stateEvents.set("busy", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("error", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("impaired", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("initialized", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("new", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("started", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<ChatStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<ChatStateChangeEvent>());
  }

  /** @internal */
  public init(init: ChatInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._controlConnection = init.controlConnection;
    this._localAttendee = init.localAttendee;
    this._subscribedView = init.subscribedView;

    this._defaultChannel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: {
        createdBy: null,
        id: null,
        meetingId: this._controlConnection.meetingId,
        name: "Default",
        status: "ACTIVE",
        type: "PUBLIC",
        updatedBy: null,
        updatedOn: null,
      },
      subscribedView: this._subscribedView,
    });
    this._channels = new ChannelCollection();
    this._channels.tryAdd(this._defaultChannel);
    this._channels.added.bind(this._onLocalChannelAdded);
    this._channels.removed.bind(this._onLocalChannelRemoved);
    this.setState("initialized");
  }

  private getChannel(channelId: string): Channel | null {
    return channelId ? this._channels.get(channelId) : this._defaultChannel;
  }

  private async onChannelDeleted(e: ChatEvent) {
    const channelId = e.chatNotification?.channelId;
    if (!channelId) return;
    this.removeChannelInternal(channelId);
  }

  private async onChannelUpdated(e: ChatEvent) {
    const channelId = e.chatNotification?.channelId;
    if (!channelId) return;
    await this.getChannel(channelId)?.refreshModel();
  }

  private onLocalChannelAdded(e: ReadOnlyCollectionEvent<Channel>) {
    e.element.memberAdded.bind(this._onLocalMemberAdded);
    e.element.memberRemoved.bind(this._onLocalMemberRemoved);
    e.element.messageDeleted.bind(this._onLocalMessageDeleted);
    e.element.messageReceived.bind(this._onLocalMessageReceived);
    e.element.messageSent.bind(this._onLocalMessageSent);
  }

  private onLocalChannelRemoved(e: ReadOnlyCollectionEvent<Channel>) {
    e.element.memberAdded.unbind(this._onLocalMemberAdded);
    e.element.memberRemoved.unbind(this._onLocalMemberRemoved);
    e.element.messageDeleted.unbind(this._onLocalMessageDeleted);
    e.element.messageReceived.unbind(this._onLocalMessageReceived);
    e.element.messageSent.unbind(this._onLocalMessageSent);
  }

  private onLocalMemberAdded(e: MemberEvent): Promise<void> {
    return this._memberAdded.dispatch(e);
  }

  private onLocalMemberRemoved(e: MemberEvent): Promise<void> {
    return this._memberRemoved.dispatch(e);
  }
  
  private onLocalMessageDeleted(e: MessageEvent): Promise<void> {
    return this._messageDeleted.dispatch(e);
  }

  private onLocalMessageReceived(e: MessageEvent): Promise<void> {
    return this._messageReceived.dispatch(e);
  }

  private onLocalMessageSent(e: MessageEvent): Promise<void> {
    return this._messageSent.dispatch(e);
  }

  private async onMemberCreated(e: ChatEvent): Promise<void> {
    const attendeeId = e.chatNotification?.attendeeId;
    const channelId = e.chatNotification?.channelId;
    const memberId = e.chatNotification?.chatMemberId;
    const sentBy = e.chatNotification?.sentBy;
    if (!attendeeId || !channelId || !memberId || sentBy == this._controlConnection.attendeeId) return;

    if (attendeeId == this._controlConnection.attendeeId) {
      await this.addChannelInternal(channelId);
    } else {
      await this.getChannel(channelId)?.addMemberInternal(memberId);
    }
  }

  private async onMemberDeleted(e: ChatEvent) {
    const attendeeId = e.chatNotification?.attendeeId;
    const channelId = e.chatNotification?.channelId;
    const memberId = e.chatNotification?.chatMemberId;
    const sentBy = e.chatNotification?.sentBy;
    if (!attendeeId || !channelId || !memberId || sentBy == this._controlConnection.attendeeId) return;

    if (attendeeId == this._controlConnection.attendeeId) {
      await this.removeChannelInternal(channelId);
    } else {
      await this.getChannel(channelId)?.removeMemberInternal(memberId);
    }
  }

  private async onMemberUpdated(e: ChatEvent) {
    const attendeeId = e.chatNotification?.attendeeId;
    const channelId = e.chatNotification?.channelId;
    const memberId = e.chatNotification?.chatMemberId;
    const sentBy = e.chatNotification?.sentBy;
    if (!attendeeId || !channelId || !memberId || sentBy == this._controlConnection.attendeeId) return;

    await this.getChannel(channelId)?.updateMemberInternal(memberId);
  }

  private async onMessageCreated(e: ChatEvent): Promise<any> {
    const messageId = e.chatNotification?.messageId;
    const sentBy = e.chatNotification?.sentBy;
    if (!messageId || sentBy == this._controlConnection.attendeeId) return;

    await this.getChannel(e.chatNotification?.channelId)?.addMessageInternal(messageId, sentBy);
  }

  private async onMessageDeleted(e: ChatEvent) {
    const messageId = e.chatNotification?.messageId;
    const sentBy = e.chatNotification?.sentBy;
    if (!messageId || sentBy == this._controlConnection.attendeeId) return;

    await this.getChannel(e.chatNotification?.channelId)?.removeMessageInternal(messageId);
  }

  private async onMessageUpdated(e: ChatEvent) {
    const messageId = e.chatNotification?.messageId;
    const sentBy = e.chatNotification?.sentBy;
    if (!messageId || sentBy == this._controlConnection.attendeeId) return;
    
    await this.getChannel(e.chatNotification?.channelId)?.updateMessageInternal(messageId);
  }

  /** @internal */
  private async addChannelInternal(channelId: string): Promise<Channel> {
    if (this._channels.get(channelId)) return;
    
    const channelModel = (await this._controlConnection.getChatChannel({
      channelId: channelId,
    })).chatChannel;
    if (!channelModel) return;
    
    const channel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: channelModel,
      subscribedView: this._subscribedView,
    });
    await channel.load();
    this._channels.tryAdd(channel);
    await this._channelAdded.dispatch({ channel });
    return channel;
  }

  private async setState(state: ChatState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <ChatStateChangeEvent>{
      chat: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private trySetState(state: ChatState): boolean {
    const previousState = this._stateMachine.state;
    if (!this._stateMachine.trySetState(state)) return false;
    const e = <ChatStateChangeEvent>{
      chat: this,
      previousState: previousState,
      state: state,
    };
    this._stateEvents.get(state).dispatch(e);
    this._stateChanged.dispatch(e);
    return true;
  }

  /** @internal */
  public async removeChannelInternal(channelId: string): Promise<void> {
    const channel = this._channels.get(channelId);
    if (!channel || !this._channels.tryRemove(channelId)) return;
    await this._channelRemoved.dispatch({ channel });
  }

  public async createChannel(options: ChannelCreateOptions): Promise<Channel> {
    Guard.isNotNullOrUndefined(options, "options");
    Guard.isNotNullOrUndefined(options.name, "options.name");
    Guard.isNotNullOrUndefined(options.type, "options.type");
    const channelModel = (await this._controlConnection.createChatChannel({
      name: options.name,
      type: options.type,
    })).chatChannel;
    if (!channelModel) throw new Error("Could not create channel.");
    
    const channel = new Channel({
      apiClient: this._apiClient,
      chat: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      localAttendee: this._localAttendee,
      model: channelModel,
      subscribedView: this._subscribedView,
    });
    await channel.load();
    this._channels.tryAdd(channel);
    await this._channelAdded.dispatch({ channel });
    return channel;
  }

  public async search(options: ChatSearchOptions): Promise<Collection<Message>> {
    const collection = (await this._controlConnection.listChatChannelMessages({
      channelId: options.channelId,
      limit: options.limit,
      offset: options.offset,
      offsetId: options.offsetId,
      sortDirection: "DESC",
      where: options.filter,
    })).chatMessages;

    const messages: Message[] = [];
    for (const messageModel of collection.values) {
      const attendee = await this._subscribedView.subscribeToAttendee(messageModel.createdBy, "chatMessageReceived");
      const message = new Message({
        apiClient: this._apiClient,
        attendee: attendee,
        channel: this.getChannel(messageModel.channelId),
        controlConnection: this._controlConnection,
        model: messageModel,
      });
      await message.load();
      messages.push(message);
    }
    return {
      totalCount: collection.totalCount,
      values: messages,
    }
  }

  public async start() {
    if (!this.isInitialized && !this.isStopped) throw Error("Can not start chat until meeting is joined.");
    this.setState("starting");
    try {
      this._controlConnection.chatChannelDeleted.bind(this._onChannelDeleted);
      this._controlConnection.chatChannelUpdated.bind(this._onChannelUpdated);
      this._controlConnection.chatChannelMemberCreated.bind(this._onMemberCreated);
      this._controlConnection.chatChannelMemberDeleted.bind(this._onMemberDeleted);
      this._controlConnection.chatChannelMemberUpdated.bind(this._onMemberUpdated);
      this._controlConnection.chatChannelMessageCreated.bind(this._onMessageCreated);
      this._controlConnection.chatChannelMessageDeleted.bind(this._onMessageDeleted);
      this._controlConnection.chatChannelMessageUpdated.bind(this._onMessageUpdated);
  
      await this._channels.load({
        apiClient: this._apiClient,
        chat: Reactive.wrap(this),
        controlConnection: this._controlConnection,
        defaultChannel: this._defaultChannel,
        localAttendee: this._localAttendee,
        subscribedView: this._subscribedView,
      });
      this.setState("started");
    } catch (error) {
      this.setState("error");
      throw error;
    }
  }

  public async stop() {
    if (this.isInitialized || this.isStopped || this.isStopping || this.isNew) return;
    this.setState("stopping");
    try {
      this._controlConnection.chatChannelDeleted.unbind(this._onChannelDeleted);
      this._controlConnection.chatChannelUpdated.unbind(this._onChannelUpdated);
      this._controlConnection.chatChannelMemberCreated.unbind(this._onMemberCreated);
      this._controlConnection.chatChannelMemberDeleted.unbind(this._onMemberDeleted);
      this._controlConnection.chatChannelMemberUpdated.unbind(this._onMemberUpdated);
      this._controlConnection.chatChannelMessageCreated.unbind(this._onMessageCreated);
      this._controlConnection.chatChannelMessageDeleted.unbind(this._onMessageDeleted);
      this._controlConnection.chatChannelMessageUpdated.unbind(this._onMessageUpdated);
    } catch { /* best effort */ 
    } finally {
      this.setState("stopped");
    }
  }
}