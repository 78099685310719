
import { defineComponent, PropType } from "vue";
import { Attendee, AttendeeRole, AttendeeSettingName, PermissionName } from "@liveswitch/sdk"

export default defineComponent({
  data(): {
    avatarUrl: string,
    displayName: string,
    newPermissionName?: PermissionName,
    newSettingName: string,
    newSettingValue: string,
    role: AttendeeRole | null,
  } {
    return {
      avatarUrl: "",
      displayName: "",
      newPermissionName: undefined,
      newSettingName: "",
      newSettingValue: "",
      role: null,
    }
  },
  computed: {
    isAttendee() {
      return this.attendee.role == "ATTENDEE";
    },
    isHost() {
      return this.attendee.role == "HOST";
    },
    isModerator() {
      return this.attendee.role == "MODERATOR";
    },
    isViewer() {
      return this.attendee.role == "VIEWER";
    },
    attendeeRole(): AttendeeRole {
      return "ATTENDEE";
    },
    moderatorRole(): AttendeeRole {
      return "MODERATOR";
    },
    hostRole(): AttendeeRole {
      return "HOST";
    },
    viewerRole(): AttendeeRole {
      return "VIEWER";
    },
  },
  props: {    
    attendee: {
      required: true,
      type: Object as PropType<Attendee>
    },    
    canModerate: {
      required: true,
      type: Boolean
    },
  },
  watch: {
    attendee() {
      this.avatarUrl = this.attendee?.avatarUrl ?? null;
      this.displayName = this.attendee?.displayName ?? null;
      this.newPermissionName = undefined;
    }
  },
  mounted() {
    if (this.attendee == null) {
      throw new Error("No attendee found to edit");
    }
    this.avatarUrl = this.attendee.avatarUrl;
    this.displayName = this.attendee.displayName;
    this.newPermissionName = undefined;
  },
  methods: {
    async onAddPermission() {
      if (!this.newPermissionName) return;
      await this.attendee?.addPermission(this.newPermissionName);
    },
    async onAddSetting() {
      if (!this.newSettingName) return;
      await this.attendee?.addSetting({ 
        attendeeId: this.attendee.id,
        name: this.newSettingName,
        value: this.newSettingValue      
      });
    },
    async onSaveAvatarUrl() {
      try {
        await this.attendee?.setAvatarUrl(this.avatarUrl);
      } catch (ex) {
        alert(ex);
      }
    },
    async onSaveDisplayName() {
      try {
        await this.attendee?.setDisplayName(this.displayName);
      } catch (ex) {
        alert(ex);
      }
    },
    async onRemovePermission(permission: PermissionName) {
      if (!permission) return;
      await this.attendee?.removePermission(permission);
    },
    async onRemoveSetting(settingName: AttendeeSettingName | string) {
      if (!settingName) return;
      await this.attendee?.removeSetting(settingName);
    },
    changeRole() {
      if (this.role) {
        this.attendee?.setRole(this.role);
      }
    }
  }
})
