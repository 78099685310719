/**
 * Vue-specific implementation details.
 */
export default class Vue {
  private static _reactive: (element: any) => any = null;

  /**
   * Gets the reactive function.
   */
  public static get reactive(): (element: any) => any { return this._reactive; }

  /**
   * Sets the reactive function.
   */
  public static set reactive(reactive: (element: any) => any) { this._reactive = reactive; }
}
