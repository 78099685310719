import LocalMediaState from "./models/LocalMediaState";

export default class LocalMediaStateMachine {
  private _state: LocalMediaState;

  public get state(): LocalMediaState { return this._state; }

  public constructor(initialState?: LocalMediaState) {
    this._state = initialState ?? "new";
  }

  public setState(state: LocalMediaState): void {
    if (this.trySetState(state)) return;
    throw new Error(`Cannot transition to '${state}' state while in '${this._state}' state.`);
  }

  public trySetState(state: LocalMediaState): boolean {
    switch (state) {
      case "started":
        if (this._state != "starting") return false;
        break;
      case "starting":
        if (this._state != "new" && this._state != "stopped") return false;
        break;
      case "stopped":
        if (this._state != "starting" && this._state != "stopping" && this._state != "new") return false;
        break;
      case "stopping":
        if (this._state != "started") return false;
        break;
      default:
        return false;
    }
    this._state = state;
    return true;
  }
}