export enum DatabaseLogMessageLength {
    Max = 255
}

export enum DatabaseLogNameLength {
    Max = 255
}

export enum DatabaseLogTypeLength {
    Max = 20
}