import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99105db2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "output" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generate && _ctx.generate(...args)))
        }, "Generate")
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
          title: "Input"
        }, null, 512), [
          [_vModelText, _ctx.input]
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.write && _ctx.write(...args)))
        }, "Write")
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.read && _ctx.read(...args)))
        }, "Read")
      ], 512), [
        [_vShow, _ctx.dataUri]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
        }, "Clear")
      ], 512), [
        [_vShow, _ctx.dataUri]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("img", {
          ref: "output",
          src: _ctx.dataUri ?? undefined,
          title: "Output"
        }, null, 8, _hoisted_4)
      ], 512), [
        [_vShow, _ctx.dataUri]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.codeWidth), 1),
        _createTextVNode("x"),
        _createElementVNode("span", null, _toDisplayString(_ctx.codeHeight), 1)
      ], 512), [
        [_vShow, _ctx.dataUri]
      ])
    ])
  ]))
}