
import { MediaDeviceManager, UserMedia } from "@liveswitch/sdk";
import { defineComponent } from "vue";
import Preview from "./Preview.vue";
import Spinner from "./Spinner.vue";

export default defineComponent({
  name: "MediaSelection",
  components: {
    Preview,
    Spinner,
  },
  props: {
    userMedia: {
      type: UserMedia,
      default: null,
    },
    deviceManager: {
      type: MediaDeviceManager,
      default: null,
    },
    outputAudioDevice: {
      type: String,
      default: null,
    }
  },
  data(): { 
    outputDevice: string | null;
  } {
    return {      
      outputDevice: null
    };
  },
  mounted() {
    this.outputDevice = this.outputAudioDevice;
  }
});
