import LocalAudioTrack from "./LocalAudioTrack";

export default class DisplayAudioTrack extends LocalAudioTrack {
  private static _contentHint: string = "music";

  public static get contentHint(): string { return this._contentHint; }
  public static set contentHint(value: string) { this._contentHint = value; }

  public get isMuted(): boolean { return false; }
  public get isPaused(): boolean { return false; }

  protected onEnded(): void {
    if (this.media.isStarted) void this.media.stop();
    super.onEnded();
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    try {
      if ("contentHint" in stream) stream.contentHint = DisplayAudioTrack.contentHint;
    } catch { /* best effort */ }
  }
}
