
import QR from "@/classes/QR";
import QRFrameData from "@/classes/QRFrameData";
import { defineComponent } from "vue";

export default defineComponent({
  data(): {
    codeHeight: number | null;
    codeWidth: number | null;
    dataUri: string | null;
    input: string;
    qr: QR;
  } {
    return {
      codeHeight: null,
      codeWidth: null,
      dataUri: null,
      input: "",
      qr: new QR(),
    };
  },
  methods: {
    clear() {
      this.dataUri = null;
    },
    generate() {
      this.input = JSON.stringify(<QRFrameData>{
        o: {
          t: new Date().getTime(),
          c: {
            w: 1920,
            h: 1080,
            f: 30,
            b: 2000,
          },
          a: {
            w: 1920,
            h: 1080,
            f: 30,
            b: 2000,
          },
        },
        e: {
          t: new Date().getTime(),
          c: {
            w: 1920,
            h: 1080,
            f: 30,
            b: 2000,
          },
          a: {
            w: 1920,
            h: 1080,
            f: 30,
            b: 2000,
          },
        },
      });
    },
    async read() {
      if (!this.dataUri) return;
      const image = new Image();
      image.onload = async () => {
        try {
          const width = image.width;
          const height = image.height;
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          if (context == null) throw new Error("Could not get canvas context.");
          context.drawImage(image, 0, 0, width, height);
          const result = await this.qr.read(
            context.getImageData(0, 0, width, height)
          );
          if (!result) throw new Error("QR code not detected.");
          alert(result);
        } catch (error: any) {
          alert(error);
        }
      };
      image.src = this.dataUri;
    },
    async write() {
      try {
        this.dataUri = await this.qr.writeToDataUrl(this.input);
        const output = this.$refs.output as HTMLImageElement;
        output.addEventListener("load", () => {
          this.codeHeight = output.height;
          this.codeWidth = output.width;
        });
      } catch (error: any) {
        alert(error);
      }
    },
  },
});
