import ServerError from "./ServerError";

export default class ServiceUnavailableError extends ServerError {
  private readonly _serverExceptionType: string;
  private readonly _serverExceptionMessage: string;

  constructor(type: string | null, message: string) {
    super(`The service is currently unavailable. (${type ? type + ": " : ""}${message})`);
    this.name = "ServiceUnavailableError";
    this._serverExceptionType = type;
    this._serverExceptionMessage = message;
  }

  public get serverExceptionType(): string { return this._serverExceptionType; }
  public get serverExceptionMessage(): string { return this._serverExceptionMessage; }
}