export enum TenantBannerLength {
  Max = 255
}

export enum TenantImageLength {
  Max = 255
}

export enum TenantNameLength {
  Max = 100
}

export enum TenantUrlLength {
  Max = 255
}
