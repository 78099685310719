import QRCode from "qrcode";
import { deflateRaw, inflateRaw } from "pako";

export default class QR {
  private static _base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=|[A-Za-z0-9+\/]*)?$/;
  private static _scanImageData: any;
  
  private static base64Encode(buffer: Uint8Array): string {
    const binary = [];
    for (let i = 0; i < buffer.byteLength; i++) binary.push(String.fromCharCode(buffer[i]));
    return btoa(binary.join(""));
  }

  private static base64Decode(base64: string): Uint8Array | null {
    if (!this._base64Regex.test(base64)) return null;
    const binary = atob(base64);
    const buffer = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) buffer[i] = binary.charCodeAt(i);
    return buffer;
  }

  private static compress(text: string): string {
    return QR.base64Encode(deflateRaw(new TextEncoder().encode(text)));
  }

  private static decompress(text: string): string | null {
    const binary = QR.base64Decode(text);
    if (binary == null) return null;
    return new TextDecoder().decode(inflateRaw(binary));
  }

  public async read(image: ImageData): Promise<string | null> {
    if (!QR._scanImageData) {
      const { scanImageData } = await import("zbar.wasm");
      QR._scanImageData = scanImageData;
    }
    const result = await QR._scanImageData(image);
    if (result.length == 0) return null;
    return QR.decompress(result[0].decode());
  }

  public async write(text: string, frame: VideoFrame): Promise<VideoFrame> {
    const buffer = new Uint8Array(frame.allocationSize());
    const layout = await frame.copyTo(buffer);
    const qr = QRCode.create(QR.compress(text), {
      errorCorrectionLevel: "high",
    });

    const margin = 4;
    const size = qr.modules.size;
    const data = qr.modules.data;
    const sizeTotal = size + margin * 2;
    
    const width = Math.max(sizeTotal, Math.min(frame.codedHeight, frame.codedWidth) / 2);
    const scale = width / sizeTotal;
    const symbolSize = Math.floor(sizeTotal * scale);
    const scaledMargin = margin * scale;

    const dark = { y: 0, u: 0, v: 0, r: 0, g: 0, b: 0, };
    const light = { y: 255, u: 0, v: 0, r: 255, g: 255, b: 255,};
    const palette = [light, dark];

    const yuv = frame.format == "I420" || frame.format == "I420A" || frame.format == "I422" || frame.format == "I444" || frame.format == "NV12";
    const rgb = frame.format == "RGBA" || frame.format == "RGBX";
    const bgr = frame.format == "BGRA" || frame.format == "BGRX";
    if (!yuv && !rgb && !bgr) throw new Error(`Unrecognized image format '${frame.format}'.`);
    const symbolStride = (yuv ? 1 : 4) * symbolSize;
    
    let bufferOffset = 0;
    for (let i = 0; i < symbolSize; i++) {
      for (let j = 0; j < symbolSize; j++) {
        let pxColor = palette[0];
        if (i >= scaledMargin && j >= scaledMargin && i < symbolSize - scaledMargin && j < symbolSize - scaledMargin) {
          const iSrc = Math.floor((i - scaledMargin) / scale);
          const jSrc = Math.floor((j - scaledMargin) / scale);
          pxColor = palette[data[iSrc * size + jSrc] ? 1 : 0];
        }
        if (yuv) {
          buffer[bufferOffset++] = pxColor.y;
        } else if (rgb) {
          buffer[bufferOffset++] = pxColor.r;
          buffer[bufferOffset++] = pxColor.g;
          buffer[bufferOffset++] = pxColor.b;
          bufferOffset++;
        } else if (bgr) {
          buffer[bufferOffset++] = pxColor.b;
          buffer[bufferOffset++] = pxColor.g;
          buffer[bufferOffset++] = pxColor.r;
          bufferOffset++;
        }
      }
      bufferOffset += (layout[0].stride - symbolStride);
    }

    return new VideoFrame(buffer, {
      codedHeight: frame.codedHeight,
      codedWidth: frame.codedWidth,
      displayHeight: frame.displayHeight,
      displayWidth: frame.displayWidth,
      duration: frame.duration ?? undefined,
      format: frame.format!,
      timestamp: frame.timestamp!,
      visibleRect: frame.visibleRect ?? undefined,
    });
  }

  public writeToCanvas(text: string, canvas: HTMLCanvasElement): Promise<void> {
    return QRCode.toCanvas(canvas, QR.compress(text), {
      errorCorrectionLevel: "high",
      width: canvas.width,
    });
  }

  public writeToDataUrl(text: string): Promise<string> {
    return QRCode.toDataURL(QR.compress(text), {
      errorCorrectionLevel: "high",
    });
  }

  public writeToSvg(text: string): Promise<string> {
    return QRCode.toString(QR.compress(text), {
      errorCorrectionLevel: "high",
    });
  }
}