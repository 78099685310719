import Attendee from "../Attendee";
import Channel from "./Channel";
import ChatChannelAttendee from "../api/models/ChatChannelAttendee";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import MemberEvent from "./models/MemberEvent";
import MemberInit from "./models/MemberInit";
import MemberRole from "./models/MemberRole";
import MemberUpdateOptions from "./models/MemberUpdateOptions";

export default class Member {
  private readonly _attendee: Attendee;
  private readonly _channel: Channel;
  private readonly _controlConnection: ControlConnection;
  private readonly _updated = new EventOwnerAsync<MemberEvent>();

  private _model: ChatChannelAttendee;
  
  public get attendee(): Attendee { return this._attendee; }
  public get channel(): Channel { return this._channel; }
  public get id(): string { return this._model.id; }
  public get role(): MemberRole { return this._model.roleType; }
  /** @event */
  public get updated(): EventOwnerAsync<MemberEvent> { return this._updated; }

  /** @internal */
  public constructor(init: MemberInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.attendee, "init.attendee");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.model, "init.model");
    this._attendee = init.attendee;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._model = init.model;
  }

  /** @internal */
  public async refreshModel(): Promise<void> {
    const model = (await this._controlConnection.getChatChannelMember({
      chatMemberId: this._model.id,
    })).chatChannelMember;
    if (!model) return;

    this._model = model;
    await this._updated.dispatch({
      member: this
    });
  }

  public async remove(): Promise<void> {
    await this._controlConnection.deleteChatChannelMember(this._model, {
      channelId: this.channel.id,
      chatMemberId: this._model.id,
    });
    this._channel.removeMemberInternal(this._model.id);
  }

  public async update(options: MemberUpdateOptions) {
    Guard.isNotNullOrUndefined(options, "options");
    await this._controlConnection.updateChatChannelMember(this._model, {
      roleType: options.role
    });
    await this.refreshModel();
  }
}