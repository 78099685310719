import ConsoleLogger from "./ConsoleLogger";
import Guard from "../core/Guard";
import Logger from "./Logger";
import Item from "./Item";
import Level from "./Level";
import Utility from "../core/Utility";

export default class Log {
  private static _level: Level = "none";
  private static _loggers: Logger[] = [new ConsoleLogger()];

  public static get defaultLevel(): Level {
    switch ((globalThis as any).process?.env?.NODE_ENV) {
      case "production":
      case "staging":
        return "error";
      case "test":
        return "warn";
      case "development":
        return "info";
    }
    return "none";
  }
 
  public static get level(): Level { return this._level; }
  public static set level(value: Level) { this._level = value; }
  public static get loggers(): Logger[] { return this._loggers; }

  private static getIndexOfLevel(level: Level): number {
    if (level == "none") return 6;
    if (level == "fatal") return 5;
    if (level == "error") return 4;
    if (level == "warn") return 3;
    if (level == "info") return 2;
    if (level == "debug") return 1;
    return 0; // verbose
  }

  /** @internal */
  public static write(item: Item): void {
    if (this.getIndexOfLevel(item.level) < this.getIndexOfLevel(Log.level)) return;
    if (this.getIndexOfLevel("debug") < this.getIndexOfLevel(Log.level)) delete item.debug;
    for (const logger of this._loggers) {
      logger.write(item);
    }
  }

  public static addLogger(logger: Logger): boolean {
    if (this._loggers.indexOf(logger) != -1) return false;
    this._loggers.push(logger);
    return true;
  }

  public static removeLogger(logger: Logger): boolean {
    const index = this._loggers.indexOf(logger);
    if (index == -1) return false;
    this._loggers.splice(index, 1);
    return true;
  }

  public static removeLoggers(loggers?: Logger[]): void {
    for (const logger of loggers ?? this._loggers) {
      this.removeLogger(logger);
    }
  }
  
  static debug(message: string): void;
  static debug(message: string, error: Error): void;
  public static debug(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "debug",
      message: message,
      timestamp: new Date(),
    });
  }

  static error(message: string): void;
  static error(message: string, error: Error): void;
  public static error(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "error",
      message: message,
      timestamp: new Date(),
    });
  }

  static fatal(message: string): void;
  static fatal(message: string, error: Error): void;
  public static fatal(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "fatal",
      message: message,
      timestamp: new Date(),
    });
  }

  static info(message: string): void;
  static info(message: string, error: Error): void;
  public static info(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "info",
      message: message,
      timestamp: new Date(),
    });
  }

  static warn(message: string): void;
  static warn(message: string, error: Error): void;
  public static warn(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "warn",
      message: message,
      timestamp: new Date(),
    });
  }

  static verbose(message: string): void;
  static verbose(message: string, error: Error): void;
  public static verbose(message: string, error?: Error): void {
    Guard.isNotNullOrUndefined(message, "message");
    this.write({
      error: Utility.sanitizeError(error),
      level: "verbose",
      message: message,
      timestamp: new Date(),
    });
  }
  
  static {
    Log.level = Log.defaultLevel;
  }
}