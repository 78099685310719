import Client from "./Client";
import Credentials from "./models/Credentials"
import Guard from "../core/Guard";
import IceInfo from "./models/IceInfo";
import Log from "../logging/Log";
import SessionInit from "./models/SessionInit";
import SessionModel from "./models/Session";

export default class Session implements SessionModel {
  private readonly _client: Client;
  private readonly _tcpAllowed: boolean;
  private readonly _tlsAllowed: boolean;
  private readonly _udpAllowed: boolean;

  private _credentials: Credentials;
  private _credentialsRefreshAfterTime: number;

  public get maxRetries(): number { return this._client.maxRetries; }
  public set maxRetries(value: number) { this._client.maxRetries = value; }
  public get requestTimeout(): number { return this._client.requestTimeout; }
  public set requestTimeout(value: number) { this._client.requestTimeout = value; }

  public constructor(init: SessionInit) {
    Guard.isNotNullOrUndefined(init, "init");
    this._client = new Client(init);
    this._tcpAllowed = init.tcpAllowed;
    this._tlsAllowed = init.tlsAllowed;
    this._udpAllowed = init.udpAllowed;
  }

  public async iceServer(abortSignal?: AbortSignal): Promise<RTCIceServer> {
    const credentials = await this.credentials(abortSignal);
    const uris: string[] = [];
    for (const uri of credentials.uris) {
      const isSecure = uri.startsWith("turns:");
      const isUdp = uri.endsWith("?transport=udp");
      if (!this._tcpAllowed && !isUdp && !isSecure) continue;
      if (!this._tlsAllowed && !isUdp && isSecure) continue;
      if (!this._udpAllowed && isUdp) continue;
      uris.push(uri);
    }
    return <RTCIceServer>{
      credential: credentials.password,
      urls: uris,
      username: credentials.username,
    };
  }

  public async credentials(abortSignal?: AbortSignal): Promise<Credentials> {
    if (this._credentials && this._credentialsRefreshAfterTime && new Date().getTime() < this._credentialsRefreshAfterTime) return this._credentials;
    this._credentials = await this._client.getCredentials(abortSignal);
    this._credentialsRefreshAfterTime = new Date().getTime() + (this._credentials.ttl * 1000) - 60000; // within 1 minute of expiring
    for (let i = 0; i < this._credentials.uris.length; i++) {
      const uri = this._credentials.uris[i];
      if (uri.indexOf("?transport=") != -1) continue;
      const uriUdp = `${uri}?transport=udp`;
      const uriTcp = `${uri}?transport=tcp`;
      Log.info(`Transforming TURN URI from ${uri} to ${uriUdp} and ${uriTcp}`);
      this._credentials.uris.splice(i, 1, uriUdp, uriTcp);
      i++;
    }
    return this._credentials;
  }

  public async setIceInfo(iceInfo: IceInfo, abortSignal?: AbortSignal): Promise<void> {
    await this._client.setIceInfo(iceInfo, abortSignal);
  }
}
