import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "chat-bubble" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { controls: "" }
const _hoisted_7 = ["type", "src"]
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { controls: "" }
const _hoisted_10 = ["type", "src"]
const _hoisted_11 = { key: 5 }
const _hoisted_12 = ["href", "download"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canDeleteMessage)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteChatMessage && _ctx.deleteChatMessage(...args))),
          class: "delete-message"
        }, "x"))
      : _createCommentVNode("", true),
    _createElementVNode("h4", null, _toDisplayString(_ctx.displayName), 1),
    (_ctx.message.isText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message.text), 1))
      : (_ctx.message.isImage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.message.uri
            }, null, 8, _hoisted_4)
          ]))
        : (_ctx.message.isAudio)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("audio", _hoisted_6, [
                _createElementVNode("source", {
                  type: _ctx.message.file.type,
                  src: _ctx.message.uri
                }, null, 8, _hoisted_7)
              ])
            ]))
          : (_ctx.message.isVideo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("video", _hoisted_9, [
                  _createElementVNode("source", {
                    type: _ctx.message.file.type,
                    src: _ctx.message.uri
                  }, null, 8, _hoisted_10)
                ])
              ]))
            : _createCommentVNode("", true),
    (_ctx.message.uri)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("a", {
            href: _ctx.message.uri,
            download: _ctx.message.file.name
          }, _toDisplayString(_ctx.message.file.name), 9, _hoisted_12),
          _createTextVNode(" (" + _toDisplayString(Math.ceil(_ctx.message.file.size / 1000).toLocaleString()) + " KB) ", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h6", null, _toDisplayString(_ctx.message.timestamp), 1)
  ]))
}