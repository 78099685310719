
import QRFakeUserMedia from "@/classes/QRFakeUserMedia";
import QRFrameData from "@/classes/QRFrameData";
import QRMeetingData from "@/classes/QRMeetingData";
import QRProcessor from "@/classes/QRProcessor";
import QRReader from "@/classes/QRReader";
import { Identity, Log, Meeting, RemoteMedia } from "@liveswitch/sdk";
import { defineComponent } from "vue";

const getParams = () => {
  return new URLSearchParams(
    [...new URLSearchParams(window.location.search)].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
};

type State = "starting" | "started" | "stopping" | "stopped";

export default defineComponent({
  data(): {
    apiKey: string;
    identityServiceUrl: string;
    meeting1: Meeting | undefined;
    meeting1JoinMillis: number | undefined;
    meeting2: Meeting | undefined;
    meeting2JoinMillis: number | undefined;
    messages: string[];
    qrReader: QRReader;
    remoteVideo1Data: QRFrameData | undefined;
    remoteVideo1Datas: QRFrameData[];
    remoteVideo1FileUri: string | undefined;
    remoteVideo1Latency: number | undefined;
    remoteVideo1Processor: QRProcessor | undefined;
    remoteVideo2Data: QRFrameData | undefined;
    remoteVideo2Datas: QRFrameData[];
    remoteVideo2FileUri: string | undefined;
    remoteVideo2Latency: number | undefined;
    remoteVideo2Processor: QRProcessor | undefined;
    state: State;
  } {
    const params = getParams();
    const defaultApiKey = params.get("apikey") ?? process.env.VUE_APP_API_KEY!;
    const defaultIdentityServiceUrl =
      params.get("identityserviceurl") ??
      process.env.VUE_APP_IDENTITY_SERVICE_URL!;
    if (!defaultApiKey)
      throw new Error("VUE_APP_API_KEY is missing and required.");
    if (!defaultIdentityServiceUrl)
      throw new Error("VUE_APP_IDENTITY_SERVICE_URL is missing and required.");
    return {
      apiKey: defaultApiKey,
      identityServiceUrl: defaultIdentityServiceUrl,
      meeting1: undefined,
      meeting1JoinMillis: undefined,
      meeting2: undefined,
      meeting2JoinMillis: undefined,
      messages: [],
      qrReader: new QRReader(document.getElementsByClassName("video-remote")),
      remoteVideo1Data: undefined,
      remoteVideo1Datas: [],
      remoteVideo1FileUri: undefined,
      remoteVideo1Latency: undefined,
      remoteVideo1Processor: undefined,
      remoteVideo2Data: undefined,
      remoteVideo2Datas: [],
      remoteVideo2FileUri: undefined,
      remoteVideo2Latency: undefined,
      remoteVideo2Processor: undefined,
      state: "stopped",
    };
  },
  mounted() {
    Log.level = "debug";
  },
  methods: {
    augmentQRFrameData(
      data: QRFrameData,
      datas: QRFrameData[],
      processor?: QRProcessor
    ): number | undefined {
      const now = new Date().getTime();
      data.e = {
        t: now,
        c: {
          f: undefined, //TODO: once constraints are implemented
          h: undefined, //TODO: once constraints are implemented
          w: undefined, //TODO: once constraints are implemented
          b: undefined, //TODO: once constraints are implemented
        },
        a: {
          f: processor?.frameRate,
          h: processor?.frameHeight,
          w: processor?.frameWidth,
          b: processor?.bitrate,
        },
      };
      datas[datas.length] = data;
      if (data.e?.t && data.o?.t) return data.e.t - data.o.t;
      return undefined;
    },
    createFileUri(
      meeting: Meeting,
      videoFramesUser: QRFrameData[]
    ): Promise<string> {
      const blob = new Blob([
        new TextEncoder().encode(
          JSON.stringify(<QRMeetingData>{
            attendeeId: meeting.localAttendee.id,
            maxAudibleDisplay: meeting.audibleDisplayMedias.length,
            maxAudibleUser: meeting.audibleUserMedias.length,
            maxVisibleDisplay: meeting.maxVisibleDisplayMedias,
            maxVisibleUser: meeting.maxVisibleUserMedias,
            meetingId: meeting.id,
            videoFramesDisplay: [],
            videoFramesUser: videoFramesUser,
          })
        ),
      ]);
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
    },
    log(message: string) {
      this.messages.push(message);
    },
    async readQRCodes() {
      const results = await this.qrReader.read();
      if (results["video1-remote"]) {
        this.remoteVideo1Data = results["video1-remote"];
        this.remoteVideo1Latency =
          this.augmentQRFrameData(
            this.remoteVideo1Data,
            this.remoteVideo1Datas,
            <QRProcessor | undefined>this.remoteVideo1Processor
          ) ?? this.remoteVideo1Latency;
      }
      if (results["video2-remote"]) {
        this.remoteVideo2Data = results["video2-remote"];
        this.remoteVideo2Latency =
          this.augmentQRFrameData(
            this.remoteVideo2Data,
            this.remoteVideo2Datas,
            <QRProcessor | undefined>this.remoteVideo2Processor
          ) ?? this.remoteVideo2Latency;
      }
      if (
        this.meeting1?.state == "joined" &&
        this.meeting2?.state == "joined"
      ) {
        setTimeout(this.readQRCodes.bind(this), 1);
      }
    },
    async start() {
      this.messages = [];
      this.meeting1JoinMillis = undefined;
      this.meeting2JoinMillis = undefined;
      this.remoteVideo1Datas = [];
      this.remoteVideo2Datas = [];
      this.state = "starting";
      this.log("Starting...");
      try {
        const identity1 = new Identity({
          apiKey: this.apiKey,
          identityServiceUrl: this.identityServiceUrl,
          type: "anonymous",
        });
        const identity2 = new Identity({
          apiKey: this.apiKey,
          identityServiceUrl: this.identityServiceUrl,
          type: "anonymous",
        });

        this.meeting1 = new Meeting({ identity: identity1 });
        this.meeting2 = new Meeting({ identity: identity2 });

        this.meeting1.visibleUserMedias.added.bind((e) => {
          if (e.element.isLocal) return;
          this.remoteVideo1Processor = new QRProcessor(e.element.videoTrack);
        });
        this.meeting2.visibleUserMedias.added.bind((e) => {
          if (e.element.isLocal) return;
          this.remoteVideo2Processor = new QRProcessor(e.element.videoTrack);
        });

        this.meeting1.maxVisibleUserMedias = 2;
        this.meeting2.maxVisibleUserMedias = 2;

        this.log("Setting user media 1...");
        await this.meeting1.setLocalUserMedia(new QRFakeUserMedia(true, true));
        await this.meeting1.localUserMedia.videoTrack.setFrameSize({
          width: 1280,
          height: 720,
        });

        this.log("Setting user media 2...");
        await this.meeting2.setLocalUserMedia(new QRFakeUserMedia(true, true));
        await this.meeting2.localUserMedia.videoTrack.setFrameSize({
          width: 1280,
          height: 720,
        });

        this.log("Starting user media 1...");
        await this.meeting1.localUserMedia.start();

        this.log("Starting user media 2...");
        await this.meeting2.localUserMedia.start();

        this.log("Joining meeting 1...");
        const start1 = performance.now();
        await this.meeting1.join({
          onProgress: (e) => {
            this.log(
              `Joining meeting 1 (${e.progress.toLocaleString(undefined, {
                style: "percent",
              })})...`
            );
          },
        });
        this.meeting1JoinMillis = performance.now() - start1;

        this.log("Joining meeting 2...");
        const start2 = performance.now();
        await this.meeting2.join({
          onProgress: (e) => {
            this.log(
              `Joining meeting 2 (${e.progress.toLocaleString(undefined, {
                style: "percent",
              })})...`
            );
          },
          roomKey: this.meeting1.roomKey,
        });
        this.meeting2JoinMillis = performance.now() - start2;

        this.state = "started";
        this.log("Started.");

        void this.readQRCodes();
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        this.log(`Unexpected error. ${error}`);
        await this.stop();
      }
    },
    async stop() {
      this.state = "stopping";
      this.log("Stopping...");
      try {
        if (this.meeting1?.localAttendee)
          this.remoteVideo1FileUri = await this.createFileUri(
            <Meeting>this.meeting1,
            this.remoteVideo1Datas
          );
        if (this.meeting2?.localAttendee)
          this.remoteVideo2FileUri = await this.createFileUri(
            <Meeting>this.meeting2,
            this.remoteVideo2Datas
          );

        this.log("Leaving meeting 1...");
        await this.meeting1?.leave();

        this.log("Leaving meeting 2...");
        await this.meeting2?.leave();

        this.log("Stopping user media 1...");
        await this.meeting1?.localUserMedia?.stop();

        this.log("Stopping user media 2...");
        await this.meeting2?.localUserMedia?.stop();

        this.meeting1 = undefined;
        this.meeting2 = undefined;
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        this.log(`Unexpected error. ${error}`);
      } finally {
        this.state = "stopped";
        this.log("Stopped.");
      }
    },
  },
});
