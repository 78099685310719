export enum ClusterAdminApiKeyLength {
  Max = 100
}

export enum ClusterAdminApiUrlLength {
  Max = 255
}

export enum ClusterNameLength {
  Max = 50
}

export enum ClusterKeyLength {
  Max = 50
}

export enum ClusterSecretLength {
  Max = 255
}

export enum ClusterUrlLength {
  Max = 255
}

export enum ClusterMeetingUrlLength {
  Max = 255
}

export enum ClusterSipUrlLength {
  Max = 255
}

export enum ClusterSipSecretLength {
  Max = 100
}