export default class BadRequestField {
  private readonly _name: string;
  private readonly _messages: string[];

  constructor(name: string, messages: string[]) {
    this._name = name;
    this._messages = messages;
  }

  public get name(): string { return this._name; }
  public get messages(): string[] { return this._messages; }
}