export default class FakeVideo {
  private readonly _brightness: number;
  private readonly _frameHeight: number;
  private readonly _frameRate: number;
  private readonly _frameWidth: number;
  private readonly _saturation: number;

  private _canvas?: HTMLCanvasElement;
  private _canvasContext?: CanvasRenderingContext2D | null = null;
  private _hue = 0;
  private _isStarted = false;
  private _stream?: MediaStream;
  private _streamTrack?: MediaStreamTrack;

  public get frameHeight(): number { return this._frameHeight; }
  public get frameRate(): number { return this._frameRate; }
  public get frameWidth(): number { return this._frameWidth; }
  public get streamTrack(): MediaStreamTrack | undefined { return this._streamTrack; }

  public constructor(frameWidth: number, frameHeight: number, frameRate: number) {
    this._frameWidth = frameWidth;
    this._frameHeight = frameHeight;
    this._frameRate = frameRate;

    this._brightness = 1.0;
    this._saturation = 1.0;
  }

  public async start(): Promise<void> {
    if (!this._isStarted) {
      this._canvas = document.createElement("canvas");
      this._canvas.width = this._frameWidth;
      this._canvas.height = this._frameHeight;

      this._canvasContext = this._canvas.getContext("2d");
      this._stream = this._canvas.captureStream(this._frameRate);
      this._streamTrack = this._stream.getVideoTracks()[0];

      this._hue = 0.0;

      setTimeout(() => {
        requestAnimationFrame(() => {
          this.renderFrame();
        });
      }, 1);

      this._isStarted = true;
    }
  }

  public async stop(): Promise<void> {
    this._isStarted = false;
  }

  private renderFrame() {
    if (!this._isStarted) return;
    
    const color = this.createColor(this._hue, this._saturation, this._brightness);

    this._hue += 0.005;
    if (this._hue >= 1.0) this._hue = 0.0;

    if (this._canvasContext) {
      this._canvasContext.fillStyle = `rgb(${color.red},${color.green},${color.blue})`;
      this._canvasContext.fillRect(0, 0, this._frameWidth, this._frameHeight);
    }

    requestAnimationFrame(() => {
      this.renderFrame();
    });
  }

  private createColor(hue: number, saturation: number, brightness: number): { red: number, green: number, blue: number } {
    hue = Math.max(0, Math.min(359, hue));
    saturation = Math.max(0.0, Math.min(1.0, saturation));
    brightness = Math.max(0.0, Math.min(1.0, brightness));

    if (saturation == 0) {
      const value = Math.floor(brightness * 255.0 + 0.5);
      return {
        red: value,
        green: value,
        blue: value
      };
    }

    const h = (hue - Math.floor(hue)) * 6.0;
    const f = h - Math.floor(h);
    const p = brightness * (1.0 - saturation);
    const q = brightness * (1.0 - saturation * f);
    const t = brightness * (1.0 - (saturation * (1.0 - f)));

    switch (Math.floor(h)) {
    case 0:
      return {
        red: Math.floor(brightness * 255.0 + 0.5),
        green: Math.floor(t * 255.0 + 0.5),
        blue: Math.floor(p * 255.0 + 0.5)
      };
    case 1:
      return {
        red: Math.floor(q * 255.0 + 0.5),
        green: Math.floor(brightness * 255.0 + 0.5),
        blue: Math.floor(p * 255.0 + 0.5)
      };
    case 2:
      return {
        red: Math.floor(p * 255.0 + 0.5),
        green: Math.floor(brightness * 255.0 + 0.5),
        blue: Math.floor(t * 255.0 + 0.5)
      };
    case 3:
      return {
        red: Math.floor(p * 255.0 + 0.5),
        green: Math.floor(q * 255.0 + 0.5),
        blue: Math.floor(brightness * 255.0 + 0.5)
      };
    case 4:
      return {
        red: Math.floor(t * 255.0 + 0.5),
        green: Math.floor(p * 255.0 + 0.5),
        blue: Math.floor(brightness * 255.0 + 0.5)
      };
    default:
      return {
        red: Math.floor(brightness * 255.0 + 0.5),
        green: Math.floor(p * 255.0 + 0.5),
        blue: Math.floor(q * 255.0 + 0.5)
      };
    }
  }
}
