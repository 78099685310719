import { withKeys as _withKeys, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c1e676"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-box" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      disabled: _ctx.sending,
      class: "chat-textarea",
      onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["enter"])),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.chatText) = $event))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.chatText]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatFiles, (file, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: file.name
      }, [
        _createElementVNode("span", null, _toDisplayString(file.name), 1),
        _createTextVNode(" (" + _toDisplayString(Math.ceil(file.size / 1000).toLocaleString()) + " KB) ", 1),
        _withDirectives(_createElementVNode("a", {
          onClick: ($event: any) => (_ctx.chatFiles.splice(index, 1))
        }, "Delete", 8, _hoisted_3), [
          [_vShow, !_ctx.sending]
        ])
      ]))
    }), 128)),
    _createElementVNode("input", {
      disabled: _ctx.sending || _ctx.uploading,
      type: "file",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.readFile && _ctx.readFile(...args)))
    }, null, 40, _hoisted_4),
    _createElementVNode("button", {
      disabled: _ctx.sending || (!_ctx.chatText && !_ctx.chatFiles.length),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)))
    }, "Send", 8, _hoisted_5)
  ]))
}