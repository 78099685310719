export enum UserAccountAvatarUrlLength {
    Max = 255
}
 
export enum UserAccountDisplayNameLength {
    Max = 50
}

export enum UserAccountUserNameLength {
    Max = 255
}