import Size from "./core/Size"
import TrackStatsModel from "./models/TrackStats";
import Utility from "./core/Utility";
import VideoUtility from "./core/VideoUtility";

export default class TrackStats implements TrackStatsModel {
  private _audioLevel: number = 0;
  private _averageRtcpInterval: number = 0;
  private _bitrate: number = null;
  private _bytesReceived: number = null;
  private _bytesSent: number = null;
  private _count: number = 0;
  private _fecFramesDecoded: number = 0;
  private _fecPacketsDiscarded: number = 0;
  private _fecPacketsReceived: number = 0;
  private _firCount: number = null;
  private _fractionLost: number = null;
  private _frameRate: number = null;
  private _frameSize: Size = null;
  private _framesDecoded: number = null;
  private _framesDropped: number = null;
  private _framesEncoded: number = null;
  private _framesReceived: number = null;
  private _framesSent: number = null;
  private _headerBytesSent: number = null;
  private _hugeFramesSent: number = null;
  private _jitter: number = null;
  private _jitterBufferDelay: number = null;
  private _jitterBufferEmittedCount: number = null;
  private _jitterBufferMinimumDelay: number = null;
  private _jitterBufferTargetDelay: number = null;
  private _jitterPrecise: number = null;
  private _keyFrameRequestsReceived: number = null;
  private _keyFrameRequestsSent: number = null;
  private _keyFramesReceived: number = null;
  private _keyFramesSent: number = null;
  private _lastBitrate: number = null;
  private _lastBytesTransferred = 0;
  private _lastBytesTransferredTimestamp: DOMHighResTimeStamp = null;
  private _lastFrameRate: number = null;
  private _lastFramesTransferred = 0;
  private _lastFramesTransferredTimestamp: DOMHighResTimeStamp = null;
  private _lastPacketsReceived = 0;
  private _lastPacketsReceiverExpected = 0;
  private _lastPacketsReceiverLost = 0;
  private _lastPacketsReceiverMissing = 0;
  private _lastPacketsSenderLost = 0;
  private _lastPacketsSent = 0;
  private _lastSendDelay = 0;
  private _lastTargetBitrate = 0;
  private _nacksReceived: number = null;
  private _nacksSent: number = null;
  private _originAttendeeId: string = null;
  private _packetLoss: number = null;
  private _packetsDuplicated: number = null;
  private _packetsLost: number = null;
  private _packetsReceived: number = null;
  private _packetsSent: number = null;
  private _pausedCount: number = null;
  private _pliCount: number = null;
  private _qpSum: number = null;
  private _qualityLimitationBandwidth: number = null;
  private _qualityLimitationCPU: number = null;
  private _qualityLimitationNone: number = null;
  private _qualityLimitationOther: number = null;
  private _qualityLimitationReason: string = null;
  private _qualityLimitationReasonCombined: number = null;
  private _qualityLimitationResolutionChanges: number = null;
  private _quantizationParameter: number = null;
  private _ready: boolean = false;
  private _retransmittedBytesSent: number = null;
  private _retransmittedPacketsSent: number = null;
  private _roundTripTime: number = null;
  private _roundTripTimeMeasurements: number = null;
  private _sendDelay: number = 0;
  private _targetBitrate: number = null;
  private _timestamp: number = null;
  private _totalEncodeTime: number = null;
  private _totalEncodedBytesTarget: number = null;
  private _totalPacketSendDelay: number = null;
  private _totalRoundTripTime: number = null;

  public get audioLevel(): number { return this._audioLevel }
  public get averageRtcpInterval(): number { return this._averageRtcpInterval }
  public get bitrate(): number { return this._bitrate; }
  public get bytesReceived(): number { return this._bytesReceived; }
  public get bytesSent(): number { return this._bytesSent; }
  public get count(): number { return this._count; }
  public get fecFramesDecoded(): number { return this._fecFramesDecoded; }
  public get fecPacketsDiscarded(): number { return this._fecPacketsDiscarded; }
  public get fecPacketsReceived(): number { return this._fecPacketsReceived; }
  public get firCount(): number { return this._firCount; }
  public get fractionLost(): number { return this._fractionLost; }
  public get frameRate(): number { return this._frameRate; }
  public get frameSize(): Size { return this._frameSize; }
  public get framesDecoded(): number { return this._framesDecoded; }
  public get framesDropped(): number { return this._framesDropped; }
  public get framesEncoded(): number { return this._framesEncoded; }
  public get framesReceived(): number { return this._framesReceived; }
  public get framesSent(): number { return this._framesSent; }
  public get headerBytesSent(): number { return this._headerBytesSent; }
  public get hugeFramesSent(): number { return this._hugeFramesSent; }
  public get jitter(): number { return this._jitter; }
  public get jitterBufferDelay(): number { return this._jitterBufferDelay; }
  public get jitterBufferEmittedCount(): number { return this._jitterBufferEmittedCount; }
  public get jitterBufferMinimumDelay(): number { return this._jitterBufferMinimumDelay; }
  public get jitterBufferTargetDelay(): number { return this._jitterBufferTargetDelay; }
  public get keyFrameRequestsReceived(): number { return this._keyFrameRequestsReceived; }
  public get keyFrameRequestsSent(): number { return this._keyFrameRequestsSent; }
  public get keyFramesReceived(): number { return this._keyFramesReceived; }
  public get keyFramesSent(): number { return this._keyFramesSent; }
  public get nacksReceived(): number { return this._nacksReceived; }
  public get nacksSent(): number { return this._nacksSent; }
  public get originAttendeeId(): string { return this._originAttendeeId; }
  public set originAttendeeId(value: string) { this._originAttendeeId = value; }
  public get packetLoss(): number { return this._packetLoss; }
  public get packetsDuplicated(): number { return this._packetsDuplicated; }
  public get packetsLost(): number { return this._packetsLost; }
  public get packetsReceived(): number { return this._packetsReceived; }
  public get packetsSent(): number { return this._packetsSent; }
  public get pausedCount(): number { return this._pausedCount; }
  public get pliCount(): number { return this._pliCount; }
  public get qpSum(): number { return this._qpSum };
  public get qualityLimitationBandwidth(): number { return this._qualityLimitationBandwidth; }
  public get qualityLimitationCPU(): number { return this._qualityLimitationCPU; }
  public get qualityLimitationNone(): number { return this._qualityLimitationNone; }
  public get qualityLimitationOther(): number { return this._qualityLimitationOther; }
  public get qualityLimitationReason(): string { return this._qualityLimitationReason; }
  public get qualityLimitationReasonCombined(): number { return this._qualityLimitationReasonCombined; }
  public set qualityLimitationReasonCombined(value: number) { this._qualityLimitationReasonCombined = value; }
  public get qualityLimitationResolutionChanges(): number { return this._qualityLimitationResolutionChanges; }
  public get quantizationParameter(): number { return this._quantizationParameter; }
  public get ready(): boolean { return this._ready; }
  public get retransmittedBytesSent(): number { return this._retransmittedBytesSent; }
  public get retransmittedPacketsSent(): number { return this._retransmittedPacketsSent; }
  public get roundTripTime(): number { return this._roundTripTime; }
  public get roundTripTimeMeasurements(): number { return this._roundTripTimeMeasurements; }
  public get sendDelay(): number { return this._sendDelay; }
  public get targetBitrate(): number { return this._targetBitrate; }
  public get timestamp(): number { return this._timestamp; }
  public get totalEncodeTime(): number { return this._totalEncodeTime; }
  public get totalEncodedBytesTarget(): number { return this._totalEncodedBytesTarget; }
  public get totalPacketSendDelay(): number { return this._totalPacketSendDelay; }
  public get totalRoundTripTime(): number { return this._totalRoundTripTime; }


  public didTargetBitrateDecrease() {
    if (!this._lastTargetBitrate || !this.targetBitrate) return false;
    return this.targetBitrate < this._lastTargetBitrate;
  }

  public didTargetBitrateIncrease() {
    if (!this._lastTargetBitrate || !this.targetBitrate) return false;
    return this.targetBitrate > this._lastTargetBitrate;
  }

  private estimateBitrate(): number | null {
    if (!this._frameSize || !this._frameRate) return null;
    const bitrate = VideoUtility.calculateBitrate(this._frameSize, this._frameRate);
    return Math.ceil(bitrate);
  }

  private getAudioLevelFromStats(stats: any): number | null {
    if ("audioLevel" in stats) return stats["audioLevel"];
    return null;
  }

  private getBitrateFromStats(stats: any, statsKey: string): number | null {
    if (!(statsKey in stats)) return null;
    const bytesTransferred = stats[statsKey] as number;
    const timestamp = stats["timestamp"] as DOMHighResTimeStamp;
    let bitrate = this._lastBitrate;
    if (this._lastBytesTransferredTimestamp) {
      const deltaBytes = bytesTransferred - this._lastBytesTransferred;
      const deltaSeconds = (timestamp - this._lastBytesTransferredTimestamp) / 1000;
      if (deltaSeconds == 0) return bitrate;
      bitrate = Math.ceil(deltaBytes * 8 / deltaSeconds / 1000);
    }
    this._lastBitrate = bitrate;
    this._lastBytesTransferred = bytesTransferred;
    this._lastBytesTransferredTimestamp = timestamp;
    return bitrate;
  }

  
  private getAverageRtcpIntervalFromStats(stats: any): number | null {
    if ("averageRtcpInterval" in stats) return stats["averageRtcpInterval"];
    return null;
  }

  private getBytesReceivedFromStats(stats: any): number | null {
    if ("bytesReceived" in stats) return stats["bytesReceived"];
    return null;
  }

  private getBytesSentFromStats(stats: any): number | null {
    if ("bytesSent" in stats) return stats["bytesSent"];
    return null;
  }

  private getFecFramesDecodedFromStats(stats: any): number | null {
    if ("fecFramesDecoded" in stats) return stats["fecFramesDecoded"];
    return null;
  }

  private getFecPacketsDiscardedFromStats(stats: any): number | null {
    if ("fecPacketsDiscarded" in stats) return stats["fecPacketsDiscarded"];
    return null;
  }

  private getFecPacketsReceivedFromStats(stats: any): number | null {
    if ("fecPacketsReceived" in stats) return stats["fecPacketsReceived"];
    return null;
  }

  private getFirCountFromStats(stats: any): number | null {
    if ("firCount" in stats) return stats["firCount"];
    return null;
  }

  private getFractionLostFromStats(stats: any): number | null {
    if ("fractionLost" in stats) return stats["fractionLost"];
    return null;
  }

  private getFramesEncodedFromStats(stats: any): number | null {
    if ("framesEncoded" in stats) return stats["framesEncoded"];
    return null;
  }

  private getFramesDecodedFromStats(stats: any): number | null {
    if ("framesDecoded" in stats) return stats["framesDecoded"];
    return null;
  }

  private getFramesDroppedFromStats(stats: any): number | null {
    if ("framesDropped" in stats) return stats["framesDropped"];
    return null;
  }

  private getFramesSentFromStats(stats: any): number | null {
    if ("framesSent" in stats) return stats["framesSent"];
    return null;
  }

  private getFramesReceivedFromStats(stats: any): number | null {
    if ("framesReceived" in stats) return stats["framesReceived"];
    return null;
  }

  private getFrameRateFromStats(stats: any, statsKey: string): number | null {
    if ('framesPerSecond' in stats) return stats['framesPerSecond'];
    if (!(statsKey in stats)) return null;
    const framesTransferred = stats[statsKey] as number;
    const timestamp = stats["timestamp"] as DOMHighResTimeStamp;
    let frameRate = this._lastFrameRate;
    if (this._lastFramesTransferredTimestamp) {
      const deltaFrames = framesTransferred - this._lastFramesTransferred;
      const deltaSeconds = (timestamp - this._lastFramesTransferredTimestamp) / 1000;
      if (deltaSeconds == 0) return frameRate;
      frameRate = Math.ceil(deltaFrames / deltaSeconds);
    }
    this._lastFrameRate = frameRate;
    this._lastFramesTransferred = framesTransferred;
    this._lastFramesTransferredTimestamp = timestamp;
    return frameRate;
  }

  private getFrameSizeFromStats(stats: any): Size | null {
    if ("frameWidth" in stats && "frameHeight" in stats) return { width: stats["frameWidth"], height: stats["frameHeight"] };
    return null;
  }

  private getHeaderBytesSentFromStats(stats: any): number | null {
    if ("headerBytesSent" in stats) return stats["headerBytesSent"];
    return null;
  }

  private getHugeFramesSentFromStats(stats: any): number | null {
    if ("hugeFramesSent" in stats) return stats["hugeFramesSent"];
    return null;
  }

  private getJitterFromStats(stats: any): number | null {
    if ("jitter" in stats) {
      this._jitterPrecise = stats["jitter"] * 1000;
      return Math.ceil(this._jitterPrecise);
    }
    return null;
  }

  private getJitterDelayFromStats(stats: any): number | null {
    if ("jitterBufferDelay" in stats) return stats["jitterBufferDelay"];
    return null;
  }

  private getJitterBufferEmittedCountFromStats(stats: any): number | null {
    if ("jitterBufferEmittedCount" in stats) return stats["jitterBufferEmittedCount"];
    return null;
  }

  private getJitterBufferMinimumDelayFromStats(stats: any): number | null {
    if ("jitterBufferMinimumDelay" in stats) return stats["jitterBufferMinimumDelay"];
    return null;
  }

  private getJitterBufferTargetDelayStats(stats: any): number | null {
    if ("jitterBufferTargetDelay" in stats) return stats["jitterBufferTargetDelay"];
    return null;
  }

  private getKeyFrameRequestCountFromStats(stats: any): number | null {
    let value = 0;
    let hasValue = false;
    if ("firCount" in stats) {
      value += stats["firCount"];
      hasValue = true;
    }
    if ("pliCount" in stats) {
      value += stats["pliCount"];
      hasValue = true;
    }
    if (hasValue) return value;
    return null;
  }

  private getKeyFramesReceivedFromStats(stats: any): number | null {
    if ("keyFramesDecoded" in stats) return stats["keyFramesDecoded"];
    return null;
  }

  private getKeyFramesSentFromStats(stats: any): number | null {
    if ("keyFramesEncoded" in stats) return stats["keyFramesEncoded"];
    return null;
  }

  private getNacksReceivedFromStats(stats: any): number | null {
    if ("nackCount" in stats) return stats["nackCount"];
    return null;
  }

  private getNacksSentFromStats(stats: any): number | null {
    if ("nackCount" in stats) return stats["nackCount"];
    return null;
  }

  private getPacketLossFromReceiverStats(packetsLost: number, packetsReceived: number): number | null {
    if (Utility.isNullOrUndefined(packetsLost) || Utility.isNullOrUndefined(packetsReceived)) return null;
    if (packetsLost == this._lastPacketsReceiverLost && packetsReceived == this._lastPacketsReceived) return 0;
    const packetsMissing = Math.max(packetsLost, this._lastPacketsReceiverMissing);
    const packetsExpected = packetsReceived + packetsMissing;
    const packetsMissingDelta = packetsMissing - this._lastPacketsReceiverMissing;
    const packetsExpectedDelta = packetsExpected - this._lastPacketsReceiverExpected;
    if (packetsExpectedDelta == 0) return 0;
    const packetLoss = packetsMissingDelta / packetsExpectedDelta;
    this._lastPacketsReceived = packetsReceived;
    this._lastPacketsReceiverExpected = packetsExpected;
    this._lastPacketsReceiverMissing = packetsMissing;
    this._lastPacketsReceiverLost = packetsLost;
    return packetLoss;
  }

  private getPacketLossFromSenderStats(packetsLost: number, packetsSent: number): number | null {
    if (Utility.isNullOrUndefined(packetsLost) || Utility.isNullOrUndefined(packetsSent)) return null;
    if (packetsLost == this._lastPacketsSenderLost && packetsSent == this._lastPacketsSent) return 0;
    const packetsLostDelta = packetsLost - this._lastPacketsSenderLost;
    const packetsSentDelta = packetsSent - this._lastPacketsSent;
    if (packetsSentDelta == 0) return 0;
    const packetLoss = packetsLostDelta / packetsSentDelta;
    this._lastPacketsSenderLost = packetsLost;
    this._lastPacketsSent = packetsSent;
    return packetLoss;
  }

  private getPacketsDuplicatedFromStats(stats: any): number | null {
    if ("packetsDuplicated" in stats) return stats["packetsDuplicated"];
    return null;
  }

  private getPacketsLostFromStats(stats: any): number | null {
    if ("packetsLost" in stats) return stats["packetsLost"];
    return null;
  }

  private getPacketsReceivedFromStats(stats: any): number | null {
    if ("packetsReceived" in stats) return stats["packetsReceived"];
    return null;
  }

  private getPacketsSentFromStats(stats: any): number | null {
    if ("packetsSent" in stats) return stats["packetsSent"];
    return null;
  }

  private getPliCountFromStats(stats: any): number | null {
    if ("pliCount" in stats) return stats["pliCount"];
    return null;
  }

  private getPausedCountFromStats(stats: any): number | null {
    if ("pauseCount" in stats) return stats["pauseCount"];
    return null;
  }

  private getQpSumFromStats(stats: any): number | null {
    if ("qpSum" in stats) return stats["qpSum"];
    return null;
  }

  private getQuantizationParameterFromStats(qpSum: number, framesEncoded: number) {
    if (Utility.isNullOrUndefined(qpSum) || Utility.isNullOrUndefined(framesEncoded) || qpSum == 0 || framesEncoded == 0) return null;
    return qpSum / framesEncoded;
  }

  private getQualityLimitationBandwidthFromStats(stats: any): number | null {
    if ("qualityLimitationDurations" in stats) {
      const durations = stats["qualityLimitationDurations"] as any;
      if ("bandwidth" in durations) return durations["bandwidth"];
    }
    return null;
  }

  private getQualityLimitationCPUFromStats(stats: any): number | null {
    if ("qualityLimitationDurations" in stats) {
      const durations = stats["qualityLimitationDurations"] as any;
      if ("cpu" in durations) return durations["cpu"];
    }
    return null;
  }

  private getQualityLimitationNoneFromStats(stats: any): number | null {
    if ("qualityLimitationDurations" in stats) {
      const durations = stats["qualityLimitationDurations"] as any;
      if ("none" in durations) return durations["none"];
    }
    return null;
  }

  private getQualityLimitationOtherFromStats(stats: any): number | null {
    if ("qualityLimitationDurations" in stats) {
      const durations = stats["qualityLimitationDurations"] as any;
      if ("other" in durations) return durations["other"];
    }
    return null;
  }

  private getQualityLimitationReasonFromStats(stats: any): string | null {
    if ("qualityLimitationReason" in stats) return stats["qualityLimitationReason"];
    return null;
  }

  private getQualityLimitationResolutionChangesFromStats(stats: any): number | null {
    if ("qualityLimitationResolutionChanges" in stats) return stats["qualityLimitationResolutionChanges"];
    return null;
  }

  private getRetransmittedBytesSentFromStats(stats: any): number | null {
    if ("retransmittedBytesSent" in stats) return stats["retransmittedBytesSent"];
    return null;
  }

  private getRetransmittedPacketsSentFromStats(stats: any): number | null {
    if ("retransmittedPacketsSent" in stats) return stats["retransmittedPacketsSent"];
    return null;
  }

  private getRoundTripTimeFromStats(stats: any): number | null {
    if ("roundTripTime" in stats) return Math.ceil(stats["roundTripTime"] * 1000);
    return null;
  }

  private getRoundTripTimeMeasurementsFromStats(stats: any): number | null {
    if ("roundTripTimeMeasurements" in stats) return stats["roundTripTimeMeasurements"];
    return null;
  }

  private getTargetBitrateFromStats(stats: any): number | null {
    if ("targetBitrate" in stats) {
      const currentTargetBitrate = Math.ceil(stats["targetBitrate"] / 1000);
      this._lastTargetBitrate = this._targetBitrate;
      return currentTargetBitrate;
    };
    return null;
  }

  private getTimestampFromStats(stats: any): number | null {
    if ("timestamp" in stats) return stats["timestamp"];
    return null;
  }

  private getTotalEncodeTimeFromStats(stats: any): number | null {
    if ("totalEncodeTime" in stats) return stats["totalEncodeTime"] * 1000;
    return null;
  }

  private getTotalEncodedBytesTargetFromStats(stats: any): number | null {
    if ("totalEncodedBytesTarget" in stats) return stats["totalEncodedBytesTarget"];
    return null;
  }

  private getTotalPacketSendDelayFromStats(stats: any): number | null {
    if ("totalPacketSendDelay" in stats) return stats["totalPacketSendDelay"] * 1000;
    return null;
  }

  private getPacketSendDelay(stats: any, key: string): number | null {
    if (!(key in stats)) return null;
    const totalSendDelay = stats[key] * 1000;
    const delta = totalSendDelay - this._lastSendDelay;
    this._lastSendDelay = totalSendDelay;
    return delta;
  }

  private getTotalRoundTripTimeFromStats(stats: any): number | null {
    if ("totalRoundTripTime" in stats) return stats["totalRoundTripTime"] * 1000;
    return null;
  }

  public reset(): void {
    this._audioLevel = null;
    this._averageRtcpInterval = null;
    this._bitrate = null;
    this._bytesReceived = null;
    this._bytesSent = null;
    this._fecFramesDecoded = null;
    this._fecPacketsDiscarded = null;
    this._fecPacketsReceived = null;
    this._firCount = null;
    this._fractionLost = null;
    this._frameRate = null;
    this._frameSize = null;
    this._framesDecoded = null;
    this._framesDropped = null;
    this._framesEncoded = null;
    this._framesReceived = null;
    this._framesSent = null;
    this._headerBytesSent = null;
    this._hugeFramesSent = null;
    this._jitter = null;
    this._jitterBufferDelay = null;
    this._jitterBufferEmittedCount = null;
    this._jitterBufferMinimumDelay = null;
    this._jitterBufferTargetDelay = null;
    this._jitterPrecise = null;
    this._keyFrameRequestsReceived = null;
    this._keyFrameRequestsSent = null;
    this._keyFramesReceived = null;
    this._keyFramesSent = null;
    this._nacksReceived = null;
    this._nacksSent = null;
    this._packetLoss = null;
    this._packetsDuplicated = null;
    this._packetsLost = null;
    this._packetsReceived = null;
    this._packetsSent = null;
    this._pausedCount = null;
    this._pliCount = null;
    this._qpSum = null;
    this._qualityLimitationBandwidth = null;
    this._qualityLimitationCPU = null;
    this._qualityLimitationNone = null;
    this._qualityLimitationOther = null;
    this._qualityLimitationReason = null;
    this._qualityLimitationResolutionChanges = null;
    this._retransmittedBytesSent = null;
    this._retransmittedPacketsSent = null;
    this._roundTripTime = null;
    this._roundTripTimeMeasurements = null;
    this._sendDelay = null;
    this._targetBitrate = null;
    this._totalEncodeTime = null;
    this._totalEncodedBytesTarget = null;
    this._totalPacketSendDelay = null;
    this._totalRoundTripTime = null;
    this.originAttendeeId = null;
  }

  public toJson(): any {
    return {
      //bitrateEstimated: this.estimateBitrate(),
      audioLevel: this.audioLevel,
      averageRtcpInterval: this.averageRtcpInterval,
      bitrate: this.bitrate,
      bytesReceived: this.bytesReceived,
      bytesSent: this.bytesSent,
      fecFramesDecoded: this._fecFramesDecoded,
      fecPacketsDiscarded: this.fecPacketsDiscarded,
      fecPacketsReceived: this.fecPacketsReceived,
      firCount: this.firCount,
      fractionLost: this.fractionLost,
      frameHeight: this.frameSize?.height,
      frameWidth: this.frameSize?.width,
      framesDecoded: this.framesDecoded,
      framesDropped: this.framesDropped,
      framesEncoded: this.framesEncoded,
      framesPerSecond: this.frameRate,
      framesReceived: this.framesReceived,
      framesSent: this.framesSent,
      headerBytesSent: this.headerBytesSent,
      hugeFramesSent: this.hugeFramesSent,
      jitter: this._jitterPrecise,
      jitterBufferDelay: this.jitterBufferDelay,
      jitterBufferEmittedCount: this.jitterBufferEmittedCount,
      jitterBufferMinimumDelay: this.jitterBufferMinimumDelay,
      jitterBufferTargetDelay: this.jitterBufferTargetDelay,
      keyFrameRequestsReceived: this.keyFrameRequestsReceived,
      keyFrameRequestsSent: this.keyFrameRequestsSent,
      keyFramesEncoded: this.keyFramesSent,
      keyFramesReceived: this.keyFramesReceived,
      keyFramesSent: this.keyFramesSent,
      nackCount: this.nacksReceived,
      nacksSent: this.nacksSent,
      originAttendeeId: this.originAttendeeId,
      packetLoss: this.packetLoss,
      packetsDuplicated: this.packetsDuplicated,
      packetsLost: this.packetsLost,
      packetsSent: this.packetsSent,
      pausedCount: this.pausedCount,
      pliCount: this.pliCount,
      qpSum: this.qpSum,
      qualityLimitationBandwidth: this.qualityLimitationBandwidth,
      qualityLimitationCPU: this.qualityLimitationCPU,
      qualityLimitationNone: this.qualityLimitationNone,
      qualityLimitationOther: this.qualityLimitationOther,
      qualityLimitationReason: this.qualityLimitationReason,
      qualityLimitationReasonCombined: this.qualityLimitationReasonCombined,
      qualityLimitationResolutionChanges: this.qualityLimitationResolutionChanges,
      quantizationParameter: this.quantizationParameter,
      retransmittedBytesSent: this.retransmittedBytesSent,
      retransmittedPacketsSent: this.retransmittedPacketsSent,
      roundTripTime: this.roundTripTime,
      roundTripTimeMeasurements: this.roundTripTimeMeasurements,
      sendDelay: this.sendDelay,
      targetBitrate: this.targetBitrate,
      timestamp: this.timestamp,
      totalEncodeTime: this.totalEncodeTime,
      totalEncodedBytesTarget: this.totalEncodedBytesTarget,
      totalPacketSendDelay: this.totalPacketSendDelay,
      totalRoundTripTime: this.totalRoundTripTime
    };
  }

  public updateFromReceiver(stats: Map<string, any>): void {
    if (Utility.isNullOrUndefined(stats)) return;
    this.reset();
    this._count++;

    for (const value of stats.values()) {
      if (value.type == "inbound-rtp") {
        this._audioLevel = this.getAudioLevelFromStats(value);
        this._bitrate = this.getBitrateFromStats(value, "bytesReceived");
        this._bytesReceived = this.getBytesReceivedFromStats(value);
        this._fecFramesDecoded = this.getFecFramesDecodedFromStats(value);
        this._fecPacketsDiscarded = this.getFecPacketsDiscardedFromStats(value);
        this._fecPacketsReceived = this.getFecPacketsReceivedFromStats(value);
        this._firCount = this.getFirCountFromStats(value);
        this._frameRate = this.getFrameRateFromStats(value, "framesReceived");
        this._frameSize = this.getFrameSizeFromStats(value);
        this._framesDecoded = this.getFramesDecodedFromStats(value);
        this._framesDropped = this.getFramesDroppedFromStats(value);
        this._framesReceived = this.getFramesReceivedFromStats(value);
        this._jitter = this.getJitterFromStats(value);
        this._jitterBufferDelay = this.getJitterDelayFromStats(value);
        this._jitterBufferEmittedCount = this.getJitterBufferEmittedCountFromStats(value);
        this._jitterBufferMinimumDelay = this.getJitterBufferMinimumDelayFromStats(value);
        this._jitterBufferTargetDelay = this.getJitterBufferTargetDelayStats(value);
        this._keyFramesReceived = this.getKeyFramesReceivedFromStats(value); // not currently supported in Firefox 105
        this._nacksReceived = this.getNacksReceivedFromStats(value);
        this._packetsLost = this.getPacketsLostFromStats(value);
        this._packetsReceived = this.getPacketsReceivedFromStats(value);
        this._pausedCount = this.getPausedCountFromStats(value);
        this._pliCount = this.getPliCountFromStats(value);
        this._qpSum = this.getQpSumFromStats(value);
      }
      if (value.type == "remote-outbound-rtp") {
        this._bytesSent = this.getBytesSentFromStats(value);
        this._packetsSent = this.getPacketsSentFromStats(value); // might not be supported
        this._roundTripTime = this.getRoundTripTimeFromStats(value);
        this._roundTripTimeMeasurements = this.getRoundTripTimeMeasurementsFromStats(value);
        this._targetBitrate = this.getTargetBitrateFromStats(value);
        this._totalRoundTripTime = this.getTotalRoundTripTimeFromStats(value);
      }
      this._timestamp = this.getTimestampFromStats(value);
    }
    this._packetLoss = this.getPacketLossFromReceiverStats(this._packetsLost, this._packetsReceived);
  }

  public updateFromSender(stats: Map<string, any>): void {
    if (Utility.isNullOrUndefined(stats)) return;
    this.reset();
    this._count++;
    this._ready = true;
    for (const value of stats.values()) {
      if (value.type == "outbound-rtp") {
        this._bitrate = this.getBitrateFromStats(value, "bytesSent");
        this._bytesSent = this.getBytesSentFromStats(value);
        this._fecFramesDecoded = this.getFecFramesDecodedFromStats(value);
        this._fecPacketsDiscarded = this.getFecPacketsDiscardedFromStats(value);
        this._fecPacketsReceived = this.getFecPacketsReceivedFromStats(value);
        this._firCount = this.getFirCountFromStats(value);
        this._frameRate = this.getFrameRateFromStats(value, "framesSent");
        this._frameSize = this.getFrameSizeFromStats(value);
        this._framesEncoded = this.getFramesEncodedFromStats(value);
        this._framesSent = this.getFramesSentFromStats(value);
        this._headerBytesSent = this.getHeaderBytesSentFromStats(value);
        this._hugeFramesSent = this.getHugeFramesSentFromStats(value);
        this._keyFrameRequestsReceived = this.getKeyFrameRequestCountFromStats(value);
        this._keyFramesSent = this.getKeyFramesSentFromStats(value); // not currently supported in Firefox 105
        this._nacksReceived = this.getNacksReceivedFromStats(value);
        this._packetsSent = this.getPacketsSentFromStats(value);
        this._pliCount = this.getPliCountFromStats(value);
        this._qpSum = this.getQpSumFromStats(value);
        this._qualityLimitationBandwidth = this.getQualityLimitationBandwidthFromStats(value);
        this._qualityLimitationCPU = this.getQualityLimitationCPUFromStats(value);
        this._qualityLimitationNone = this.getQualityLimitationNoneFromStats(value);
        this._qualityLimitationOther = this.getQualityLimitationOtherFromStats(value);
        this._qualityLimitationReason = this.getQualityLimitationReasonFromStats(value);
        this._qualityLimitationResolutionChanges = this.getQualityLimitationResolutionChangesFromStats(value);
        this._quantizationParameter = this.getQuantizationParameterFromStats(this._qpSum, this._framesEncoded);
        this._retransmittedBytesSent = this.getRetransmittedBytesSentFromStats(value);
        this._retransmittedPacketsSent = this.getRetransmittedPacketsSentFromStats(value);
        this._sendDelay = this.getPacketSendDelay(value, "totalPacketSendDelay");
        this._targetBitrate = this.getTargetBitrateFromStats(value);
        this._timestamp = this.getTimestampFromStats(value);
        this._totalEncodeTime = this.getTotalEncodeTimeFromStats(value);
        this._totalEncodedBytesTarget = this.getTotalEncodedBytesTargetFromStats(value);
        this._totalPacketSendDelay = this.getTotalPacketSendDelayFromStats(value);
      }
      if (value.type == "remote-inbound-rtp") { // not currently supported in Firefox 105 (for audio)
        this._fractionLost = this.getFractionLostFromStats(value);
        this._jitter = this.getJitterFromStats(value); // not currently supported in Safari 15.4
        this._packetsLost = this.getPacketsLostFromStats(value); // not currently supported in Safari 15.4
        this._packetsReceived = this.getPacketsReceivedFromStats(value); // might not be supported
        this._nacksSent = this.getNacksSentFromStats(value);
        this._roundTripTime = this.getRoundTripTimeFromStats(value);
        this._roundTripTimeMeasurements = this.getRoundTripTimeMeasurementsFromStats(value);
        this._totalRoundTripTime = this.getTotalRoundTripTimeFromStats(value);
      }
    }
    this._packetLoss = this.getPacketLossFromSenderStats(this._packetsLost, this._packetsSent);
  }
}