import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Meeting = _resolveComponent("Meeting")!
  const _component_Register = _resolveComponent("Register")!
  const _component_RoomManagement = _resolveComponent("RoomManagement")!
  const _component_Lobby = _resolveComponent("Lobby")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_KeepAlive, null, [
      _withDirectives(_createVNode(_component_Meeting, {
        ref: "meeting",
        onAdmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inLobby = false)),
        onBlocked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inLobby = false)),
        onEnterLobby: _ctx.onEnterLobby,
        onRegister: _ctx.onRegister,
        onRoomManagement: _ctx.onManageRoom
      }, null, 8, ["onEnterLobby", "onRegister", "onRoomManagement"]), [
        [_vShow, (!_ctx.isRegistering && !_ctx.inLobby && !_ctx.isManagingRoom)]
      ])
    ], 1024)),
    (_ctx.isRegistering)
      ? (_openBlock(), _createBlock(_component_Register, {
          key: 0,
          identityServiceUrl: _ctx.identityServiceUrl,
          apiKey: _ctx.apiKey,
          onRegistered: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isRegistering = false)),
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isRegistering = false))
        }, null, 8, ["identityServiceUrl", "apiKey"]))
      : _createCommentVNode("", true),
    (_ctx.isManagingRoom)
      ? (_openBlock(), _createBlock(_component_RoomManagement, {
          key: _ctx.roomComponentKey,
          "initial-room-key": _ctx.roomKey,
          "room-info": _ctx.roomInfo,
          "api-client": <ApiClient>_ctx.apiClient,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isManagingRoom = false)),
          onUpdateRoomInfo: _ctx.updateRoomInfo
        }, null, 8, ["initial-room-key", "room-info", "api-client", "onUpdateRoomInfo"]))
      : _createCommentVNode("", true),
    (_ctx.inLobby)
      ? (_openBlock(), _createBlock(_component_Lobby, {
          key: 2,
          ref: "lobby",
          onExit: _ctx.onExitLobby
        }, null, 8, ["onExit"]))
      : _createCommentVNode("", true)
  ]))
}