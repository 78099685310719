import MeetingState from "./models/MeetingState";

export default class MeetingStateMachine {
  private _state: MeetingState;

  public get state(): MeetingState { return this._state; }

  public constructor(initialState?: MeetingState) {
    this._state = initialState ?? "new";
  }

  public setState(state: MeetingState): void {
    if (this.state === state) return;
    if (this.trySetState(state)) return;
    throw new Error(`Cannot transition to '${state}' state while in '${this._state}' state.`);
  }

  public trySetState(state: MeetingState): boolean {
    switch (state) {
      case "joined":
        if (this._state != "joining" && this._state != "reconnecting") return false;
        break;
      case "joining":
        if (this._state != "new" && this._state != "left") return false;
        break;
      case "leaving":
        if (this._state != "joined" && this._state != "joining" && this._state != "reconnecting") return false;
        break;
      case "left":
        if (this._state != "leaving") return false;
        break;
      case "reconnecting":
        if (this._state != "joined") return false;
        break;
      default:
        return false;
    }
    this._state = state;
    return true;
  }
}