import ExternalSessionInit from "./models/ExternalSessionInit";
import Guard from "../core/Guard";
import SessionModel from "./models/Session";
import TurnCredentials from "../models/TurnCredentials";

export default class ExternalSession implements SessionModel {
  private _credentials: TurnCredentials;

  public constructor(init: ExternalSessionInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.credentials, "init.credentials");
    this._credentials = init.credentials;
  }
  
  public async addIceCandidate(): Promise<void> { }

  public async iceServer(abortSignal?: AbortSignal): Promise<RTCIceServer> {
    return <RTCIceServer>{
      credential: this._credentials.password,
      urls: this._credentials.uris,
      username: this._credentials.username,
    };
  }
  
  public async setIceInfo(): Promise<void> { }
}
