import Connection from "./Connection";
import ConnectionMessage from "./models/ConnectionMessage";
import ConnectionState from "./models/ConnectionState";
import ConnectionStateChangeEvent from "./models/ConnectionStateChangeEvent";
import ConnectionType from "./models/ConnectionType";
import DataChannelStats from "./DataChannelStats";
import EventOwner from "./core/EventOwner";
import EventOwnerAsync from "./core/EventOwnerAsync";
import EventLoggerModel from "./event/models/Logger";
import ManagerInit from "./models/ManagerInit";
import ManagerState from "./models/ManagerState";
import ManagerStateChangeEvent from "./models/ManagerStateChangeEvent";
import ManagerStateMachine from "./ManagerStateMachine";
import Reactive from "./core/Reactive";
import Utility from "./core/Utility";
import TenantSetting from "./api/models/TenantSetting";
import Room from "./api/models/Room";

export default abstract class Manager<TConnection extends Connection<ConnectionMessage>> {
  private readonly _connectionStateChanged = new EventOwner<ConnectionStateChangeEvent>();
  private readonly _stateChanged: EventOwnerAsync<ManagerStateChangeEvent> = new EventOwnerAsync<ManagerStateChangeEvent>();
  private readonly _stateEvents = new Map<ManagerState, EventOwnerAsync<ManagerStateChangeEvent>>();
  private readonly _stateMachine = new ManagerStateMachine();
  
  private _attendeeId: string;
  private _connection: TConnection = null;
  private _eventLogger: EventLoggerModel;
  private _meetingId: string;
  private _onConnectionStateChanged: (e: ConnectionStateChangeEvent) => void;
  private _room: Room;
  private _tenantSettings: TenantSetting[];
  private _type: ConnectionType;
  private _url: string;

  protected get connection(): TConnection { return this._connection; }
  protected get eventLogger(): EventLoggerModel { return this._eventLogger; }

  public get attendeeId(): string { return this._attendeeId; }
  public get tenantSettings(): TenantSetting[] { return this._tenantSettings; }
  public get connectionState(): ConnectionState { return this._connection?.state ?? "new"; }
  public get connectionStateChanged(): EventOwner<ConnectionStateChangeEvent> { return this._connectionStateChanged; }
  public get controlChannelStats(): DataChannelStats { return this.connection?.controlChannelStats; }
  public get isBusy(): boolean { return this.state == "busy"; }
  public get isError(): boolean { return this.state == "error"; }
  public get isImpaired(): boolean { return this.state == "impaired"; }
  public get isInitialized(): boolean { return this.state == "initialized"; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped" || this.state == "new"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public abstract get maxRetries(): number;
  public abstract set maxRetries(value: number);
  public get meetingId(): string { return this._meetingId; }
  public abstract get requestTimeout(): number;
  public abstract set requestTimeout(value: number);
  public get room(): Room { return this._room; }
  public get started(): EventOwnerAsync<ManagerStateChangeEvent> { return this._stateEvents.get("started"); }
  public get starting(): EventOwnerAsync<ManagerStateChangeEvent> { return this._stateEvents.get("starting"); }
  public get stopped(): EventOwnerAsync<ManagerStateChangeEvent> { return this._stateEvents.get("stopped"); }
  public get stopping(): EventOwnerAsync<ManagerStateChangeEvent> { return this._stateEvents.get("stopping"); }
  public get state(): ManagerState { return this._stateMachine.state; }
  public get stateChanged(): EventOwnerAsync<ManagerStateChangeEvent> { return this._stateChanged; }
  public get url(): string { return this._url; }


  public constructor() {    
    this._stateEvents.set("busy", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("error", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("impaired", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("initialized", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("new", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("started", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<ManagerStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<ManagerStateChangeEvent>());
  }

  public initialize(init: ManagerInit) {
    this._attendeeId = init.attendeeId;
    this._tenantSettings = init.tenantSettings;
    this._eventLogger = init.eventLogger;
    this._meetingId = init.meetingId;
    this._room = init.room;
    this._type = init.type;
    this._url = init.url;

    this._onConnectionStateChanged = this.onConnectionStateChanged.bind(Reactive.wrap(this));
    this.setState("initialized");
  }

  private close(reason: string) {
    this._connection?.close(reason);
    this._connection?.stateChanged.unbind(this._onConnectionStateChanged);
    this.destroyConnection(this._connection);
    this._connection = null;
  }

  private async open(abortSignal?: AbortSignal, retryCounter = 0) {
    try {
      const [createConnectionDuration, connection] = await Utility.time(() => this.createConnection());
      this._eventLogger.debug("open.initialized", `${this._type} connection created.`, createConnectionDuration);
      this._connection = connection;
      this._connection.requestTimeout = this.requestTimeout;
      if (retryCounter == 0) void this._eventLogger.debug("open", `Opening ${this._type} connection for attendee ${this._attendeeId} in meeting ${this._meetingId}...`);
      this._connection.stateChanged.bind(this._onConnectionStateChanged);
      const [openConnectionDuration] = await Utility.time(() => this._connection.open(abortSignal));
      this._eventLogger.debug("open.succeeded", `${this._type} connection opened.`, openConnectionDuration);
    } catch (error: any) {
      // handle abort signal
      if (abortSignal?.aborted) throw error;

      // handle retry exhaustion
      if (retryCounter >= this.maxRetries) throw error;

      // handle retry
      const delay = 200 * Math.pow(2, retryCounter);
      void this._eventLogger.warning(<Error>error, "open", `Reopening ${this._type} connection for attendee ${this._attendeeId} in meeting ${this._meetingId} after ${delay.toLocaleString()}ms...`);
      await Utility.delay(delay);
      await this.open(abortSignal, retryCounter + 1);
    }
  }

  private onConnectionStateChanged(e: ConnectionStateChangeEvent): void {
    this._connectionStateChanged.dispatch(e);
  }

  private async setState(state: ManagerState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <ManagerStateChangeEvent>{
      manager: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  protected abstract createConnection(): Promise<TConnection>;

  protected abstract destroyConnection(connection: TConnection): void;

  protected async onStarted(): Promise<void> { }

  protected async onStarting(): Promise<void> { }

  protected async onStopped(): Promise<void> { }

  protected async onStopping(): Promise<void> { }

  public async start(abortSignal?: AbortSignal): Promise<void> {
    if (this.isStarted) return;
    try {
      await this.setState("starting");
      await this.onStarting();
      await this.open(abortSignal, 0);
      await this.setState("started");
      await this.onStarted();
    } catch (error) {
      await this.setState("stopped");
      await this.onStopped();
      throw error;
    }
  }

  public async stop(reason?: string): Promise<void> {
    reason ??= "Media has been disabled."
    if (this.isInitialized || this.isStopped) return;
    try {
      await this.setState("stopping");
      await this.onStopping();
      this.close(reason);
    } finally {
      await this.setState("stopped");
      await this.onStopped();
    }
  }

  public updateStats(): Promise<void> {
    return this.connection?.updateStats();
  }
}