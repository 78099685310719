import RemoteAttendeeUpdateEvent from "./models/RemoteAttendeeUpdateEvent";
import MediaType from "./models/MediaType";
import Reactive from "../core/Reactive";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import RemoteMedia from "./RemoteMedia";
import TrackType from "./models/TrackType";
import Utility from "../core/Utility";

export default class RemoteMediaCollection extends ReadOnlyCollection<RemoteMedia> {
  private static readonly _empty = new RemoteMediaCollection();

  public static get empty(): RemoteMediaCollection { return this._empty; }

  private readonly _elementMapByAttendeeId: Map<string, RemoteMedia> = new Map<string, RemoteMedia>();
  private readonly _elementMapByDisplayAudioTrackIndex: Map<number, RemoteMedia> = new Map<number, RemoteMedia>();
  private readonly _elementMapByDisplayVideoTrackIndex: Map<number, RemoteMedia> = new Map<number, RemoteMedia>();
  private readonly _elementMapByUserAudioTrackIndex: Map<number, RemoteMedia> = new Map<number, RemoteMedia>();
  private readonly _elementMapByUserVideoTrackIndex: Map<number, RemoteMedia> = new Map<number, RemoteMedia>();

  private _onRemoteMediaAttendeeBound: (e: RemoteAttendeeUpdateEvent) => Promise<void>;
  private _onRemoteMediaAttendeeUnbound: (e: RemoteAttendeeUpdateEvent) => void;

  constructor() {
    super(remoteMedia => remoteMedia.id);
    this._onRemoteMediaAttendeeBound = this.onRemoteMediaAttendeeBound.bind(Reactive.wrap(this));
    this._onRemoteMediaAttendeeUnbound = this.onRemoteMediaAttendeeUnbound.bind(Reactive.wrap(this));
  }

  private getElementMapByTrackIndex(mediaType: MediaType, trackType: TrackType): Map<number, RemoteMedia> {
    if (mediaType == "display") {
      if (trackType == "audio") {
        return this._elementMapByDisplayAudioTrackIndex;
      }
      return this._elementMapByDisplayVideoTrackIndex;
    }
    if (trackType == "audio") {
      return this._elementMapByUserAudioTrackIndex;
    }
    return this._elementMapByUserVideoTrackIndex;
  }

  private async onRemoteMediaAttendeeBound(e: RemoteAttendeeUpdateEvent): Promise<void> {
    this._elementMapByAttendeeId.delete(e.previousAttendee?.id);
    this._elementMapByAttendeeId.set(e.attendee?.id, e.media);
  }

  private onRemoteMediaAttendeeUnbound(e: RemoteAttendeeUpdateEvent): void {
    this._elementMapByAttendeeId.delete(e.previousAttendee?.id);
  }

  /** @internal */
  public getByAttendeeId(attendeeId: string): RemoteMedia | undefined {
    if (this._elementMapByAttendeeId.has(attendeeId)){
      return this._elementMapByAttendeeId.get(attendeeId);
    }
    return undefined;
  }

  /** @internal */
  public getByTrackIndex(trackIndex: number, mediaType: MediaType, trackType: TrackType): RemoteMedia | undefined {
    const elementMapByTrackIndex = this.getElementMapByTrackIndex(mediaType, trackType);
    if (elementMapByTrackIndex.has(trackIndex)){
      return elementMapByTrackIndex.get(trackIndex);
    }
    return undefined;
  }

  /** @internal */
  public removeAll(): RemoteMedia[] {
    const elements = super.removeAll();
    this._elementMapByAttendeeId.clear();
    this._elementMapByDisplayAudioTrackIndex.clear();
    this._elementMapByDisplayVideoTrackIndex.clear();
    this._elementMapByUserAudioTrackIndex.clear();
    this._elementMapByUserVideoTrackIndex.clear();
    elements.forEach(element => {
      element.attendeeBound.unbind(this._onRemoteMediaAttendeeBound);
      element.attendeeUnbound.unbind(this._onRemoteMediaAttendeeUnbound);
    });
    return elements;
  }

  /** @internal */
  public tryAdd(element: RemoteMedia): boolean {
    if (super.tryAdd(element)) {
      element.attendeeBound.bind(this._onRemoteMediaAttendeeBound);
      element.attendeeUnbound.bind(this._onRemoteMediaAttendeeUnbound);
      if (!Utility.isNullOrUndefined(element.attendee?.id)) {
        this._elementMapByAttendeeId.set(element.attendee?.id, element);
      }
      if (!Utility.isNullOrUndefined(element.audioTrackIndex)) {
        this.getElementMapByTrackIndex(element.type, "audio").set(element.audioTrackIndex, element);
      }
      if (!Utility.isNullOrUndefined(element.videoTrackIndex)) {
        this.getElementMapByTrackIndex(element.type, "video").set(element.videoTrackIndex, element);
      }
      return true;
    }
    return false;
  }
    
  /** @internal */
  public tryRemove(key: string): boolean {
    const element = super.get(key);
    if (super.tryRemove(key)) {
      if (!Utility.isNullOrUndefined(element.attendee?.id)) {
        this._elementMapByAttendeeId.delete(element.attendee?.id);
      }
      if (!Utility.isNullOrUndefined(element.audioTrackIndex)) {
        this.getElementMapByTrackIndex(element.type, "audio").delete(element.audioTrackIndex);
      }
      if (!Utility.isNullOrUndefined(element.videoTrackIndex)) {
        this.getElementMapByTrackIndex(element.type, "video").delete(element.videoTrackIndex);
      }
      element.attendeeBound.unbind(this._onRemoteMediaAttendeeBound);
      element.attendeeUnbound.unbind(this._onRemoteMediaAttendeeUnbound);
      return true;
    }
    return false;
  }
}
