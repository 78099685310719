import Attendee from "./Attendee";
import AttendeeModel from "./api/models/Attendee";
import Collection from "./models/Collection";
import ControlConnection from "./control/Connection";
import Guard from "./core/Guard";
import Meeting from "./Meeting";
import ReadOnlyCollection from "./core/ReadOnlyCollection";
import SubscribedReason from "./models/SubscribedReason";
import SubscribedViewInit from "./models/SubscribedViewInit";

export default class SubscribedAttendeeCollection extends ReadOnlyCollection<Attendee> {
  private _controlConnection: ControlConnection;
  private _meeting: Meeting;

  /** @internal */
  constructor() {
    super(attendee => attendee.id);
  }

  /** @internal */
  public load(init: SubscribedViewInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.meeting, "init.meeting");
    this._controlConnection = init.controlConnection;
    this._meeting = init.meeting;
  }

  /** @internal */
  public async pinAttendee(attendee: AttendeeModel) {
    const subscribedAttendee = this.get(attendee.id);
    if (!subscribedAttendee) {
      const pinnedAttendee = new Attendee({
        controlConnection: this._controlConnection,
        isLocal: this._meeting.localAttendee.id == attendee.id,
        model: attendee,
        meeting: this._meeting,
      });
      pinnedAttendee.isPinned = true;
      this.tryAdd(pinnedAttendee);
    } else {
      subscribedAttendee.isPinned = true;
    }
  }

  /** @internal */
  public processResponse(attendees: Collection<AttendeeModel>) {
    attendees.values.forEach((a) => {
      const attendee = this.get(a.id);
      if (!attendee) {
        const joinedAttendee = new Attendee({
          controlConnection: this._controlConnection,
          isLocal: this._meeting.localAttendee.id == a.id,
          model: a,
          meeting: this._meeting,
        });
        this.tryAdd(joinedAttendee);
      } else {
        attendee.model = a;
      }
    });
  }

  /** @internal */
  public async subscribeToAttendee(attendeeId: string, reason: SubscribedReason): Promise<Attendee> {
    let subscribedAttendee = this.get(attendeeId);
    if (!subscribedAttendee) {
      // tell the server that we need to be subscribed to this attendee
      const msg = await this._controlConnection.subscribeToAttendee(attendeeId, reason);
      subscribedAttendee = new Attendee({
        controlConnection: this._controlConnection,
        isLocal: this._meeting.localAttendee.id == msg.attendee.id,
        model: msg.attendee,
        meeting: this._meeting,
      });
      this.tryAdd(subscribedAttendee);
    }
    return subscribedAttendee;
  }

  /** @internal */
  public tryAddAttendee(attendee: AttendeeModel): boolean {
    if (!attendee) return false;
    const subscribedAttendee = new Attendee({
      controlConnection: this._controlConnection,
      isLocal: this._meeting.localAttendee.id == attendee.id,
      model: attendee,
      meeting: this._meeting,
    });
    return this.tryAdd(subscribedAttendee);
  }

  /** @internal */
  public async unpinAttendee(attendeeId: string) {
    const pinnedAttendee = this.get(attendeeId);
    if (pinnedAttendee) {
      pinnedAttendee.isPinned = false;
    }
  }
}