export default class FrameRateMonitor {
  private _samples: number[] = [];

  public get frameRate(): number | undefined { return this._samples.length || undefined; }

  public addTimestamp(timestamp: number) {
    this._samples.push(timestamp);
    while (this._samples[0] < (timestamp - 1000)) {
      this._samples.splice(0, 1);
    }
  }
}