import Attendee from "../Attendee";
import EdgeConnection from "../edge/Connection";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import LocalMedia from "./LocalMedia";
import Media from "./Media";
import MediaType from "./models/MediaType";
import RemoteAttendeeUpdateEvent from "./models/RemoteAttendeeUpdateEvent";
import RemoteAudioTrack from "./RemoteAudioTrack";
import RemoteVideoTrack from "./RemoteVideoTrack";
import Utility from "../core/Utility";

export default class RemoteMedia extends Media {
  private readonly _attendeeBound = new EventOwnerAsync<RemoteAttendeeUpdateEvent>();
  private readonly _attendeeUnbound = new EventOwner<RemoteAttendeeUpdateEvent>();
  private readonly _connection: EdgeConnection;
  private readonly _immutableAudioTrack: RemoteAudioTrack;
  private readonly _immutableVideoTrack: RemoteVideoTrack;
  private readonly _localAttendeeId: string;
  private readonly _stream: MediaStream;

  private _attendee: Attendee = null;
  private _audioTrack: RemoteAudioTrack;
  private _audioTrackIndex: number | null = null;
  private _linkedLocalMedia: LocalMedia = null;
  private _linkedRemoteAudio: RemoteMedia = null;
  private _linkedRemoteVideo: RemoteMedia = null;
  private _videoTrack: RemoteVideoTrack;
  private _videoTrackIndex: number | null = null;

  /** @internal */
  public get audioTrackIndex(): number { return this._audioTrackIndex; }

  /** @internal */
  public set audioTrackIndex(value: number) { this._audioTrackIndex = value; }
  
  /** @internal */
  public get connection(): EdgeConnection { return this._connection; }

  /** @internal */
  public get immutableAudioTrack(): RemoteAudioTrack { return this._immutableAudioTrack; }

  /** @internal */
  public get immutableVideoTrack(): RemoteVideoTrack { return this._immutableVideoTrack; }

  /** @internal */
  public get linkedLocalMedia(): LocalMedia { return this._linkedLocalMedia; }

  /** @internal */
  public set linkedLocalMedia(value: LocalMedia) { this._linkedLocalMedia = value; }

  /** @internal */
  public get linkedRemoteAudio(): RemoteMedia { return this._linkedRemoteAudio; }

  /** @internal */
  public set linkedRemoteAudio(value: RemoteMedia) {
    if (this._linkedRemoteAudio == value) return;
    if (this._audioTrack?.stream) this.stream.removeTrack(this._audioTrack.stream);
    this._linkedRemoteAudio = value;
    this._audioTrack = value?.immutableAudioTrack ?? this._immutableAudioTrack;
    if (this._audioTrack?.stream) this.stream.addTrack(this._audioTrack.stream);
  }

  /** @internal */
  public get linkedRemoteVideo(): RemoteMedia { return this._linkedRemoteVideo; }

  /** @internal */
  public set linkedRemoteVideo(value: RemoteMedia) {
    if (this._linkedRemoteVideo == value) return;
    if (this._videoTrack?.stream) this.stream.removeTrack(this._videoTrack.stream);
    this._linkedRemoteVideo = value;
    this._videoTrack = value?.immutableVideoTrack ?? this._immutableVideoTrack;
    if (this._videoTrack?.stream) this.stream.addTrack(this._videoTrack.stream);
  }
  
  /** @internal */
  public get videoTrackIndex(): number { return this._videoTrackIndex; }

  /** @internal */
  public set videoTrackIndex(value: number) { this._videoTrackIndex = value; }

  public get attendee(): Attendee { return this._attendee; }
  public get audioTrack(): RemoteAudioTrack { return this._audioTrack; }
  public get isRemote(): boolean { return this.attendee?.id != this._localAttendeeId; }
  public get stream(): MediaStream { return this._stream; }
  public get videoTrack(): RemoteVideoTrack { return this._videoTrack; }
  
  /** @event */
  public get attendeeBound(): EventOwnerAsync<RemoteAttendeeUpdateEvent> { return this._attendeeBound; }
  /** @event */
  public get attendeeUnbound(): EventOwner<RemoteAttendeeUpdateEvent> { return this._attendeeUnbound; }

  /** @internal */
  public constructor(connection: EdgeConnection, localAttendeeId: string, type: MediaType) {
    super(type);
    this._connection = connection;
    this._audioTrack = this._immutableAudioTrack = new RemoteAudioTrack(this);
    this._videoTrack = this._immutableVideoTrack = new RemoteVideoTrack(this);
    this._localAttendeeId = localAttendeeId;
    this._stream = new MediaStream();
  }

  /** @internal */
  public async bindAttendee(attendee: Attendee): Promise<void> {
    Guard.isNotNullOrUndefined(attendee, "attendee");
    if (this._attendee == attendee) return;
    const previousAttendee = this._attendee;
    this._attendee = attendee;
    if (this._attendee.id != this._localAttendeeId && !Utility.isNullOrUndefined(this._videoTrackIndex) && this.type == "user") await this._attendee.bindMedia(this);
    await this._attendeeBound.dispatch({
      attendee: attendee,
      media: this,
      previousAttendee: previousAttendee,
    });
  }
  
  /** @internal */
  public async startAudioStream(trackStream: MediaStreamTrack): Promise<void> {
    await this.immutableAudioTrack.start(trackStream);
    this.stream.addTrack(trackStream);
  }

  /** @internal */
  public async startVideoStream(trackStream: MediaStreamTrack): Promise<void> {
    await this.immutableVideoTrack.start(trackStream);
    this.stream.addTrack(trackStream);
  }
    
  /** @internal */
  public stopAudioStream(): void {
    const trackStream = this.immutableAudioTrack?.stream;
    if (!trackStream) return;
    this.stream.removeTrack(trackStream);
    this.immutableAudioTrack.stop();
  }

  /** @internal */
  public stopVideoStream(): void {
    const trackStream = this.immutableVideoTrack?.stream;
    if (!trackStream) return;
    this.stream.removeTrack(trackStream);
    this.immutableVideoTrack.stop();
  }

  /** @internal */
  public unbindAttendee(): void {
    if (!this._attendee) return;
    const previousAttendee = this._attendee;
    if (this._attendee.id != this._localAttendeeId && !Utility.isNullOrUndefined(this._videoTrackIndex) && this.type == "user") this._attendee.unbindMedia();
    this._attendee = null;
    this._attendeeUnbound.dispatch({
      attendee: null,
      media: this,
      previousAttendee: previousAttendee,
    });
  }

  public async setAudioDevice(deviceId?: string): Promise<void> {
    await this.audioTrack?.setDevice(deviceId);
  }
  
  public async useNextAudioDevice(): Promise<void> {
    await this.audioTrack?.useNextDevice();
  }
  
  public async usePreviousAudioDevice(): Promise<void> {
    await this.audioTrack?.usePreviousDevice();
  }
}
