export default class HttpRequestAbortedError extends Error {
  private readonly _request: Request;

  public get request(): Request { return this._request; }

  constructor(message: string, request: Request) {
    super(message);
    this.name = "HttpRequestAbortedError";
    this._request = request;
  }
}