
import { defineComponent } from "vue";
import { BadRequestError } from "@liveswitch/sdk";

export default defineComponent({
  data() {
    return {
      
    }
  },
  props: {
    error: {
      type: Error,
    },
  },
});
