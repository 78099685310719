import DevicesEvent from "./models/DevicesEvent";
import DeviceManager from "./DeviceManager";
import LocalAudioTrack from "./LocalAudioTrack";
import Reactive from "../core/Reactive";

export default class UserAudioTrack extends LocalAudioTrack {
  private static _contentHint: string = "speech";

  public static get contentHint(): string { return this._contentHint; }
  public static set contentHint(value: string) { this._contentHint = value; }

  private readonly _onDevicesUpdated: (e: DevicesEvent) => Promise<void>;

  private _deviceId: string = null;
  private _isMuted = false;
  private _requestedAutoGainControl = true;
  private _requestedEchoCancellation = true;
  private _requestedDeviceId: string = null;
  private _requestedDeviceRequired = false;
  private _requestedNoiseSuppression = true;
  
  public get deviceId(): string { return this._deviceId; }
  public get isMuted(): boolean { return this._isMuted; }
  public get isPaused(): boolean { return this.media?.attendee?.isAudioPaused ?? false; }
  public get requestedAutoGainControl(): boolean { return this._requestedAutoGainControl; }
  public get requestedEchoCancellation(): boolean { return this._requestedEchoCancellation; }
  public get requestedDeviceId(): string { return this._requestedDeviceId; }
  public get requestedDeviceRequired(): boolean { return this._requestedDeviceRequired; }
  public get requestedNoiseSuppression(): boolean { return this._requestedNoiseSuppression; }

  public constructor() {
    super();
    this._onDevicesUpdated = this.onDevicesUpdated.bind(Reactive.wrap(this));
  }

  private async onDevicesUpdated(e: DevicesEvent): Promise<void> {
    if (this._deviceId == "default" || e.removed.find(d => d.id == this._deviceId) || e.added.find(d => d.id == this._requestedDeviceId)) {
      this._deviceId = null;
      await this.replaceStream();
      await this.updateDeviceId();
    }
  }

  private async updateDeviceId(): Promise<void> {
    if (!this.stream) return;
    this._deviceId = this._requestedDeviceId;
    const settings = this.stream.getSettings();
    if (!settings.deviceId) return;
    this._deviceId = settings.deviceId;
  }

  /** @internal */
  public muteInternal(): void {
    this._isMuted = true;
    if (this.stream) this.stream.enabled = false;
  }

  /** @internal */
  public unmuteInternal(): void {
    this._isMuted = false;
    if (this.stream) this.stream.enabled = true;
  }

  protected async onStarted(): Promise<void> {
    await DeviceManager.shared.refresh();
    DeviceManager.shared.audioInputsUpdated.bind(this._onDevicesUpdated);
    await this.updateDeviceId();
  }

  protected async onStarting(): Promise<void> {
    await DeviceManager.shared.start();
  }

  protected async onStopping(): Promise<void> {
    DeviceManager.shared.audioInputsUpdated.unbind(this._onDevicesUpdated);
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    try {
      if ("contentHint" in stream) stream.contentHint = UserAudioTrack.contentHint;
    } catch { /* best effort */ }
  }

  public async getConstraints(): Promise<MediaTrackConstraints> {
    const constraints = await super.getConstraints();
    constraints.autoGainControl = { ideal: this._requestedAutoGainControl };
    constraints.echoCancellation = { ideal: this._requestedEchoCancellation };
    constraints.noiseSuppression = { ideal: this._requestedNoiseSuppression };
    if (this._requestedDeviceId) {
      if (this._requestedDeviceRequired) {
        constraints.deviceId = { exact: this._requestedDeviceId };
      } else {
        constraints.deviceId = { ideal: this._requestedDeviceId };
      }
    }
    return constraints;
  }

  public async mute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.muteAudio();
    else this.muteInternal();
  }

  public async setAutoGainControl(autoGainControl: boolean): Promise<void> {
    if (this._requestedAutoGainControl == autoGainControl) return;
    this._requestedAutoGainControl = autoGainControl;
    await this.stream?.applyConstraints(await this.getConstraints());
  }

  public async setDevice(deviceId?: string, required?: boolean): Promise<void> {
    this._deviceId = null;
    this._requestedDeviceId = deviceId ?? null;
    this._requestedDeviceRequired = required ?? false;
    await this.replaceStream();
    await this.updateDeviceId();
  }

  public async setEchoCancellation(echoCancellation: boolean): Promise<void> {
    if (this._requestedEchoCancellation == echoCancellation) return;
    this._requestedEchoCancellation = echoCancellation;
    await this.stream?.applyConstraints(await this.getConstraints());
  }

  public async setNoiseSuppression(noiseSuppression: boolean): Promise<void> {
    if (this._requestedNoiseSuppression == noiseSuppression) return;
    this._requestedNoiseSuppression = noiseSuppression;
    await this.stream?.applyConstraints(await this.getConstraints());
  }

  public async unmute(): Promise<void> {
    const attendee = this.media.attendee;
    if (attendee) await attendee.unmuteAudio();
    else this.unmuteInternal();
  }

  public async useNextDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.audioInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.next(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }

  public async usePreviousDevice(manager?: DeviceManager): Promise<void> {
    manager ??= DeviceManager.shared;
    await manager.start();
    const devices = manager.audioInputs;
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = devices.previous(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id, this._requestedDeviceRequired);
  }
}
