import Attendee from "../Attendee";
import BaseManager from "../Manager";
import Client from "./Client";
import Connection from "./Connection";
import DataChannelStats from "../DataChannelStats";
import EventLogger from "../event/Logger";
import Guard from "../core/Guard";
import LocalMedia from "../media/LocalMedia";
import ManagerInit from "./models/ManagerInit";
import MediaStats from "../MediaStats";
import Reactive from "../core/Reactive";
import TrackStats from "../TrackStats";
import TrackType from "../media/models/TrackType";
import UserMedia from "../media/UserMedia";

export default class Manager extends BaseManager<Connection> {

  private readonly _onMediaRejected: () => Promise<void>;
  private readonly _onMediaReplaced: () => Promise<void>;

  private _client: Client;
  private _init: ManagerInit;
  private _media: LocalMedia = null;

  public get maxRetries(): number { return this._client.maxRetries; }
  public set maxRetries(value: number) { this._client.maxRetries = value; }
  public get media(): LocalMedia { return this._media; }
  public get mediaChannelStats(): DataChannelStats { return this.connection?.mediaChannelStats; }
  public get mediaStats(): MediaStats { return this.connection?.mediaStats; }
  public get requestTimeout(): number { return this._client.requestTimeout; }
  public set requestTimeout(value: number) { this._client.requestTimeout = value; }

  constructor() {
    super();
    this._onMediaRejected = this.onMediaRejected.bind(Reactive.wrap(this));
    this._onMediaReplaced = this.onMediaReplaced.bind(Reactive.wrap(this));
  }

  public init(init: ManagerInit) {
    super.initialize({
      attendeeId: init.attendeeId,
      tenantSettings: init.tenantSettings,
      eventLogger: new EventLogger(init.apiClient, "OriginManager", init.attendeeId, init.meetingId, init.clusterId),
      meetingId: init.meetingId,
      room: init.room,
      type: "origin",
      url: init.originServerUrl
    });
    this._init = init;
    this._client = new Client(init);
  }

  private async onMediaRejected(): Promise<void> {
    const media = this.media;
    if (!media) return;
    await media.stop();
    void this.eventLogger?.debug("onMediaRejected", `Local ${media.type} media has been rejected and stopped.`);
  }

  private async onMediaReplaced(): Promise<void> {
    const media = this.media;
    if (!media) return;
    await media.stop();
    void this.eventLogger?.debug("onMediaReplaced", `Local ${media.type} media has been replaced and stopped.`);
  }

  /** @internal */
  public pauseInternal(): Promise<void> {
    return this.connection?.pauseInternal();
  }

  /** @internal */
  public resumeInternal(): Promise<void> {
    return this.connection?.resumeInternal();
  }

  protected async createConnection(): Promise<Connection> {
    const connection = new Connection(Object.assign({
      attendeeId: this.attendeeId,
      client: this._client,
      tenantSettings: this.tenantSettings,
      meetingId: this.meetingId,
    }, this._init));

    connection.mediaRejected.bind(this._onMediaRejected);
    connection.mediaReplaced.bind(this._onMediaReplaced);

    if (this.media) await connection.setMedia(this._media);

    return connection;
  }

  protected destroyConnection(connection: Connection): void {
    connection.mediaRejected.unbind(this._onMediaRejected);
    connection.mediaReplaced.unbind(this._onMediaReplaced);
  }

  public getSenderStats(trackType: TrackType): TrackStats {
    return this.connection?.getSenderStats(trackType);
  }

  public async setMedia(media: LocalMedia, attendee: Attendee, closing: boolean, abortSignal?: AbortSignal): Promise<void> {
    if (media == this._media) return;
    if (media) {
      Guard.isNotNullOrUndefined(attendee, "attendee");
      this._media = media;
      await media.bindAttendee(attendee);
      media.room = this.room;
      // synchronize muted state
      // - if attendee is muted and local media is not, mute local media
      // - if local media is muted and attendee is not, mute attendee
      if (media.type == "user") {
        const userMedia = <UserMedia>media;
        const audioTrack = userMedia.audioTrack;
        if (audioTrack) {
          if (attendee.isAudioMuted && !audioTrack.isMuted) audioTrack.muteInternal();
          if (!attendee.isAudioMuted && audioTrack.isMuted) await attendee.muteAudio();
        }
        const videoTrack = userMedia.videoTrack;
        if (videoTrack) {
          if (attendee.isVideoMuted && !videoTrack.isMuted) videoTrack.muteInternal();
          if (!attendee.isVideoMuted && videoTrack.isMuted) await attendee.muteVideo();
        }
      }
      await this.connection?.setMedia(media);
    } else {
      if (!closing) await this.connection?.setMedia(null);
      this._media.unbindAttendee();
      this._media = null;
    }
  }
}