import Attendee from "../Attendee";
import AudioTrack from "./AudioTrack";
import MediaType from "./models/MediaType";
import Utility from "../core/Utility";
import VideoTrack from "./VideoTrack";

export default abstract class Media {
  private readonly _id: string = Utility.generateGuid();
  private readonly _type: MediaType;

  public abstract get attendee(): Attendee | null;
  public abstract get audioTrack(): AudioTrack;
  public get id(): string { return this._id; }
  public get isDisplay(): boolean { return this._type == "display"; }
  public get isLocal(): boolean { return !this.isRemote; }
  public get isUser(): boolean { return this._type == "user"; }
  public abstract get isRemote(): boolean;
  public abstract get stream(): MediaStream;
  public get type(): MediaType { return this._type; }
  public abstract get videoTrack(): VideoTrack;
  
  public constructor(type: MediaType) {
    this._type = type;
  }
}
