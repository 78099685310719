import Utility from "./Utility";

export default class Guard {
  public static isEqualTo(value: number, equalTo: number, name: string) {
    if (value == equalTo) return;
    throw new Error(`Parameter ${name} has value ${value} but must be equal to ${equalTo}.`);
  }

  public static isGreaterThan(value: number, greaterThan: number, name: string) {
    if (value > greaterThan) return;
    throw new Error(`Parameter ${name} has value ${value} but must be greater than ${greaterThan}.`);
  }

  public static isGreaterThanOrEqualTo(value: number, greaterThanOrEqualTo: number, name: string) {
    if (value >= greaterThanOrEqualTo) return;
    throw new Error(`Parameter ${name} has value ${value} but must be greater than or equal to ${greaterThanOrEqualTo}.`);
  }
  
  public static isLengthWithinBounds(value: string, name: string, max: number, min?: number) {
    if (Utility.isNullOrUndefined(value)) return;
    if (Utility.isNullOrUndefined(min)) min = 0;
    if (value.length < min) throw new Error(`Parameter ${name} has length ${value.length}. Minimum length is ${min}.`);
    if (value.length > max) throw new Error(`Parameter ${name} has length ${value.length}. Maximum length is ${max}.`);
  }

  public static isLessThan(value: number, lessThan: number, name: string) {
    if (value < lessThan) return;
    throw new Error(`Parameter ${name} has value ${value} but must be less than ${lessThan}.`);
  }

  public static isLessThanOrEqualTo(value: number, lessThanOrEqualTo: number, name: string) {
    if (value <= lessThanOrEqualTo) return;
    throw new Error(`Parameter ${name} has value ${value} but must be less than or equal to ${lessThanOrEqualTo}.`);
  }

  public static isNotNullOrUndefined(value: any, name: string) {
    if (Utility.isNullOrUndefined(value)) throw new Error(`Parameter ${name} cannot be null or undefined.`);
  }
}