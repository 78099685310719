import ServerError from "./ServerError";

export default class UnauthorizedError extends ServerError {
  private readonly _serverExceptionType: string;
  private readonly _serverExceptionMessage: string;

  constructor(type: string | null, message: string) {
    super(`The request was not authorized. (${type ? type + ": " : ""}${message})`);
    this.name = "UnauthorizedError";
    this._serverExceptionType = type;
    this._serverExceptionMessage = message;
  }

  public get serverExceptionType(): string { return this._serverExceptionType; }
  public get serverExceptionMessage(): string { return this._serverExceptionMessage; }
}