
import FakeUserMedia from "@/classes/FakeUserMedia";
import { Identity, Log, Meeting, UserMedia } from "@liveswitch/sdk";
import { defineComponent } from "vue";

const getParams = () => {
  return new URLSearchParams(
    [...new URLSearchParams(window.location.search)].map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
};

type JoinMode = "controlOnly" | "audioOnly" | "videoOnly" | "audioVideo";
type MediaDirectionality = "sendAndReceive" | "sendOnly" | "receiveOnly";
type State = "starting" | "started" | "stopping" | "stopped";

interface Timings {
  appJoin: number,
  localUserMediaStart: number,
  meetingIsReady: number,
  meetingJoined: number,
}


export default defineComponent({
  data(): {
    apiKey: string;
    appMeetingState: State;
    identityServiceUrl: string;
    joinMode: JoinMode;
    joinStartTimeApp: number;
    joinStartTimeMeeting: number;
    mediaDirectionality: MediaDirectionality;
    meeting: Meeting | undefined;
    messages: string[];
    timings: Timings;
    useAttendeeList: boolean;
    useChat: boolean;
    useFakeMedia: boolean;
  } {
    const params = getParams();
    const defaultApiKey = params.get("apikey") ?? process.env.VUE_APP_API_KEY!;
    const defaultIdentityServiceUrl =
      params.get("identityserviceurl") ??
      process.env.VUE_APP_IDENTITY_SERVICE_URL!;
    if (!defaultApiKey)
      throw new Error("VUE_APP_API_KEY is missing and required.");
    if (!defaultIdentityServiceUrl)
      throw new Error("VUE_APP_IDENTITY_SERVICE_URL is missing and required.");
    return {
      apiKey: defaultApiKey,
      appMeetingState: "stopped",
      identityServiceUrl: defaultIdentityServiceUrl,
      joinMode: "controlOnly",
      joinStartTimeApp: 0,
      joinStartTimeMeeting: 0,
      mediaDirectionality: "sendAndReceive",
      meeting: undefined,
      messages: [],
      timings: <Timings>{},
      useAttendeeList: true,
      useChat: true,
      useFakeMedia: false,
    };
  },
  mounted() {
    Log.level = "debug";
    this.joinMode = this.$route.query.joinMode as JoinMode ?? "audioVideo";
    this.mediaDirectionality = this.$route.query.mediaDirectionality as MediaDirectionality ?? "sendAndReceive";
    this.useAttendeeList = this.$route.query.useAttendeeList ? this.$route.query.useAttendeeList == "true" : true;
    this.useChat = this.$route.query.useChat ? this.$route.query.useChat == "true" : true;
    this.useFakeMedia = this.$route.query.useFakeMedia ? this.$route.query.useFakeMedia == "true" : false;
  },
  watch: {
    'meeting.isReady'(value){
      if (!value) return;
      this.timings.meetingIsReady = performance.now() - this.joinStartTimeMeeting;
      this.timings.appJoin = performance.now() - this.joinStartTimeApp;
    }
  },
  methods: {
   log(message: string) {
      this.messages.push(message);
    },
    
    async start() {
      this.joinStartTimeApp = performance.now();
      this.messages = [];
      this.appMeetingState = "starting";
      this.log("Starting...");
      try {
        const identity = new Identity({
          apiKey: this.apiKey,
          identityServiceUrl: this.identityServiceUrl,
          type: "anonymous",
        });

        this.meeting = new Meeting({ identity: identity });
        // @ts-ignore
        globalThis.__meeting = this.meeting;

        this.log("Setting user media...");

        const useLocalUserMedia = this.joinMode != "controlOnly" && this.mediaDirectionality != "receiveOnly";        
        const useAudio = this.joinMode == "audioOnly" || this.joinMode == "audioVideo";
        const useVideo = this.joinMode == "videoOnly" || this.joinMode == "audioVideo";

        if (useLocalUserMedia && (useAudio || useVideo)) {
          if (this.useFakeMedia) {
            await this.meeting.setLocalUserMedia(new FakeUserMedia(useAudio, useVideo));
          } else {
            await this.meeting.setLocalUserMedia(new UserMedia(useAudio, useVideo));
          }
          
          this.log("Starting user media...");
          const localMediaStart = performance.now();
          await this.meeting.localUserMedia.start();
          this.timings.localUserMediaStart = performance.now() - localMediaStart;
        }

        if (this.joinMode == "audioOnly") {
          this.meeting.maxVisibleDisplayMedias = 0;
          this.meeting.maxVisibleUserMedias = 0;
          this.meeting.maxVisibleUserMediasDuringDisplayMedia = 0;
        }

        this.log("Joining meeting...");
        this.joinStartTimeMeeting = performance.now();
        await this.meeting.join({
          onProgress: (e) => {
            this.log(
              `Joining meeting (${e.progress.toLocaleString(undefined, {
                style: "percent",
              })})...`
            );
          },
          roomKey: this.$route.query.roomKey as string ?? undefined,
          useAttendeeList: this.useAttendeeList,
          useCamera: (this.joinMode == "audioVideo" || this.joinMode == "videoOnly") && this.mediaDirectionality != "receiveOnly",
          useChat: this.useChat,
          useMicrophone: (this.joinMode == "audioVideo" || this.joinMode == "audioOnly") && this.mediaDirectionality != "receiveOnly",
          useRemoteMedia: this.joinMode != "controlOnly" && this.mediaDirectionality != "sendOnly",
          useScreenShare: this.joinMode != "controlOnly" && this.mediaDirectionality != "receiveOnly",
        });

        this.$router.push({query: {
          roomKey: this.meeting.roomKey, 
          joinMode: this.joinMode,
          mediaDirectionality: this.mediaDirectionality,
          useAttendeeList: this.useAttendeeList.toLocaleString(),
          useChat: this.useChat.toLocaleString() }
        });

        this.appMeetingState = "started";
        this.log("Started.");
        this.timings.meetingJoined = performance.now() - this.joinStartTimeMeeting;
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        this.log(`Unexpected error. ${error}`);
        await this.stop();
      }
    },
    async stop() {
      this.joinStartTimeApp = 0;
      this.joinStartTimeMeeting = 0;
      this.timings = <Timings>{};
      this.appMeetingState = "stopping";
      this.log("Stopping...");
      try {
        this.log("Leaving meeting...");
        await this.meeting?.leave();

        this.log("Stopping user media...");
        await this.meeting?.localUserMedia?.stop();

        this.meeting = undefined
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        this.log(`Unexpected error. ${error}`);
      } finally {
        this.appMeetingState = "stopped";
        this.log("Stopped.");
      }
    },
  },
});
