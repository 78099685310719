import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import ConnectionStats from "../ConnectionStats";
import Constraints from "./models/Constraints";
import DataChannelStats from "../DataChannelStats";
import DispatchQueue from "../core/DispatchQueue";
import EventLogger from "../event/Logger";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import LocalMedia from "../media/LocalMedia";
import LocalMediaOptions from "../models/LocalMediaOptions";
import LocalTrackPriority from "../models/LocalTrackPriority";
import MediaEvent from "./models/MediaEvent";
import MediaStats from "../MediaStats";
import MediaType from "../media/models/MediaType";
import Message from "./models/Message";
import PromiseCompletionSource from "../core/PromiseCompletionSource";
import Reactive from "../core/Reactive";
import TrackStats from "../TrackStats";
import TrackType from "../media/models/TrackType";
import Log from "../logging/Log";
import StepType from "./models/StepType";
import VideoUtility from "../core/VideoUtility";
import {  VideoOriginFrameRateDisplayMin,
  VideoOriginPixelCountDisplayMin,
  VideoOriginJitterDisplayLow,
  VideoOriginJitterDisplayMedium,
  VideoOriginJitterDisplayHigh,
  VideoOriginPacketLossDisplayLow,
  VideoOriginPacketLossDisplayMedium,
  VideoOriginPacketLossDisplayHigh,
  AudioOriginJitterThresholdDisplayLow,
  AudioOriginJitterThresholdDisplayMedium,
  AudioOriginJitterThresholdDisplayHigh,
  AudioOriginPacketLossThresholdDisplayLow,
  AudioOriginPacketLossThresholdDisplayMedium,
  AudioOriginPacketLossThresholdDisplayHigh,
  VideoOriginFrameRateUserMin,
  VideoOriginPixelCountUserMin,
  VideoOriginJitterUserLow,
  VideoOriginJitterUserMedium,
  VideoOriginJitterUserHigh,
  VideoOriginPacketLossUserLow,
  VideoOriginPacketLossUserMedium,
  VideoOriginPacketLosUserHigh,
  AudioOriginJitterThresholdUserLow,
  AudioOriginJitterThresholdUserMedium,
  AudioOriginJitterThresholdUserHigh,
  AudioOriginPacketLossThresholUserLow,
  AudioOriginPacketLossThresholdUserMedium,
  AudioOriginPacketLossThresholdUserHigh
} from "./models/TenantSettings";

const qualityLimitationReasons: Map<String, number> = new Map([["none", 1000], ["bandwidth", 2000], ["cpu", 3000], ["other", 4000]]);
const qualityLimitationReasonNone = 1000;
const qualityLimitationReasonUs = 100; // SDK is making the constraint adjustment
const qualityLimitationReasonDecrease = 10;
const qualityLimitationReasonIncrease = 20
const qualityLimitationReasonBitrate = 1;
const qualityLimitationReasonFrameRate = 3;
const qualityLimitationReasonResolution = 5;

const trackTypes: TrackType[] = ["audio", "video"];
/*
* Audio impairment default statuses. 
*/
export enum AudioHealthIssues {
  JITTERHIGH = "Jitter - High",
  JITTERMEDIUM = "Jitter - Medium",
  JITTERLOW = "Jitter - Low",
  PACKETLOSSHIGH = "Packet Loss - High",
  PACKETLOSSMEDIUM = "Packet Loss - Medium",
  PACKETLOSSLOW = "Packet Loss - Low",
  ROUNDTRIPTIMEHIGH = "Round Trip Time - High",
  ROUNDTRIPTIMEMEDIUM = "Round Trip Time - Medium",
  ROUNDTRIPTIMELOW = "Round Trip Time - Low"
}
export default class Connection extends ConnectionBase<Message> {
  private readonly _audioLevelInterval?: number;
  private readonly _client: ClientModel;
  private readonly _mediaChannel: RTCDataChannel;
  private readonly _mediaChannelStats = new DataChannelStats();
  private readonly _mediaNotificationQueue = new DispatchQueue();
  private readonly _mediaOptions: LocalMediaOptions;
  private readonly _mediaRejected = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaReplaced = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaStats = new MediaStats();
  private readonly _mediaType: MediaType;
  private readonly _onAudioTrackStreamBound: () => Promise<void>;
  private readonly _onAudioTrackStreamUnbound: () => void;
  private readonly _onMediaChannelClose: () => void;
  private readonly _onMediaChannelClosing: () => void;
  private readonly _onMediaChannelError: (ev: any) => void;
  private readonly _onMediaChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onMediaChannelOpen: () => void;
  private readonly _onMediaStateChanged: () => Promise<void>;
  private readonly _onVideoTrackFrameSizeChanged: () => void;
  private readonly _onVideoTrackStreamBound: () => Promise<void>;
  private readonly _onVideoTrackStreamUnbound: () => void;
  private readonly _replicationCount?: number;
  private readonly _senders = new Map<TrackType, RTCRtpSender>();
  private readonly _senderStats = new Map<TrackType, TrackStats>();
  private readonly _stats = new ConnectionStats();
  private readonly _transceivers = new Map<TrackType, RTCRtpTransceiver>();

  private _jitterHigh = 120;
  private _jitterMedium = 79;
  private _jitterLow = 30;
  private _packetLossHigh = .28;
  private _packetLossMedium = .08;
  private _packetLossLow = .03;

  // Audio impairment default thresholds.
  // - Jitter
  private _audioJitterThresholdHigh: number = 120;
  private _audioJitterThresholdMedium: number = 79;
  private _audioJitterThresholdLow: number = 30;
  // - Packet Loss
  private _audioPacketLossThresholdHigh: number = 0.28;
  private _audioPacketLossThresholdMedium: number = 0.08;
  private _audioPacketLossThresholdLow: number = 0.03;

  private _answerUpdated: PromiseCompletionSource<Message>;
  private _bitrateIncreases = 0;
  private _bitrateSteady = 0;
  private _bitrateThrottle = 1.0;
  private _encodingEnabled = true;
  private _lastStepType: StepType = "pixelCount";
  private _largePacketLossEvent = false;
  private _media: LocalMedia = null;
  private _mediaChannelBytesReceived = 0;
  private _mediaChannelBytesSent = 0;
  private _minBitrate = 20;
  private _minAudioBitrate = 12;
  private _minFrameRate = 1;
  private _minHeight = 240;
  private _minWidth = 320;
  private _maxBitrate = 2200;
  private _maxAudioBitrate = 96; 
  private _maxAudioJitterDelay = 40;
  private _maxFrameRate = 25;
  private _maxHeight = 1080;
  private _maxVideoJitterDelay = 200;
  private _maxWidth = 1920;
  private _minPixelCount = 76800;
  private _maxPixelCount = 2073600;
  private _priorityAudio: LocalTrackPriority = "high";
  private _priorityVideo: LocalTrackPriority = "low";
  private _qualityLimitationReason = 0;
  private _rampedUp = false;
  private _rampUpSeconds = 5;
  private _recoveryTime = 0;
  private _serverConstraints: Constraints = null;
  private _clientConstraints: Constraints = null;
  private _estimatedConstraints: Constraints = null;
  private _statsBatchAudio: Array<any> = null;
  private _statsBatchVideo: Array<any> = null;
  private _statsBatchSize = 15;
  private _videoCanRecover = true;

  public get audioBitrateMax(): number { return this._clientConstraints?.audioBitrateMax; }
  public get media(): LocalMedia { return this._media; }
  public get mediaChannelStats(): DataChannelStats { return this._mediaChannelStats; }
  public get mediaRejected(): EventOwnerAsync<MediaEvent> { return this._mediaRejected; }
  public get mediaReplaced(): EventOwnerAsync<MediaEvent> { return this._mediaReplaced; }
  public get mediaStats(): MediaStats { return this._mediaStats; }
  public get mediaType(): MediaType { return this._mediaType; }
  public get priorityAudio(): LocalTrackPriority { return this._priorityAudio; }
  public get priorityVideo(): LocalTrackPriority { return this._priorityVideo; }
  public get stats(): ConnectionStats { return this._stats; }
  public get videoBitrateMax(): number { return this._clientConstraints?.videoBitrateMax; }
  public get videoPixelCountMax(): number { return this._clientConstraints?.videoPixelCountMax ?? this._maxPixelCount; }

  public get videoFramerateMin(): number { return this._minFrameRate; }
  public get videoHeightMin(): number { return this._minHeight; }
  public get videoWidthMin(): number { return this._minWidth; }
  public get videoFramerateMax(): number { return this._maxFrameRate; }
  public get videoHeightMax(): number { return this._maxHeight; }
  public get videoWidthMax(): number { return this._maxWidth; }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "OriginConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      meetingId: init.meetingId,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: `Origin (${init.mediaType})`,
    });
    this._audioLevelInterval = init.audioLevelInterval;
    this._client = init.client;
    this._mediaOptions = init.mediaOptions;
    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;
    this._mediaType = init.mediaType;
    this._replicationCount = init.replicationCount;
    this._statsBatchAudio = [];
    this._statsBatchVideo = [];

    if (init.room) {
      const room = init.room;
      if (this._mediaType === "display") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateDisplay) this._minFrameRate = room.minVideoFramerateDisplay;
        if (room.minVideoPixelCountDisplay) this._minPixelCount = room.minVideoPixelCountDisplay;
        if (room.maxAudioDisplay) this._maxAudioBitrate = room.maxAudioDisplay;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      } else if (this._mediaType === "user") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateUser) this._minFrameRate = room.minVideoFramerateUser;
        if (room.minVideoPixelCountUser) this._minPixelCount = room.minVideoPixelCountUser;
        if (room.minVideoHeightUser) this._minHeight = room.minVideoHeightUser;
        if (room.minVideoWidthUser) this._minHeight = room.minVideoWidthUser;
        if (room.maxVideoFramerateUser) this._maxFrameRate = room.maxVideoFramerateUser;
        if (room.maxVideoHeightUser) this._maxHeight = room.maxVideoHeightUser;
        if (room.maxVideoWidthUser) this._maxHeight = room.maxVideoWidthUser;
        if (room.maxAudioBitrate) this._maxAudioBitrate = room.maxAudioBitrate;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      }
      if (room.maxAudioJitterDelay) this._maxAudioJitterDelay = room.maxAudioJitterDelay;
      if (room.maxVideoJitterDelay) this._maxVideoJitterDelay = room.maxVideoJitterDelay;
    }

    if (init.tenantSettings && init.tenantSettings.length > 0) {
      const settings = init.tenantSettings;
      settings.forEach(setting => {
        try {
          if (this._mediaType === "display") {
            switch (setting.settingName) {
              case VideoOriginFrameRateDisplayMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountDisplayMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case  VideoOriginJitterDisplayLow: {
                this._jitterLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayMedium: {
                this._jitterMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayHigh: {
                this._jitterHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayLow: {
                this._packetLossLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayMedium: {
                this._packetLossMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayHigh: {
                this._packetLossHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // Audio Impairment Thresholds - (Display)
              case AudioOriginJitterThresholdDisplayLow: {
                this._audioJitterThresholdLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdDisplayMedium: {
                this._audioJitterThresholdMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdDisplayHigh: {
                this._audioJitterThresholdHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayLow: {
                this._audioPacketLossThresholdLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayMedium: {
                this._audioPacketLossThresholdMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayHigh: {
                this._audioPacketLossThresholdHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // END Audio Settings
            }
          } else if (this._mediaType === "user") {
            switch (setting.settingName) {
              case VideoOriginFrameRateUserMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountUserMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginJitterUserLow: {
                this._jitterLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserMedium: {
                this._jitterMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserHigh: {
                this._jitterHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserLow: {
                this._packetLossLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserMedium: {
                this._packetLossMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLosUserHigh: {
                this._packetLossHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // Audio Impairment Thresholds - Settings (User)
              case AudioOriginJitterThresholdUserLow: {
                this._audioJitterThresholdLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdUserMedium: {
                this._audioJitterThresholdMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdUserHigh: {
                this._audioJitterThresholdHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholUserLow: {
                this._audioPacketLossThresholdLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdUserMedium: {
                this._audioPacketLossThresholdMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdUserHigh: {
                this._audioPacketLossThresholdHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // END Audio Settings
            }
          }

          switch (setting.settingName) {
            case "ORIGIN:BITRATE:MIN":
              if (this._minBitrate == 0) {
                this._minBitrate = Number.parseInt(setting.settingValue);
                Log.debug(`Set minBitrate from TenantSetting: Value=${setting.settingValue}`);
              }
            case "ORIGIN:STATISTIC:BATCHSIZE":
              this._statsBatchSize = Number.parseInt(setting.settingValue);
              Log.debug(`Set statsBatchSize from TenantSetting: Value=${setting.settingValue}`);
              break;
            case "ORIGIN:RAMPUP:SECONDS":
              this._rampUpSeconds = Number.parseInt(setting.settingValue);
              Log.debug(`Set rampUpSeconds from TenantSetting: Value=${setting.settingValue}`);
              break;
          }
        } catch (err: any) {
          Log.error(`Error parsing TenantSetting: ${setting.settingName}=${setting.settingValue} Type=${setting.settingType}`, err);
        }
      });
    }

    if (this._minBitrate == 0) this._minBitrate = 300;
    if (this._minFrameRate == 0) this._minFrameRate = this._mediaType == "display" ? 3 : 12;
    if (this._minPixelCount == 0) this._minPixelCount = this._mediaType == "display" ? 1280 * 720 : this._minHeight * this._minWidth;
    if (this._jitterHigh == 0) this._jitterHigh = 120;
    if (this._jitterMedium == 0) this._jitterMedium = 79;
    if (this._jitterMedium == 0) this._jitterLow = 30;
    if (this._packetLossHigh == 0) this._packetLossHigh = .28;
    if (this._packetLossMedium == 0) this._packetLossMedium = .08;
    if (this._packetLossMedium == 0) this._packetLossLow = .03;

    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;

    this._onAudioTrackStreamBound = this.onAudioTrackStreamBound.bind(Reactive.wrap(this));
    this._onAudioTrackStreamUnbound = this.onAudioTrackStreamUnbound.bind(Reactive.wrap(this));
    this._onMediaChannelClose = this.onMediaChannelClose.bind(Reactive.wrap(this));
    this._onMediaChannelClosing = this.onMediaChannelClosing.bind(Reactive.wrap(this));
    this._onMediaChannelError = this.onMediaChannelError.bind(Reactive.wrap(this));
    this._onMediaChannelMessage = this.onMediaChannelMessage.bind(Reactive.wrap(this));
    this._onMediaChannelOpen = this.onMediaChannelOpen.bind(Reactive.wrap(this));
    this._onMediaStateChanged = this.onMediaStateChanged.bind(Reactive.wrap(this));
    this._onVideoTrackFrameSizeChanged = this.onVideoTrackFrameSizeChanged.bind(Reactive.wrap(this));
    this._onVideoTrackStreamBound = this.onVideoTrackStreamBound.bind(Reactive.wrap(this));
    this._onVideoTrackStreamUnbound = this.onVideoTrackStreamUnbound.bind(Reactive.wrap(this));

    this._mediaChannel = this.connection.createDataChannel("media");
    this._mediaChannel.binaryType = "arraybuffer";

    for (const trackType of trackTypes) {
      const transceiver = this.connection.addTransceiver(trackType, { direction: "inactive" });
      this._transceivers.set(trackType, transceiver);
      this._senders.set(trackType, transceiver.sender);
      this._senderStats.set(trackType, new TrackStats());

      if (trackType === "audio") {
         this.setPreferredAudioCodecsOrigin(transceiver);
      }
    }

    this.attachEventHandlers();
  }

  private getSenderEncodings(parameters: RTCRtpSendParameters): RTCRtpEncodingParameters[] {
    const encodings = parameters.encodings ?? [{}];
    parameters.encodings = encodings;
    return encodings;
  }

  private onAudioTrackStreamBound(): Promise<void> {
    const trackStream = this._media.audioTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("audio", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onAudioTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("audio", null);
      await this.updateTransceiverDirections();
    });
  }

  private onMediaChannelClose(): void {
    const message = `Origin ${this.mediaType} connection media channel has closed.`;
    void this.eventLogger.debug("onMediaChannelClose", message);
  }

  private onMediaChannelClosing(): void {
    const message = `Origin ${this.mediaType} connection media channel is closing.`;
    void this.eventLogger.debug("onMediaChannelClosing", message);
  }

  private onMediaChannelError(ev: RTCErrorEvent): void {
    const message = `Origin ${this.mediaType} connection media channel has failed. ${ev.error?.errorDetail ?? ev.error ?? ""}`.trimEnd();
    void this.eventLogger.debug("onMediaChannelError", message);
  }

  private onMediaChannelMessage(ev: MessageEvent<any>): void {
    const arrayBuffer = <ArrayBuffer>ev.data;
    this._mediaChannelBytesReceived += arrayBuffer.byteLength;
    const buffer = new Uint8Array(arrayBuffer);
    if (buffer.length == 0) return;
    const payloadType = buffer[0];
    if (payloadType == 0) {
      if (buffer.length == 1) return;
      const audioLevel = buffer[1] / 255;
      this._media?.audioTrack?.updateLevel(audioLevel);
    }
  }

  private onMediaChannelOpen(): void {
    const message = `Origin ${this.mediaType} connection media channel has opened.`;
    void this.eventLogger.debug("onMediaChannelOpen", message);
  }

  private onMediaStateChanged(): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackFrameSizeChanged(): void {
    void this.eventQueue.dispatch(async () => {
      await this.updateSenderParametersVideo();
    });
  }

  private onVideoTrackStreamBound(): Promise<void> {
    const trackStream = this._media.videoTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("video", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("video", null);
      await this.updateTransceiverDirections();
    });
  }

  private processClientStats(stats: TrackStats, type: string) {
    const clientStats = stats.toJson();
    if (type === "video") {
      clientStats.bitrateServer = this._serverConstraints.videoBitrateMax;
      clientStats.frameRateServer = this._serverConstraints.videoFrameRateMax;
      clientStats.pixelCountServer = this._serverConstraints.videoPixelCountMax;

      clientStats.bitrateEstimated = this._estimatedConstraints.videoBitrateMax;
      clientStats.frameRateEstimated = this._estimatedConstraints.videoFrameRateMax;
      clientStats.pixelCountEstimated = this._estimatedConstraints.videoPixelCountMax;

      clientStats.availableOutgoingBitrate = this._stats.availableOutgoingBitrate;
      clientStats.bitrateConstraint = this._clientConstraints.videoBitrateMax;
      clientStats.frameRateConstraint = this._clientConstraints.videoFrameRateMax;
      clientStats.pixelCountConstraint = this._clientConstraints.videoPixelCountMax;

      this._statsBatchVideo.push(clientStats);

      if (this._statsBatchVideo.length >= this._statsBatchSize) {
        const batch = this._statsBatchVideo.splice(0);
        this.sendNotification({
          type: "clientStats",
          clientStatisticsTrackType: 'video',
          clientStats: batch
        });
      }
    } else {
      this._statsBatchAudio.push(clientStats);
      if (this._statsBatchAudio.length >= this._statsBatchSize) {
        const batch = this._statsBatchAudio.splice(0);
        this.sendNotification({
          type: "clientStats",
          clientStatisticsTrackType: 'audio',
          clientStats: batch
        });
      }
    }   
  }

  private async tryReplaceSenderTrack(trackType: TrackType, track: MediaStreamTrack): Promise<boolean> {
    const sender = this.getSender(trackType);
    try {
      if (this.state == "closed") return false;
      if (sender.track == track) return false;
      await sender.replaceTrack(track);
      if (trackType == "video") this.sendNotification({
        type: "frameRateUpdated",
        frameRate: track?.getSettings()?.frameRate ?? null,
      });
      return true;
    } catch (error: any) {
      void this.eventLogger.debug(<Error>error, "tryReplaceSenderTrack", `Could not replace origin ${this._mediaType} ${trackType} sender track (null:${track == null ? "true" : "false"}.`);
      return false;
    }
  }

  private trySetTransceiverDirection(trackType: TrackType, direction: RTCRtpTransceiverDirection): boolean {
    const transceiver = this.getTransceiver(trackType);
    try {
      if (this.state == "closed") return false;
      if (transceiver.direction == direction || transceiver.direction == "stopped" || (transceiver as any).stopped /* legacy */) return false;
      transceiver.direction = direction;
      return true;
    } catch (error: any) {
      void this.eventLogger.debug(<Error>error, "trySetTransceiverDirection", `Could not set origin ${this._mediaType} ${trackType} transceiver direction to ${direction}.`);
      return false;
    }
  }

  private async updateConstraints(constraints: Constraints): Promise<void> {
    void this.eventLogger.debug("updateConstraints", `Updating origin ${this._mediaType} constraints...`, null, {
      audioBitrateMax: constraints.audioBitrateMax,
      videoBitrateMax: constraints.videoBitrateMax,
      videoFrameRateMax: constraints.videoFrameRateMax,
      videoPixelCountMax: constraints.videoPixelCountMax,
    });

    if (this._estimatedConstraints == null) {
      this._estimatedConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._maxPixelCount
      } as Constraints;
    }
    if (this._clientConstraints == null) {
      this._clientConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._maxPixelCount
      } as Constraints;
    }
    //} else {
    //  if (this._clientConstraints.audioBitrateMax > constraints.audioBitrateMax || this._clientConstraints.audioBitrateMax >= this._serverConstraints.audioBitrateMax) {
    //    this._clientConstraints.audioBitrateMax = constraints.audioBitrateMax;
    //    Log.debug(`Updating Constraint: audioBitrateMax: ServerValue=${constraints.audioBitrateMax}, NewClientValue=${constraints.audioBitrateMax}`);
    //  }
    //  if (this._clientConstraints.videoBitrateMax > constraints.videoBitrateMax || this._clientConstraints.videoBitrateMax >= this._serverConstraints.videoBitrateMax) {
    //    const bitrate = Math.max(this._minBitrate, constraints.videoBitrateMax);
    //    this._clientConstraints.videoBitrateMax = bitrate;
    //    Log.debug(`Updating Constraint: videoBitrateMax: ServerValue=${constraints.videoBitrateMax}, NewClientValue=${bitrate}`);
    //  }
    //  if (this._clientConstraints.videoFrameRateMax > constraints.videoFrameRateMax || this._clientConstraints.videoFrameRateMax >= this._serverConstraints.videoFrameRateMax) {
    //    const frameRate = Math.max(this._minFrameRate, constraints.videoFrameRateMax);
    //    this._clientConstraints.videoFrameRateMax = frameRate;
    //    Log.debug(`Updating Constraint: videoFrameRateMax: ServerValue=${constraints.videoFrameRateMax}, NewClientValue=${frameRate}`);
    //  }
    //  if (this._clientConstraints.videoPixelCountMax > constraints.videoPixelCountMax || this._clientConstraints.videoPixelCountMax >= this._serverConstraints.videoPixelCountMax) {
    //    const pixelCountMax = Math.max(this._minPixelCount, constraints.videoPixelCountMax);
    //    this._clientConstraints.videoPixelCountMax = pixelCountMax;
    //    Log.debug(`Updating Constraint: videoPixelCountMax: ServerValue=${constraints.videoPixelCountMax}, NewClientValue=${pixelCountMax}`);
    //  }
    //}

    if (this._serverConstraints == null) {
      this._serverConstraints = constraints;
    } else {
      Log.debug(`Updating server constraints: ${JSON.stringify(constraints)}`);
      this._serverConstraints.audioBitrateMax = constraints.audioBitrateMax;
      this._serverConstraints.videoBitrateMax = Math.max(this._minBitrate, constraints.videoBitrateMax);
      this._serverConstraints.videoFrameRateMax = Math.max(this._minFrameRate, constraints.videoFrameRateMax);
      this._serverConstraints.videoPixelCountMax = Math.max(this._minPixelCount, constraints.videoPixelCountMax);
    }
    await this.updateSenderParameters();
  }

  private async updateMediaBindings(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  private async updateSenderParametersAudio(): Promise<void> {
    const sender = this._senders.get("audio");
    const parameters = sender.getParameters();
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.audioBitrateMax) {
        const maxBitrate = this._clientConstraints.audioBitrateMax * 1000;
        if (encodings[0].maxBitrate != maxBitrate) {
          void this.eventLogger.debug("updateSenderParametersAudio", `Setting origin ${this._mediaType} audio max bitrate...`, null, {
            maxBitrate: maxBitrate,
          });
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      encodings[0].priority = this._priorityAudio;
      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersAudio", `Could not set origin ${this._mediaType} audio sender parameters.`);
      }
      Log.debug(`Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, Priority=${encodings[0].priority}, ResolutionScale=${encodings[0].scaleResolutionDownBy}`);
    }
  }

  private async updateSenderParametersVideo(): Promise<void> {
    const sender = this._senders.get("video");
    const parameters = sender.getParameters();

    if (this._mediaOptions.webRtcDegradationPreferenceEnabled) {
      parameters.degradationPreference = this._mediaOptions.degradationPreference;
    }
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.videoBitrateMax) {
        const maxBitrate = Math.round(this._clientConstraints.videoBitrateMax * 1000);
        if (encodings[0].maxBitrate != maxBitrate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max bitrate...`, null, {
            maxBitrate: maxBitrate,
          });
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      if (this._clientConstraints?.videoFrameRateMax) {
        const maxFrameRate = this._clientConstraints.videoFrameRateMax;
        if (encodings[0].maxFramerate != maxFrameRate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max frame rate...`, null, {
            maxFramerate: maxFrameRate,
          });
          encodings[0].maxFramerate = maxFrameRate;
        }
      }
      if (this._clientConstraints?.videoPixelCountMax) {
        const settings = sender.track?.getSettings();
        if (settings && settings.width && settings.height) {
          const scaleResolutionDownBy = Math.max(1, Math.sqrt(settings.width * settings.height / (this._clientConstraints.videoPixelCountMax)));
          if (encodings[0].scaleResolutionDownBy != scaleResolutionDownBy) {
            void this.eventLogger.debug("updateSenderParametersVideo", `Scaling down origin ${this._mediaType} video resolution...`, null, {
              scaleResolutionDownBy: scaleResolutionDownBy,
              videoWidth: settings.width,
              videoHeight: settings.height,
            });
            encodings[0].scaleResolutionDownBy = scaleResolutionDownBy;
          }
        }
      }

      encodings[0].active = this._encodingEnabled;
      encodings[0].priority = this._priorityVideo;
      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersVideo", `Could not set origin ${this._mediaType} video sender parameters.`);
      }
      Log.debug(`Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, FrameRate=${encodings[0].maxFramerate}, ResolutionScale=${encodings[0].scaleResolutionDownBy}`);
    }
  }

  private async updateSenderParameters(): Promise<void> {
    await this.updateSenderParametersAudio();
    await this.updateSenderParametersVideo();
  }

  private setPreferredAudioCodecsOrigin(audioTransceiver: RTCRtpTransceiver): void {
    if (!this._supportsSetCodecPreferences || !this._redAudioEnabled) {
      return;
    }
    const {codecs} = RTCRtpSender.getCapabilities('audio');
    const redCodecIndex = codecs.findIndex(c => c.mimeType === 'audio/red');
    if (redCodecIndex == -1) {
      Log.info('audio/red codec not supported');
      return;
    }
    const redCodec = codecs[redCodecIndex];
    this._preferredCodec = redCodec;
    codecs.splice(redCodecIndex, 1);
    codecs.unshift(redCodec);
    const transceiver = audioTransceiver || this.connection.getTransceivers().find(x => x.sender && x.sender.track.kind === "audio");
    transceiver.setCodecPreferences(codecs);
    Log.info(`audio/red codec preference set: ${JSON.stringify(codecs)}`);
  }

  private async updateTransceiverDirections(): Promise<void> {
    let updateOffer = false;
    if (this._media?.audioTrack?.stream) {
      if (this.trySetTransceiverDirection("audio", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("audio", "inactive")) updateOffer = true;
    }
    if (this._media?.videoTrack?.stream) {
      if (this.trySetTransceiverDirection("video", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("video", "inactive")) updateOffer = true;
    }
    if (!updateOffer || this.state == "new" || this.state == "closed") return;
    let offer = (await this.connection.createOffer()).sdp;
    offer = await this.mungeOffer(offer);
    await this.connection.setLocalDescription({
      sdp: offer,
      type: "offer"
    });
    //TODO: use sendRequest once the server honors requests
    this.sendNotification({
      type: "offerUpdated",
      offer: offer,
    });
    this._answerUpdated = new PromiseCompletionSource();
    try {
      let answer = (await this._answerUpdated.promise).answer;
      answer = await this.mungeAnswer(answer);
      await this.connection.setRemoteDescription({
        sdp: answer,
        type: "answer",
      });
      await this.updateSenderParameters();
    } catch { /* display media rejected */ }
  }

  /** @internal */
  public getSender(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._senders.get(trackType);
  }

  /** @internal */
  public getSenderStats(trackType: TrackType): TrackStats {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._senderStats.get(trackType);
  }

  public setMaxAudio(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._senders.get(trackType);
  }

  /** @internal */
  public getTransceiver(trackType: TrackType): RTCRtpTransceiver {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType);
  }

  /** @internal */
  public async pauseInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", null);
    await this.tryReplaceSenderTrack("video", null);
  }

  /** @internal */
  public async resumeInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  protected attachEventHandlers(): void {
    super.attachEventHandlers();
    this._mediaChannel.addEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.addEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.addEventListener("error", this._onMediaChannelError);
    this._mediaChannel.addEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.addEventListener("open", this._onMediaChannelOpen);
  }

  protected detachEventHandlers(): void {
    this._media?.stateChanged.unbind(this._onMediaStateChanged);
    this._media?.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
    this._media?.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
    this._media?.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
    this._media?.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
    this._mediaChannel.removeEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.removeEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.removeEventListener("error", this._onMediaChannelError);
    this._mediaChannel.removeEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.removeEventListener("open", this._onMediaChannelOpen);
    super.detachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.negotiate({
      audioLevelInterval: this._audioLevelInterval,
      mediaType: this._mediaType,
      offer: offer,
      replicationCount: this._replicationCount,
    }, abortSignal)).answer;
  }

  protected async onOpened(): Promise<void> {
    await super.onOpened();
    await this.updateMediaBindings();
    await this.updateTransceiverDirections();
    await this.updateSenderParameters();
  }

  protected onTerminated(): void {
    this._answerUpdated?.reject("Connection closed.");
    super.onTerminated();
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "answerUpdated") {
      if (this._answerUpdated) this._answerUpdated.resolve(notification);
    } else if (notification.type == "constraintsUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this.updateConstraints(notification.constraints);
      });
    } else if (notification.type == "displayMediaRejected") {
      if (this._answerUpdated) this._answerUpdated.reject(new Error("Media rejected."));
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaRejected.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else if (notification.type == "displayMediaReplaced") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaReplaced.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else {
      void this.eventLogger.warning("processNotification", `Unexpected "${notification.type}" edge notification.`);
    }
  }

  protected processConnectionStats(stats: Map<string, any>): void {
    this._stats.updateFromConnection(stats);
  }

  protected processSenderStats(stats: Map<TrackType, Map<string, any>>): void {
    for (const trackType of trackTypes) {
      this.getSenderStats(trackType).updateFromSender(stats.get(trackType));
    }

    this._media?.audioTrack?.updateStats(this.getSenderStats("audio"), this._stats);
    this._media?.videoTrack?.updateStats(this.getSenderStats("video"), this._stats);

    this._mediaChannelStats.update(this._mediaChannelBytesSent, this._mediaChannelBytesReceived, performance.now());

    this._mediaStats.update([this.getSenderStats("audio"), this.getSenderStats("video")], performance.now());

    if (this.getSenderStats("video").count > this._rampUpSeconds) {
      this._rampedUp = true;
    }

    const audioTrackStats: TrackStats = this.getSenderStats("audio");
    const trackStats: TrackStats = this.getSenderStats("video");
    let reason: number = qualityLimitationReasonNone;
    if (trackStats.qualityLimitationReason) {
      reason = qualityLimitationReasons.get(trackStats.qualityLimitationReason);
      if (!reason) reason = qualityLimitationReasonNone;
    }
    this._qualityLimitationReason = reason;

    if (audioTrackStats.bytesSent > 0) {
      this.eventQueue.dispatch(async () => {
          await this.calculateAudioConstraints(audioTrackStats);
          this.processClientStats(audioTrackStats, 'audio');
      });
    }
    if (trackStats.bytesSent > 0) {
      this.eventQueue.dispatch(async () => {
        await this.calculateConstraints(trackStats);
        trackStats.qualityLimitationReasonCombined = this._qualityLimitationReason;
        this.processClientStats(trackStats, 'video');
        this._qualityLimitationReason = 0;
      })
    }
  }

  private getAvailableBitrateFromStats(stats: TrackStats): number {
    let availableBitrate = 0;
    // Both targetBitrate/availableOutgoingBitrate increase as network improves. Use whichever value is available from WebRTC
    if (stats.targetBitrate > 0) {
      availableBitrate = stats.targetBitrate;
    } else if (this._stats.availableOutgoingBitrate > 0) {
      availableBitrate = this._stats.availableOutgoingBitrate;
    } 
    else {
      availableBitrate = null;
    }
    return availableBitrate;
  }

  /*
  * Called by ProcessSenderStats, used to deteremine and update audio bitrate in the event of either a newly healthy network or a impaired network. 
  * Will adjust the quality of your audio both up and down depending on network conditions. 
  */
  private async calculateAudioConstraints(stats: TrackStats): Promise<void> {
    const audioIssuesFound: AudioHealthIssues[] = [];
    // AudioBitrateMax comes from the constraint, which may be null.
    // Max Audio Bitrate is the tenant setting.
    const currentBitrate = this.audioBitrateMax || this._maxAudioBitrate;
    
    if (stats.jitter > this._maxAudioJitterDelay || stats.jitterBufferDelay > this._maxAudioJitterDelay) {
      Log.debug("CalculateAudioConstraints - Jitter or JitterBufferDelay value is over the room's maxAudioJitterDelay property.");
    }

    // Determine if there was a jitter issue.
    // A low value is favourable over a high value.  
    if (stats.jitter >= this._audioJitterThresholdHigh) {
      audioIssuesFound.push(AudioHealthIssues.JITTERHIGH);
    } else if (stats.jitter >= this._audioJitterThresholdMedium) {
      audioIssuesFound.push(AudioHealthIssues.JITTERMEDIUM);
    } else if (stats.jitter >= this._audioJitterThresholdLow) {
      audioIssuesFound.push(AudioHealthIssues.JITTERLOW);
    }

    // Determine if there was a packet loss issue.
    // A low value is favourable over a high value. 
    if (stats.packetLoss >= this._audioPacketLossThresholdHigh) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSHIGH);
    } else if (stats.packetLoss >= this._audioPacketLossThresholdMedium) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSMEDIUM);
    } else if (stats.packetLoss >= this._audioPacketLossThresholdLow) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSLOW);
    }

    let availableBitrate = this.getAvailableBitrateFromStats(stats);
    
    // If the available bitrate is not avaialble. 
    if (availableBitrate == null) {
      availableBitrate = currentBitrate;
    }
    // Determine if there was a round trip time issue.
    let newBitrate = currentBitrate;
    // Determine if there is action required. 
    if (currentBitrate > availableBitrate) {
      newBitrate = availableBitrate;
    }
    else if (audioIssuesFound.length > 0) {
      // Attempt degradation.
      newBitrate = Math.round(currentBitrate / 2);
    } 
    else if (currentBitrate < availableBitrate)
    {
      // If there are no issues above and we have more bitrate avaialble to us, lets increase the bitrate to the estimated avaiable amount. 
      newBitrate = availableBitrate;
    } else {
      // If there are no issues and the currentBitrate is equal to the avaialbleBitrate, try increasing the bitrate up by the step amount. 
      newBitrate = currentBitrate * 2;
    }
    // Don't allow a value below the configured tenant setting. 
    if (newBitrate < this._minAudioBitrate) {
      // TODO: Reduce Video Quality.
      newBitrate = this._minAudioBitrate;
      // Don't allow a value higher then the configured tenant setting. 
    } else if (newBitrate > this._maxAudioBitrate) {
      newBitrate = this._maxAudioBitrate;
    }
    
    if (this.media?.audioTrack?.isMuted || stats.bitrate >= (this._maxAudioBitrate * 0.85)) {
      this._videoCanRecover = true;
    } else {
      this._videoCanRecover = false;
    }

    if (newBitrate != currentBitrate && newBitrate < currentBitrate) {
      Log.debug(`CalculateAudioConstraints - Network issue detected (audio) reducing bitrate to ${newBitrate}`);
      if (audioIssuesFound.length > 0) {
        Log.debug(`CalculateAudioConstraints - The following network issues were observed in your audio statistics: ${audioIssuesFound.join(", ")}`);
      }
    } else if (newBitrate > currentBitrate) {
      Log.debug(`CalculateAudioConstraints - No network issues detected (audio) increasing bitrate to ${newBitrate}`);
    }

    // If there is nothing to do, bail early.
    if (newBitrate === null || newBitrate == currentBitrate) {
      return;
    }

    // Perform a bitrate adjustment.
    this._clientConstraints.audioBitrateMax = newBitrate;
    await this.updateSenderParametersAudio();
  }

  private async calculateConstraints(stats: TrackStats): Promise<void> {
    if (!this._rampedUp) {
      Log.debug('Waiting for ramp up to complete');
      return;
    }

    let estimatedPixelCount = this._clientConstraints.videoPixelCountMax ?? this._maxPixelCount;
    let estimatedFrameRate = this._clientConstraints.videoFrameRateMax ?? this._maxFrameRate;
    let estimatedBitrate = this._clientConstraints.videoBitrateMax ?? this._maxBitrate;
    let adjustFrameRate = true;
    let adjustResolution = true;
    let lowJitter = false;

    if (stats.jitter > this._maxVideoJitterDelay || stats.jitterBufferDelay > this._maxVideoJitterDelay) {
      Log.debug("CalculateVideoConstraints - Jitter or JitterBufferDelay over room's maxAudioJitterDelay property.");
    }

    if (stats.jitter >= this._jitterHigh) {
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = this._minPixelCount;
        Log.debug(`High Jitter: Jitter=${stats.jitter}, PixelCount=${estimatedPixelCount}`);
      }

      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = this._minFrameRate;
        Log.debug(`High Jitter: Jitter=${stats.jitter}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (stats.jitter >= this._jitterMedium) {
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = estimatedPixelCount / 2;
        Log.debug(`Medium Jitter: Jitter=${stats.jitter}, PixelCount=${estimatedPixelCount}`);
      }

      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = estimatedFrameRate / 2;
        Log.debug(`Medium Jitter: Jitter=${stats.jitter}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (stats.jitter >= this._jitterLow) {
      lowJitter = true;
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = estimatedPixelCount * (2 / 3);
        adjustFrameRate = false;
        Log.debug(`Low Jitter: Jitter=${stats.jitter}, PixelCount=${estimatedPixelCount}`);
      } else if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = estimatedFrameRate * (2 / 3);
        Log.debug(`Low Jitter: Jitter=${stats.jitter}, FrameRate=${estimatedFrameRate}`);
      } else {
        lowJitter = false;
      }
    } 

    if (stats.packetLoss >= this._packetLossHigh) {
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._minPixelCount);
        adjustResolution = true;
        Log.debug(`High Packet Loss: PacketLoss=${stats.packetLoss}, PixelCount=${estimatedPixelCount}`);
      }
      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._minFrameRate);
        adjustFrameRate = true;
        Log.debug(`High Packet Loss: PacketLoss=${stats.packetLoss}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (stats.packetLoss >= this._packetLossMedium) {
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._clientConstraints.videoPixelCountMax / 2);
        adjustResolution = true;
        Log.debug(`Medium Packet Loss: PacketLoss=${stats.packetLoss}, PixelCount=${estimatedPixelCount}`);
      }
      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._clientConstraints.videoFrameRateMax / 2);
        adjustFrameRate = true;
        Log.debug(`Medium Packet Loss: PacketLoss=${stats.packetLoss}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (stats.packetLoss > this._packetLossLow) {
      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._clientConstraints.videoPixelCountMax * (2 / 3));
        adjustFrameRate = false;
        Log.debug(`Low Packet Loss: PacketLoss=${stats.packetLoss}, PixelCount=${estimatedPixelCount}`);
      } else if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._clientConstraints.videoFrameRateMax * (2 / 3));
        Log.debug(`Medium Packet Loss: PacketLoss=${stats.packetLoss}, FrameRate=${estimatedFrameRate}`);
      } else {
        if (!lowJitter) {
          adjustFrameRate = false;
          adjustResolution = false;
        }
      }
    } else {
      if (lowJitter) {
        adjustFrameRate = false;
        adjustResolution = false;
      }
    }

    // Ensure we don't go below minimums after scaling down
    // estimatedFrameRate = Math.max(estimatedFrameRate, this._minFrameRate);
    // estimatedPixelCount = Math.max(estimatedPixelCount, this._minPixelCount);

    const localTargetBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate);
    let videoShouldRecover = true;
    if (stats.targetBitrate > 0) {
      //canRecover = (canRecover || stats.didTargetBitrateIncrease()) && localTargetBitrate < stats.targetBitrate;
      videoShouldRecover = localTargetBitrate <= stats.targetBitrate;
      Log.debug(`CanRecover localTargetBitrate=${localTargetBitrate}, adjustResolution=${adjustResolution}, adjustFrameRate=${adjustFrameRate}`);
    } else if (this._stats.availableOutgoingBitrate > 0) {
      //canRecover = (canRecover || this._stats.didAvailableOutgoingBitrateIncrease()) && localTargetBitrate < this._stats.availableOutgoingBitrate;
      videoShouldRecover = localTargetBitrate < this._stats.availableOutgoingBitrate;
      Log.debug(`CanRecover localTargetBitrate=${localTargetBitrate}, adjustResolution=${adjustResolution}, adjustFrameRate=${adjustFrameRate}`);
    }

    if (this._videoCanRecover && videoShouldRecover) {
      if (this._lastStepType == "pixelCount") {
        this._lastStepType = "frameRate";

        if (this._clientConstraints.videoFrameRateMax < this._maxFrameRate) {
          //estimatedFrameRate = Math.min(estimatedFrameRate + (estimatedFrameRate * (1 / 3)), this._maxFrameRate);
          estimatedFrameRate = this._maxFrameRate;
          const targetBitrate = stats.targetBitrate ?? this._stats.availableOutgoingBitrate;
          if (targetBitrate) {
            estimatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate);
            //while (estimatedBitrate < stats.targetBitrate) {
            //  let nextFrameRate = Math.min(estimatedFrameRate + (estimatedFrameRate * (1 / 3)), this._maxFrameRate);
            //  estimatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, nextFrameRate);
            //  if (estimatedBitrate > stats.targetBitrate || nextFrameRate >= this._maxFrameRate || estimatedFrameRate >= this._maxFrameRate) break;
            //  estimatedFrameRate = nextFrameRate;
            //}
          }
          Log.debug(`FrameRate Increase: Current=${this._clientConstraints.videoFrameRateMax}, New=${estimatedFrameRate}`);
          adjustResolution = false;
        }
      } else if (this._lastStepType == "frameRate") {
        this._lastStepType = "pixelCount";

        if (this._clientConstraints.videoPixelCountMax < this._maxPixelCount) {
          const targetBitrate = stats.targetBitrate ?? this._stats.availableOutgoingBitrate;
          if (targetBitrate) {
            estimatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate);
            while (estimatedBitrate < stats.targetBitrate) {
              estimatedPixelCount = Math.min(estimatedPixelCount + (estimatedPixelCount * (1 / 3)), this._maxPixelCount);
              estimatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate);
              if (estimatedBitrate > stats.targetBitrate || estimatedPixelCount >= this._maxPixelCount) break;
            }
          }
          Log.debug(`PixelCount Increase: Current=${this._clientConstraints.videoPixelCountMax}, New=${estimatedPixelCount}`);
          adjustFrameRate = false;
        }
      }

      Log.debug(`Increasing Constraint: LastStepType=${this._lastStepType}, AdjustFrameRate=${adjustFrameRate}, AdjustResolution=${adjustResolution}`);
    }


    // estimatedFrameRate = Math.min(estimatedFrameRate, Math.max(this._minFrameRate, this._serverConstraints.videoFrameRateMax));
    // estimatedPixelCount = Math.min(Math.ceil(estimatedPixelCount), Math.max(this._minPixelCount, this._serverConstraints.videoPixelCountMax));
    // estimatedBitrate = Math.min(VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate), Math.max(this._minBitrate, this._serverConstraints.videoBitrateMax));

    estimatedBitrate = Math.round(estimatedBitrate);
    estimatedPixelCount = Math.round(estimatedPixelCount);
    this._estimatedConstraints.videoBitrateMax = estimatedBitrate;
    this._estimatedConstraints.videoFrameRateMax = estimatedFrameRate;
    this._estimatedConstraints.videoPixelCountMax = estimatedPixelCount;


    Log.debug(`Constraint Estimates: Bitrate=${estimatedBitrate}, FrameRate=${estimatedFrameRate}. PixelCount=${estimatedPixelCount}`);
    let updateConstraints = false;

    //NOTE: not certain we should be doing, but makes sense it would apply to both
    if (this._mediaType === "user") {
      if ((estimatedPixelCount >= this._minPixelCount) && (estimatedPixelCount <= this._maxPixelCount)) {
        updateConstraints = true;
        const previous = this._clientConstraints.videoPixelCountMax;
        Log.debug(`Client Constraint Update: Type=PixelCount, Previous=${previous}, New=${estimatedPixelCount}`);
        this._clientConstraints.videoPixelCountMax = estimatedPixelCount;
        this._qualityLimitationReason += qualityLimitationReasonResolution;
        if (estimatedPixelCount < previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonDecrease;
        else if (estimatedPixelCount > previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonIncrease;
      }

      if ((estimatedFrameRate >= this._minFrameRate) && (estimatedFrameRate <= this._maxFrameRate)) {
        updateConstraints = true;
        const previous = this._clientConstraints.videoFrameRateMax;
        Log.debug(`Client Constraint Update: Type=FrameRate, Previous=${this._clientConstraints.videoFrameRateMax}, New=${estimatedFrameRate}`);
        this._clientConstraints.videoFrameRateMax = estimatedFrameRate;
        this._qualityLimitationReason += qualityLimitationReasonFrameRate;
        if (estimatedFrameRate < previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonDecrease;
        else if (estimatedFrameRate > previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonIncrease;
      }

      if ((estimatedBitrate >= this._minBitrate) && (estimatedBitrate <= this._maxBitrate)) {
        updateConstraints = true;
      }
    } else {
      Log.debug(`MediaType=${this._mediaType}. Not adjusting WebRTC constraints`);
    }

    let availableBitrate = 0;
    let bitrateIncreasing = false;
    let bitrateDecreasing = false;

    // Both targetBitrate/availableOutgoingBitrate increase as network improves. Use whichever value is available from WebRTC
    if (stats.targetBitrate > 0) {
      availableBitrate = stats.targetBitrate;
      bitrateDecreasing = stats.didTargetBitrateDecrease();
      bitrateIncreasing = stats.didTargetBitrateIncrease();
    } else if (this._stats.availableOutgoingBitrate > 0) {
      availableBitrate = this._stats.availableOutgoingBitrate;
      bitrateDecreasing = this._stats.didAvailableOutgoingBitrateDecrease();
      bitrateIncreasing = this._stats.didAvailableOutgoingBitrateIncrease();
    } else {
      availableBitrate = null;
    }

    // Attempt to pause video only if the bitrate indicators are available from the stats.
    if (availableBitrate) {
      //TODO: configure the recovery time
      if ((availableBitrate < this._minBitrate) && this._encodingEnabled && this._recoveryTime > 3) {
        this._recoveryTime = 0;
        this._bitrateIncreases = 0;
        this._bitrateSteady = 0;
        this._encodingEnabled = false;
        updateConstraints = true;
        Log.debug(`Available bitrate below min: Available=${availableBitrate}. Disabling video encoding`);
      } else {
        if (this._encodingEnabled) this._recoveryTime++;

        // The targetBitrate/availableOutgoingBitrate will not continue to increase past the minBitrate
        // so we look to see if it has increased at least twice or remained steady for 2 intervals (10 seconds each) or a combination of both
        if (!this._encodingEnabled) {
          this._encodingEnabled = true;
          updateConstraints = true;
          Log.debug(`Available bitrate above min: Available=${availableBitrate}. Enabling video encoding`);
        }
      }
    }

    if (updateConstraints) {
      Log.debug(`Constraint Estimates: Bitrate=${estimatedBitrate}, FrameRate=${estimatedFrameRate}, PixelCount=${estimatedPixelCount}, availableBitrate=${availableBitrate}`);
      await this.updateSenderParametersVideo();
    }
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.renegotiate({
      mediaType: this._mediaType,
      offer: offer,
    }, abortSignal)).answer;
  }

  public setMedia(media: LocalMedia): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      if (this._media == media) return;
      if (media?.connection && media.connection != this) await media.connection.setMedia(null);
      if (this._media) {
        this._media.stateChanged.unbind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.unbind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.unbind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.unbind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.unbind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.unbind(this._onVideoTrackStreamUnbound);
        this._media.connection = null;
      }
      this._media = media;
      if (this._media) {
        this._media.stateChanged.bind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.bind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
        this._media.connection = this;
      }
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  public setPriorityAudio(priorityAudio: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityAudio, "priorityAudio");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityAudio == priorityAudio) return;
      this._priorityAudio = priorityAudio;
      await this.updateSenderParametersAudio();
    });
  }

  public setPriorityVideo(priorityVideo: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityVideo, "priorityVideo");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityVideo == priorityVideo) return;
      this._priorityVideo = priorityVideo;
      await this.updateSenderParametersVideo();
    });
  }
}