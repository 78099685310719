import Bowser from "bowser";
import Utility from "./Utility";

export default class DeviceUtility {
  private static readonly _identifierStorageKey = "deviceIdentifier";
  private static _platformInitialized = false;
  private static _platform: string;
  private static _platformVersion: string;

  public static get platform(): string {
    this.tryInitializePlatform();
    return this._platform;
  }
  /** @internal */
  public static get platformInitialized(): boolean { return this._platformInitialized; }
  /** @internal */
  public static set platformInitialized(value: boolean) { this._platformInitialized = value; }
  public static get platformVersion(): string {
    this.tryInitializePlatform();
    return this._platformVersion;
  }

  private static tryInitializePlatform() {
    if (this._platformInitialized) return false;
    const navigator = globalThis.navigator;
    const process = (globalThis as any).process;
    if (navigator && navigator.userAgent) {
      const parser = Bowser.getParser(navigator.userAgent);
      this._platform = parser.getBrowserName();
      this._platformVersion = parser.getBrowserVersion() ?? "Unknown";
    } else if (process) {
      this._platform = process.platform;
      this._platformVersion = process.version;
    } else {
      this._platform = "Unknown";
      this._platformVersion = "Unknown";
    }
    this._platformInitialized = true;
  }

  public static getIdentifier(): string {
    let deviceIdentifier = localStorage.getItem(this._identifierStorageKey);
    if (!deviceIdentifier) {
      deviceIdentifier = Utility.generateGuid();
      localStorage.setItem(this._identifierStorageKey, deviceIdentifier);
    }
    return deviceIdentifier;
  }

  public static hasCachedIdentifier(): boolean {
    return !!localStorage.getItem(this._identifierStorageKey);
  }

  public static removeCachedIdentifier(): void {
    localStorage.removeItem(this._identifierStorageKey);
  }
}
