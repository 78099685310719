import MediaStatsModel from "./models/MediaStats";
import TrackStats from "./TrackStats";
import Utility from "./core/Utility";

export default class MediaStats implements MediaStatsModel {
  private _lastBitrateReceived: number = null;
  private _lastBitrateSent: number = null;
  private _lastBytesReceived = 0;
  private _lastBytesSent = 0;
  private _lastTimestamp: DOMHighResTimeStamp = null;
  private _ready: boolean = false;

  private _bitrateReceived: number = null;
  private _bitrateSent: number = null;
  private _bytesReceived: number = null;
  private _bytesSent: number = null;
  private _keyFrameRequestsReceived: number = null;
  private _keyFrameRequestsSent: number = null;
  private _keyFramesReceived: number = null;
  private _keyFramesSent: number = null;
  private _nacksReceived: number = null;
  private _nacksSent: number = null;
  private _packetsLost: number = null;
  private _packetsReceived: number = null;
  private _packetsSent: number = null;

  public get bitrateReceived(): number { return this._bitrateReceived; }
  public get bitrateSent(): number { return this._bitrateSent; }
  public get bytesReceived(): number { return this._bytesReceived; }
  public get bytesSent(): number { return this._bytesSent; }
  public get keyFrameRequestsReceived(): number { return this._keyFrameRequestsReceived; }
  public get keyFrameRequestsSent(): number { return this._keyFrameRequestsSent; }
  public get keyFramesReceived(): number { return this._keyFramesReceived; }
  public get keyFramesSent(): number { return this._keyFramesSent; }
  public get nacksReceived(): number { return this._nacksReceived; }
  public get nacksSent(): number { return this._nacksSent; }
  public get packetsLost(): number { return this._packetsLost; }
  public get packetsReceived(): number { return this._packetsReceived; }
  public get packetsSent(): number { return this._packetsSent; }
  public get ready(): boolean { return this._ready; }

  public aggregate(mediaStats: MediaStats[]): void {
    this.reset();
    this._ready = true;
    this._bitrateReceived = mediaStats.map(s => s.bitrateReceived).filter(x => !Utility.isNullOrUndefined(x)).reduce((a, b) => a + b, 0);
    this._bitrateSent = mediaStats.map(s => s.bitrateSent).filter(x => !Utility.isNullOrUndefined(x)).reduce((a, b) => a + b, 0);
    this._bytesReceived = mediaStats.map(s => s.bytesReceived ?? 0).reduce((a, b) => a + b, 0);
    this._bytesSent = mediaStats.map(s => s.bytesSent ?? 0).reduce((a, b) => a + b, 0);
    this._keyFrameRequestsReceived = mediaStats.map(s => s.keyFrameRequestsReceived ?? 0).reduce((a, b) => a + b, 0);
    this._keyFrameRequestsSent = mediaStats.map(s => s.keyFrameRequestsSent ?? 0).reduce((a, b) => a + b, 0);
    this._keyFramesReceived = mediaStats.map(s => s.keyFramesReceived ?? 0).reduce((a, b) => a + b, 0);
    this._keyFramesSent = mediaStats.map(s => s.keyFramesSent ?? 0).reduce((a, b) => a + b, 0);
    this._nacksReceived = mediaStats.map(s => s.nacksReceived ?? 0).reduce((a, b) => a + b, 0);
    this._nacksSent = mediaStats.map(s => s.nacksSent ?? 0).reduce((a, b) => a + b, 0);
    this._packetsLost = mediaStats.map(s => s.packetsLost ?? 0).reduce((a, b) => a + b, 0);
    this._packetsReceived = mediaStats.map(s => s.packetsReceived ?? 0).reduce((a, b) => a + b, 0);
    this._packetsSent = mediaStats.map(s => s.packetsSent ?? 0).reduce((a, b) => a + b, 0);
  }

  public reset(): void {
    this._bitrateReceived = null;
    this._bitrateSent = null;
    this._bytesReceived = null;
    this._bytesSent = null;
  }

  public update(trackStats: TrackStats[], timestamp: DOMHighResTimeStamp): void {
    this.reset();
    this._ready = true;
    this._bytesReceived = trackStats.map(s => s.bytesReceived ?? 0).reduce((a, b) => a + b, 0);
    this._bytesSent = trackStats.map(s => s.bytesSent ?? 0).reduce((a, b) => a + b, 0);
    this._keyFrameRequestsReceived = trackStats.map(s => s.keyFrameRequestsReceived ?? 0).reduce((a, b) => a + b, 0);
    this._keyFrameRequestsSent = trackStats.map(s => s.keyFrameRequestsSent ?? 0).reduce((a, b) => a + b, 0);
    this._keyFramesReceived = trackStats.map(s => s.keyFramesReceived ?? 0).reduce((a, b) => a + b, 0);
    this._keyFramesSent = trackStats.map(s => s.keyFramesSent ?? 0).reduce((a, b) => a + b, 0);
    this._nacksReceived = trackStats.map(s => s.nacksReceived ?? 0).reduce((a, b) => a + b, 0);
    this._nacksSent = trackStats.map(s => s.nacksSent ?? 0).reduce((a, b) => a + b, 0);
    this._packetsLost = trackStats.map(s => s.packetsLost ?? 0).reduce((a, b) => a + b, 0);
    this._packetsReceived = trackStats.map(s => s.packetsReceived ?? 0).reduce((a, b) => a + b, 0);
    this._packetsSent = trackStats.map(s => s.packetsSent ?? 0).reduce((a, b) => a + b, 0);
    
    let bitrateReceived: number = this._lastBitrateReceived;
    let bitrateSent: number = this._lastBitrateSent;
    if (this._lastTimestamp) {
      const deltaBytesReceived = this._bytesReceived - this._lastBytesReceived;
      const deltaBytesSent = this._bytesSent - this._lastBytesSent;
      const deltaSeconds = (timestamp - this._lastTimestamp) / 1000;
      if (deltaSeconds == 0) return;
      bitrateReceived = Math.ceil(deltaBytesReceived * 8 / deltaSeconds / 1000);
      bitrateSent = Math.ceil(deltaBytesSent * 8 / deltaSeconds / 1000);
    }
    this._bitrateReceived = bitrateReceived;
    this._bitrateSent = bitrateSent;
    this._lastBitrateReceived = bitrateReceived;
    this._lastBitrateSent = bitrateSent;
    this._lastBytesReceived = this._bytesReceived;
    this._lastBytesSent = this._bytesSent;
    this._lastTimestamp = timestamp;
  }
}