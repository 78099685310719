import QR from "./QR";
import QRFrameData from "./QRFrameData";

export default class QRReader {
  private readonly _canvasElements: HTMLCanvasElement[] = [];
  private readonly _qr = new QR();
  private readonly _videoCollection: HTMLCollectionOf<Element>;

  public constructor(videoCollection: HTMLCollectionOf<Element>) {
    this._videoCollection = videoCollection;
  }

  public async read(): Promise<{ [key: string]: QRFrameData | null }> {
    const result: { [key: string]: QRFrameData | null } = { };
    for (let i = 0; i < this._videoCollection.length; i++) {
      const videoElement = <HTMLVideoElement>this._videoCollection[i];
      if (!(videoElement instanceof HTMLVideoElement)) throw new Error("Element is not a video.", videoElement);
      if (i >= this._canvasElements.length) {
        this._canvasElements[i] = document.createElement("canvas");
      }
      const canvasElement = this._canvasElements[i];
      const id = videoElement.id;
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      if (!width || !height) {
        result[id] = null;
        continue;
      }
      canvasElement.width = width;
      canvasElement.height = height;
      const context = canvasElement.getContext("2d", { alpha: false, willReadFrequently: true });
      if (!context) {
        result[id] = null;
        continue;
      }
      context.drawImage(videoElement, 0, 0);
      const dimension = Math.min(width, height) / 2;
      const text = await this._qr.read(context.getImageData(0, 0, dimension, dimension));
      if (!text) {
        result[id] = null;
        continue;
      }
      try {
        result[id] = <QRFrameData>JSON.parse(text);
      } catch (error) {
        console.error("Could not parse QR code JSON.", text);
        result[id] = null;
      }
    }
    return result;
  }
}