import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Video = _resolveComponent("Video")!

  return (_openBlock(), _createElementBlock("div", {
    class: "gallery",
    style: _normalizeStyle(_ctx.galleryStyleVars)
  }, [
    _createElementVNode("button", {
      class: "toggle-overlay",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showOverlay = !_ctx.showOverlay))
    }, _toDisplayString(_ctx.showOverlay ? "Hide" : "Show") + " all overlays", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.medias, (media, index) => {
      return (_openBlock(), _createBlock(_component_Video, {
        key: media.id,
        media: media,
        overlay: true,
        "all-overlays": _ctx.showOverlay,
        first: index == 0
      }, null, 8, ["media", "all-overlays", "first"]))
    }), 128))
  ], 4))
}