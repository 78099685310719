export enum StorageKeyLength {
  Max = 100
}

export enum StorageUrlLength {
  Max = 512
}

export enum StorageRetentionUnitLength {
  Max = 20
}
