
import { defineComponent, PropType } from "vue";
import { Media } from "@liveswitch/sdk";
import Video from "./Video.vue"

export default defineComponent({
  components: {
    Video,
  },
  data() {
    return {            
      showOverlay: false,
    }
  },
  computed: {
    galleryStyleVars(): any {
      const mediaCount = this.medias!.length;
      const columns = mediaCount ? Math.ceil(Math.sqrt(mediaCount)) : 0;
      const rows = mediaCount ? Math.ceil(mediaCount / columns) : 0;
      return {
        "--columns": columns,
        "--rows": rows
      };
    }
  },
  props: {
    medias: {
      type: Object as PropType<Media[]>,
    }
  },
})
