
import { ApiClient, models, SipNumberType } from "@liveswitch/sdk";
import { defineComponent, PropType } from "vue";
import Spinner from "./Spinner.vue";

export default defineComponent({
  name: "SipModal",
  components: {
    Spinner,
  },
  props: {    
    apiClient: {
      type: ApiClient,
      default: null
    },
    roomId: {
      type: String,
      default: null,
    },
    roomInfo: {
      type: Object as PropType<models.RoomInfo>,
      default: null,
    },
    userAccountId: {
      type: String,
      default: null,
    }    
  },
  data(): { 
    countries: models.Country[] | null;
    sipNumbers: models.SipInboundNumber[] | null;
    roomSipNumbers: models.RoomSipInboundNumber[] | null;
    checkedNumberIds: string[],
    filterType: SipNumberType[],
    filterProvider: string[],
    filterCountry: string[],
    numbersKey: number,
  } {
    return {      
      countries: null,
      sipNumbers: null,
      roomSipNumbers: null,
      checkedNumberIds: [],
      filterType: [],
      filterProvider: [],
      filterCountry: [],
      numbersKey: 0,
    };
  },
  computed: {
    filteredNumbers() {
      let filtered = this.sipNumbers!.filter(x=>x.numberStatus == "ACTIVE")
      if (this.filterType.length > 0) {
        filtered = filtered.filter(x=> this.filterType.includes(x.numberType));
      }
      if (this.filterCountry.length > 0) {
        filtered = filtered.filter(x=> this.filterCountry.includes(x.countryId));
      }
      return filtered;
    },
    availableTypes() {      
      return [...new Set(this.sipNumbers!.map(x=>x.numberType))];
    },
    availableCountries() {      
      return [...new Set(this.sipNumbers!.map(x=>x.countryId))];
    },
  },
  async mounted() {
    if (this.apiClient == null) {
      this.$emit("closeSipModal");
      return;
    }
    this.countries = (await this.apiClient.listCountries({})).values;
    //if there's a possiblity of more than 100 sip numbers we need to add paging
    this.sipNumbers = (await this.apiClient.listSipInboundNumbers(<models.SipInboundNumbersListRequest>{
      clusterId: this.roomInfo.clusterId,
      limit: 100,
    })).values;

    this.roomSipNumbers = (await this.apiClient.listRoomSipInboundNumbers(<models.RoomSipInboundNumberListRequest> {
      roomId: this.roomId as string,
    })).values;

    const activeIds = this.roomSipNumbers.filter(s=>s.status == "ACTIVE").map(s=> s.numberId);
    activeIds.forEach(n=> {
      if (this.checkedNumberIds.findIndex(c=>c == n) == -1) {
        this.checkedNumberIds.push(n!);
        this.numbersKey++;
      }
    });
  },
  methods: {  
    displayCountryName(countryId: string) {
      if (this.countries == null || this.countries.length == 0) return countryId;
      
      return this.countries.find(c=>c.id == countryId)?.countryName;
    },
    getPhoneNumberFromId(numberId: string) {
      const sipNumber = this.sipNumbers?.find(x=>x.id == numberId);
      if (!sipNumber) return "";
      return this.formatPhoneNumber(sipNumber.phoneNumber);
    },    
    getPasscodeFromId(numberId: string) {
      const roomNumber = this.roomSipNumbers?.find(x=>x.numberId == numberId);
      if (!roomNumber) return "";
      return roomNumber.passcode;
    },
    formatPhoneNumber(phoneNumberString: string | undefined) : string | null {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },
    isActive(sipNumber: models.SipInboundNumber) {
      return this.roomInfo?.numbers?.findIndex(n=>n.phoneNumber == sipNumber.phoneNumber) != -1;
    },
    async toggleNumber(e: any) {
      const id = e.currentTarget.value;
      const index = this.checkedNumberIds.findIndex(n=>n == id);
      if (index == -1) {
        //it was removed
        const roomSipNumber = this.roomSipNumbers?.find(rsn=>rsn.numberId == id);
        await this.apiClient.deleteRoomSipInboundNumber(roomSipNumber?.id!);
      } else {
        //it was added
        const number = this.sipNumbers?.find(s=>s.id == id);
        if (!number) return;

        try {
          await this.apiClient.createRoomSipInboundNumber(<models.RoomSipInboundNumberCreateRequest> {
            roomId: this.roomId,
            numberProvider: number.numberProvider,
            type: number.numberType,
            status: number.numberStatus,
            countryId: number.countryId
          });
        }
        catch (error: any) {
          console.error(error.message);
        }
      }

      if (this.checkedNumberIds.length == 0) {
        await this.apiClient.disableSipByRoomId(this.roomId);
      }
        
      this.roomSipNumbers = (await this.apiClient.listRoomSipInboundNumbers(<models.RoomSipInboundNumberListRequest> {
        roomId: this.roomId as string,
      })).values;
      this.numbersKey++;
    },
    async disableSip() {
      await this.apiClient.disableSipByRoomId(this.roomId);
      this.checkedNumberIds = [];
      this.numbersKey++;
    }
  }
});
