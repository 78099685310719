

import { defineComponent } from "vue";
import { getServerVersion, getSdkVersion } from "../classes/VersionInfo";

export default defineComponent({
  data(): {
    sdkVersion: string;
    serverVersion: string | null;
  } {
    return {
      sdkVersion: getSdkVersion(),
      serverVersion: "???",
    }
  },
  async created() {
    await this.updateServerVersion();
  },
  methods: {
    async updateServerVersion() {
      this.serverVersion = "???";
      if (!process.env.VUE_APP_IDENTITY_SERVICE_URL) return;
      this.serverVersion = await getServerVersion(process.env.VUE_APP_IDENTITY_SERVICE_URL + "/status");
    }
  }
});
