
import { defineComponent, PropType } from "vue";
import { DisplayMedia, LocalMedia, MediaDeviceManager, UserMedia } from "@liveswitch/sdk";

export default defineComponent({
  data() {
    return {
      deviceManager: MediaDeviceManager.shared,
      localDisplayMedia: this.media instanceof DisplayMedia ? <DisplayMedia>this.media : null,
      localUserMedia: this.media instanceof UserMedia ? <UserMedia>this.media : null,
      showOverlay: false,
      videoFrameRates: [7.5, 15, 30],
      videoFrameSizes: [
        { width: 640, height: 480, label: "480p" },
        { width: 1280, height: 720, label: "720p" },
        { width: 1920, height: 1080, label: "1080p" }
      ],
    }
  },
  props: {
    media: {
      type: Object as PropType<LocalMedia>,
    },
    overlay: Boolean
  },
})
