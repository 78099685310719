import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e734bbbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "strip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Video = _resolveComponent("Video")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.medias, (media) => {
      return (_openBlock(), _createBlock(_component_Video, {
        key: media.id,
        media: media,
        overlay: false
      }, null, 8, ["media"]))
    }), 128))
  ]))
}