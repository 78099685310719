import { VideoTrack } from "@liveswitch/sdk";
import FrameRateMonitor from "./FrameRateMonitor";

export default class QRProcessor implements UnderlyingSink<VideoFrame> {
  private readonly _frameRateMonitor = new FrameRateMonitor();
  private readonly _processor: MediaStreamTrackProcessor<VideoFrame>;
  private readonly _track: VideoTrack;

  private _frameHeight?: number;
  private _frameWidth?: number;

  public get bitrate(): number | undefined { return this._track.bitrate; }
  public get frameRate(): number | undefined { return this._frameRateMonitor.frameRate; }
  public get frameHeight(): number | undefined { return this._frameHeight; }
  public get frameWidth(): number | undefined { return this._frameWidth; }

  public constructor(track: VideoTrack) {
    this._track = track;
    this._processor = new MediaStreamTrackProcessor({ track: <MediaStreamVideoTrack>track.stream });
    void this._processor.readable.pipeTo(new WritableStream<VideoFrame>(this, new CountQueuingStrategy({ highWaterMark: 1 })));
  }

  public async write(frame: VideoFrame): Promise<void> {
    const timestamp = new Date().getTime();
    this._frameRateMonitor.addTimestamp(timestamp);
    this._frameWidth = frame.displayWidth;
    this._frameHeight = frame.displayHeight;
    frame.close();
  }
}