import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import Guard from "../core/Guard";
import Member from "./Member";
import MemberCollectionInit from "./models/MemberCollectionInit";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import SubscribedView from "../SubscribedView";

export default class MemberCollection extends ReadOnlyCollection<Member> {
  private _channel: Channel;
  private _controlConnection: ControlConnection;
  private _subscribedView: SubscribedView;

  /** @internal */
  constructor() {
    super(member => member.id);
  }

  /** @internal */
  public async load(init: MemberCollectionInit): Promise<void> {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._subscribedView = init.subscribedView;

    const collection = (await this._controlConnection.listChatChannelMembers({
      channelId: this._channel.id,
    })).chatChannelMembers;

    const memberModels = collection.values;
    const attendees = await Promise.all(memberModels.map(mm => this._subscribedView.subscribeToAttendee(mm.attendeeId, "chatMemberPaging")));
    const members = attendees.filter(a => !!a).map((a, i) => new Member({
      attendee: a,
      channel: this._channel,
      controlConnection: this._controlConnection,
      model: memberModels[i],
    }));
    for (const member of members) this.tryAdd(member);
  }
}