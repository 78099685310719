import Constants from "./Constants";
import EventOwner from "./EventOwner";
import Guard from "./Guard";
import ReadOnlyCollectionEvent from "./models/ReadOnlyCollectionEvent";

export default abstract class ReadOnlyCollection<T> extends Array<T> {
  readonly [n: number]: T;

  private readonly _added = new EventOwner<ReadOnlyCollectionEvent<T>>();
  private readonly _removed = new EventOwner<ReadOnlyCollectionEvent<T>>();
  private readonly _elementMap: Map<string, T> = new Map<string, T>();
  private readonly _keySelector: (element: T) => string;

  public get added(): EventOwner<ReadOnlyCollectionEvent<T>> { return this._added; }
  public get count(): number { return this.length; }
  public get removed(): EventOwner<ReadOnlyCollectionEvent<T>> { return this._removed; }

  /** @internal */
  constructor(keySelector: (element: T) => string) {
    super();
    this._keySelector = keySelector;
  }

  /** @internal */
  public removeAll(): T[] {
    const elements = super.splice(0, this.length);
    this._elementMap.clear();
    elements.forEach(element => {
      this._removed.dispatch({
        element: element,
      });
    });
    return elements;
  }

  /** @internal */
  public tryAdd(element: T): boolean {
    Guard.isNotNullOrUndefined(element, "element");

    const key = this._keySelector(element);
    if (this._elementMap.has(key)) return false;

    this._elementMap.set(key, element);
    super.push(element);

    this._added.dispatch({
      element: element,
    });
    return true;
  }

  /** @internal */
  public tryInsert(element: T, index: number): boolean {
    Guard.isNotNullOrUndefined(element, "element");
    Guard.isNotNullOrUndefined(index, "index");
    Guard.isLessThanOrEqualTo(index, this.length, "index");

    const key = this._keySelector(element);
    if (this._elementMap.has(key)) return false;

    this._elementMap.set(key, element);
    super.splice(index, 0, element);

    this._added.dispatch({
      element: element,
    });
    return true;
  }

  /** @internal */
  public tryRemove(key: string): boolean {
    Guard.isNotNullOrUndefined(key, "key");

    const element = this._elementMap.get(key);
    if (element == null) return false;

    const index = this.indexOf(element);
    super.splice(index, 1);
    this._elementMap.delete(key);

    this._removed.dispatch({
      element: element,
    });
    return true;
  }

  /** @internal */
  public tryReplace(key: string, element: T): boolean {
    Guard.isNotNullOrUndefined(key, "key");

    const oldElement = this._elementMap.get(key);
    if (oldElement == null) return false;

    const newKey = this._keySelector(element);
    if (this._elementMap.has(newKey)) return false;

     // splice is required to support reactive frameworks
    const index = this.indexOf(oldElement);
    super.splice(index, 1, element);
    this._elementMap.delete(key);
    this._elementMap.set(newKey, element);

    this._removed.dispatch({
      element: oldElement,
    });
    this._added.dispatch({
      element: element,
    });
    return true;
  }

  /**
   * Checks if an element with a given key exists in the collection.
   * @param key The element key.
   * @returns true if the element exists; otherwise, false.
   */
  public containsKey(key: string) {
    return this._elementMap.has(key);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param target This parameter is not used.
   * @param start This parameter is not used.
   * @param end This parameter is not used.
   */
  public copyWithin(target: number, start: number, end?: number): this {
    throw new Error(Constants.Errors.ReadOnlyCollection.copyWithin);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param value This parameter is not used.
   * @param start This parameter is not used.
   * @param end This parameter is not used.
   */
  public fill(value: T, start?: number, end?: number): this {
    throw new Error(Constants.Errors.ReadOnlyCollection.fill);
  }

  /**
   * Gets an element from the collection.
   * @param key The element key.
   * @returns The element if the key exists; otherwise null.
   */
  public get(key: string): T | null {
    Guard.isNotNullOrUndefined(key, "key");

    if (this.containsKey(key)) {
      return this._elementMap.get(key);
    }

    return null;
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   */
  public pop(): T {
    throw new Error(Constants.Errors.ReadOnlyCollection.pop);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param items This parameter is not used.
   */
  public push(...items: T[]): number {
    throw new Error(Constants.Errors.ReadOnlyCollection.push);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   */
  public reverse(): T[] {
    throw new Error(Constants.Errors.ReadOnlyCollection.reverse);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   */
  public shift(): T {
    throw new Error(Constants.Errors.ReadOnlyCollection.shift);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param compareFn This parameter is not used.
   */
  public sort(compareFn?: (a: T, b: T) => number): this {
    throw new Error(Constants.Errors.ReadOnlyCollection.sort);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param start This parameter is not used.
   * @param deleteCount This parameter is not used.
   * @param rest This parameter is not used.
   */
  public splice(start: any, deleteCount?: any, ...rest: any[]): T[] {
    throw new Error(Constants.Errors.ReadOnlyCollection.splice);
  }

  /**
   * This method is not allowed and will throw an error if invoked.
   * @param items This parameter is not used.
   */
  public unshift(...items: T[]): number {
    throw new Error(Constants.Errors.ReadOnlyCollection.unshift);
  }
}
