import Attendee from "./Attendee";
import AttendeeCollectionInit from "./models/AttendeeCollectionInit";
import AttendeeCollectionRefreshOptions from "./models/AttendeeCollectionRefreshOptions";
import AttendeeListState from "./models/AttendeeListState";
import AttendeeListStateChangeEvent from "./models/AttendeeListStateChangeEvent";
import AttendeeListStateMachine from "./AttendeeListStateMachine";
import AttendeeSortType from "./models/AttendeeSortType";
import AttendeeStatus from "./models/AttendeeStatus";
import ControlConnection from "./control/Connection";
import EventOwnerAsync from "./core/EventOwnerAsync";
import Guard from "./core/Guard";
import ReadOnlyCollection from "./core/ReadOnlyCollection";
import SubscribedView from "./SubscribedView";
import Utility from "./core/Utility";

export default class AttendeeCollection extends ReadOnlyCollection<Attendee> {
  private readonly _sortIndexes: { [key: string]: number } = {
    "displayName": 0,
    "status": 1,
    "type": 2,
    "update": 3,
    "dateEntered": 4,
    "dateLeft": 5,
  };
  private readonly _stateChanged: EventOwnerAsync<AttendeeListStateChangeEvent> = new EventOwnerAsync<AttendeeListStateChangeEvent>();
  private readonly _stateEvents = new Map<AttendeeListState, EventOwnerAsync<AttendeeListStateChangeEvent>>();
  private readonly _stateMachine = new AttendeeListStateMachine();

  private _controlConnection: ControlConnection = null;
  private _localAttendee: Attendee = null;
  private _pageNumber = 1;
  private _pageSize = 10;
  private _sortType: AttendeeSortType = "dateEntered";
  private _statusFilter: AttendeeStatus = "ACTIVE";
  private _subscribedView: SubscribedView = null;
  private _totalCount = 0;
  
  /** @internal */
  public set totalCount(value: number) {
    this._totalCount = value;
  }

  public get isBusy(): boolean { return this.state == "busy"; }
  public get isError(): boolean { return this.state == "error"; }
  public get isImpaired(): boolean { return this.state == "impaired"; }
  public get isInitialized(): boolean { return this.state == "initialized"; }
  public get isNew(): boolean { return this.state == "new"; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public get pageCount(): number { return Math.max(1, Math.ceil(this._totalCount / Math.max(1, this._pageSize))); }
  public get pageNumber(): number { return this._pageNumber; }
  public get pageSize(): number { return this._pageSize; }
  public get sortType(): AttendeeSortType { return this._sortType; }
  public get state(): AttendeeListState { return this._stateMachine.state; }
  /** @event */
  public get stateChanged(): EventOwnerAsync<AttendeeListStateChangeEvent> { return this._stateChanged; }
  public get statusFilter(): AttendeeStatus { return this._statusFilter; }
  public get totalCount(): number { return this._totalCount; }
  
  /** @internal */
  constructor() {
    super(attendee => attendee.id);
    this._stateEvents.set("busy", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("error", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("impaired", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("initialized", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("new", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("started", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<AttendeeListStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<AttendeeListStateChangeEvent>());
  }

  /** @internal */
  public async init(init: AttendeeCollectionInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._controlConnection = init.controlConnection;
    this._localAttendee = init.localAttendee;
    this._subscribedView = init.subscribedView;
    this._pageSize = init.attendeePageSize ?? this._pageSize;
    this.setState("initialized");
  }

  public async start() : Promise<void> {
    this.setState("starting");
    await this.refresh({
      pageSize: this._pageSize,
      sortType: this._sortType
    });
    this.setState("started");
  }

  /** @internal */
  public async tryRemoveAndUpdate(key: string): Promise<boolean> {
    if (!this.tryRemove(key)) return false;
    if (this._pageNumber > this.pageCount) await this.previousPage();
    return true;
  }

  public async nextPage(): Promise<void> {
    if (this._pageNumber >= this.pageCount) return;
    await this.refresh({
      pageNumber: this._pageNumber + 1
    });
  }

  public async previousPage(): Promise<void> {
    if (this._pageNumber <= 1) return;
    await this.refresh({
      pageNumber: this._pageNumber - 1
    });
  }

  public async refresh(options?: AttendeeCollectionRefreshOptions) {
    if (this.state == "started") this.setState("busy");
    options ??= {};
    if (!Utility.isNullOrUndefined(options.pageNumber)) this._pageNumber = options.pageNumber;
    if (!Utility.isNullOrUndefined(options.pageSize)) this._pageSize = options.pageSize;
    if (!Utility.isNullOrUndefined(options.sortType)) this._sortType = options.sortType;
    if (!Utility.isNullOrUndefined(options.statusFilter)) this._statusFilter = options.statusFilter;

    Guard.isGreaterThanOrEqualTo(this._pageNumber, 1, "options.pageNumber");

    if (this._pageSize == 0) {
      this.removeAll();
      return;
    }

    const message = await this._controlConnection.listAttendees({
      limit: this._pageSize,
      offset: (this._pageNumber - 1) * this._pageSize,
      attendeeStatus: this._statusFilter,
      sortIndex: this._sortIndexes[this._sortType],
      isPaging: true,
    });

    const attendeeModels = message.attendees.values;
    this._totalCount = message.attendees.totalCount ?? 0;
    
    this.removeAll();
    for (const attendeeModel of attendeeModels) {
      if (attendeeModel.id == this._localAttendee.id) {
        this.tryAdd(this._localAttendee);
        continue;
      }
      if (!this._subscribedView.containsKey(attendeeModel.id)) this._subscribedView.tryAddAttendee(attendeeModel);
      const attendee = this._subscribedView.get(attendeeModel.id);
      attendee.model = attendeeModel;
      this.tryAdd(attendee);
    }
    if (this.state == "busy") this.setState("started");
  }

  public stop() {
    this.setState("stopping");
    this.removeAll();
    this.setState("stopped");
  }

  private async setState(state: AttendeeListState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <AttendeeListStateChangeEvent>{
      attendees: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }
}