import DisplayAudioTrack from "./DisplayAudioTrack";
import DisplayVideoTrack from "./DisplayVideoTrack";
import LocalMedia from "./LocalMedia";

export default class DisplayMedia extends LocalMedia {
  public get audioTrack(): DisplayAudioTrack { return <DisplayAudioTrack>super.audioTrack; }
  public get videoTrack(): DisplayVideoTrack { return <DisplayVideoTrack>super.videoTrack; }

  public constructor(audio: boolean, video: boolean) {
    super(audio ? new DisplayAudioTrack() : null, "display", video ? new DisplayVideoTrack() : null);
  }

  protected getStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    return globalThis.navigator.mediaDevices.getDisplayMedia(constraints);
  }
}