import Client from "./Client";
import Guard from "../core/Guard";
import IdentityInitAnonymous from "./models/IdentityInitAnonymous";
import IdentityInitCredentials from "./models/IdentityInitCredentials";
import IdentityInitExternalToken from "./models/IdentityInitExternalToken";
import IdentityModel from "./models/Identity";
import TokenRequest from "./models/TokenRequest";
import TokenResponse from "./models/TokenResponse";
import Type from "./models/Type";
import Utility from "../core/Utility";

export default class Identity implements IdentityModel {
  private readonly _client: Client;
  private readonly _init: IdentityInitAnonymous | IdentityInitCredentials | IdentityInitExternalToken;
  private readonly _tokenRequest: TokenRequest;

  private _tokenResponse: TokenResponse;
  private _tokenRefreshAfterTime: number;

  public get apiKey(): string { return this._client.apiKey; }
  public get identityServiceUrl(): string | string[] { return this._client.identityServiceUrl; }
  public get maxRetries(): number { return this._client.maxRetries; }
  public set maxRetries(value: number) { this._client.maxRetries = value; }
  public get requestTimeout(): number { return this._client.requestTimeout; }
  public set requestTimeout(value: number) { this._client.requestTimeout = value; }
  public get type(): Type { return this._init.type; }

  public constructor(init: IdentityInitAnonymous | IdentityInitCredentials | IdentityInitExternalToken) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiKey, "init.apiKey");
    Guard.isNotNullOrUndefined(init.identityServiceUrl, "init.identityServiceUrl");
    if (Utility.isArray(init.identityServiceUrl)) Guard.isGreaterThan(init.identityServiceUrl.length, 0, "init.identityServiceUrl");
    this._init = init;
    this._client = new Client(this._init);
    this._tokenRequest = this.getTokenRequest();
  }

  private getTokenRequest(): TokenRequest {
    switch (this._init.type) {
      default:
      case "anonymous":
        this._init.type = "anonymous";
        return {
          displayName: this._init.displayName ?? `Anonymous #${Math.floor(Math.random() * 999)}`, //TODO: remove once database can handle null values
        };
      case "credentials":
        return {
          password: this._init.password,
          username: this._init.username,
        };
      case "externalToken":
        return {
          externalToken: this._init.externalToken,
        };
    }
  }

  public async token(abortSignal?: AbortSignal): Promise<TokenResponse> {
    if (this._tokenResponse && this._tokenRefreshAfterTime && new Date().getTime() < this._tokenRefreshAfterTime) return this._tokenResponse;
    this._tokenResponse = await this._client.getToken(this._tokenRequest, abortSignal);
    this._tokenRefreshAfterTime = new Date().getTime() + (this._tokenResponse.tokenTtl * 1000) - 60000; // within 1 minute of expiring
    return this._tokenResponse;
  }
}
