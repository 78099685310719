import Device from "./models/Device";
import ReadOnlyCollection from "../core/ReadOnlyCollection";

export default class DeviceCollection extends ReadOnlyCollection<Device> {
  constructor() {
    super(device => device.id);
  }

  public next(deviceId?: string): Device {
    if (this.length == 0) return null;
    const currentDeviceId = deviceId ?? this[0].id;
    const currentDeviceIndex = this.findIndex(device => device.id == currentDeviceId);
    if (currentDeviceIndex == -1) return null;
    const nextDeviceIndex = currentDeviceIndex == this.length - 1 ? 0 : currentDeviceIndex + 1; 
    return this[nextDeviceIndex];
  }

  public previous(deviceId?: string): Device {
    if (this.length == 0) return null;
    const currentDeviceId = deviceId ?? this[0].id;
    const currentDeviceIndex = this.findIndex(device => device.id == currentDeviceId);
    if (currentDeviceIndex == -1) return null;
    const previousDeviceIndex = currentDeviceIndex == 0 ? this.length - 1 : currentDeviceIndex - 1; 
    return this[previousDeviceIndex];
  }
}