import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "controls" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "videos" }
const _hoisted_6 = ["srcObject"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.state == 'stopped' || _ctx.state == 'starting')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            disabled: _ctx.state == 'starting',
            id: "start",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)))
          }, " Start ", 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.state == 'started' || _ctx.state == 'stopping')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            disabled: _ctx.state == 'stopping',
            id: "stop",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)))
          }, " Stop ", 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.userMedia.qrVisible)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.userMedia.setQRVisible(false)))
          }, " Hide QR "))
        : (_openBlock(), _createElementBlock("button", {
            key: 3,
            type: "button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.userMedia.setQRVisible(true)))
          }, " Show QR "))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("video", {
        srcObject: _ctx.userMedia.stream,
        autoplay: "",
        playsinline: "",
        muted: ""
      }, null, 8, _hoisted_6)
    ])
  ]))
}