import ApiClient from "../api/Client";
import Channel from "./Channel";
import ControlConnection from "../control/Connection";
import Guard from "../core/Guard";
import Message from "./Message";
import MessageCollectionInit from "./models/MessageCollectionInit";
import ReadOnlyCollection from "../core/ReadOnlyCollection";
import SubscribedView from "../SubscribedView";

export default class MessageCollection extends ReadOnlyCollection<Message> {
  private _apiClient: ApiClient;
  private _channel: Channel;
  private _controlConnection: ControlConnection;
  private _hasMore = true;
  private _loading = false;
  private _offsetId: string;
  private _subscribedView: SubscribedView;

  public get hasMore(): boolean { return this._hasMore; }

  /** @internal */
  constructor() {
    super(message => message.id);
  }

  private async getOlderMessages(count: number): Promise<boolean> {
    if (this._loading) return false;
    this._loading = true;
    try {
      const collection = (await this._controlConnection.listChatChannelMessages({
        channelId: this._channel.id,
        limit: count,
        offset: 0,
        offsetId: this._offsetId,
        sortDirection: "DESC",
      })).chatMessages;
      
      const messageModels = collection.values;
      this._hasMore = collection.totalCount > messageModels.length;

      if (messageModels.length == 0) return false;
      this._offsetId = messageModels[messageModels.length - 1].id;

      const attendees = await Promise.all(messageModels.map(m => this._subscribedView.subscribeToAttendee(m.createdBy, "chatMessageReceived")));
      const messages = messageModels.map((mm, i) => new Message({
        apiClient: this._apiClient,
        attendee: attendees[i],
        channel: this._channel,
        controlConnection: this._controlConnection,
        model: mm,
      }));
      await Promise.all(messages.map(m => m.load()));
      for (const message of messages) this.tryInsert(message, 0);
      return true;
    } finally {
      this._loading = false;
    }
  }

  /** @internal */
  public async load(init: MessageCollectionInit, count: number = 100): Promise<boolean> {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.channel, "init.channel");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._channel = init.channel;
    this._controlConnection = init.controlConnection;
    this._subscribedView = init.subscribedView;
    return await this.getOlderMessages(count);
  }

  public async loadMore(count: number = 100): Promise<boolean> {
    return await this.getOlderMessages(count);
  }
}
