import AccountType from "../../models/AccountType";
import ApiResponse from "../../api/models/ApiResponse";
import ClientInit from "./models/ClientInit";
import DeviceStatus from "./models/DeviceStatus";
import DeviceType from "./models/DeviceType";
import DeviceUtility from "../../core/DeviceUtility";
import Guard from "../../core/Guard";
import HttpClient from "../../core/HttpClient";
import TokenRequest from "./models/TokenRequest";
import TokenResponse from "./models/TokenResponse";
import Utility from "../../core/Utility";
import ServerError from "../../core/ServerError";

export default class Client {
  private readonly _httpClients: HttpClient[];
  private readonly _init: ClientInit;

  public get apiKey(): string {
    return this._init.apiKey;
  }
  public get maxRetries(): number {
    return this._httpClients[0].maxRetries;
  }
  public set maxRetries(value: number) {
    this._httpClients.forEach((hc) => (hc.maxRetries = value));
  }
  public get requestTimeout(): number {
    return this._httpClients[0].requestTimeout;
  }
  public set requestTimeout(value: number) {
    this._httpClients.forEach((hc) => (hc.requestTimeout = value));
  }
  public get identityServiceUrl(): string | string[] {
    return this._init.identityServiceUrl;
  }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiKey, "init.apiKey");
    Guard.isNotNullOrUndefined(
      init.identityServiceUrl,
      "init.identityServiceUrl"
    );
    if (Utility.isArray(init.identityServiceUrl))
      Guard.isGreaterThan(
        init.identityServiceUrl.length,
        0,
        "init.identityServiceUrl"
      );
    this._init = init;
    const identityServiceUrls = Utility.isArray(init.identityServiceUrl)
      ? <string[]>init.identityServiceUrl
      : [<string>init.identityServiceUrl];
    this._httpClients = identityServiceUrls.map((isu) =>
      HttpClient.withApiKey(this._init.apiKey, new URL(isu).origin)
    );
  }

  private async retry<T>(
    action: (httpClient: HttpClient) => Promise<T>
  ): Promise<T> {
    for (let i = 0; i < this._httpClients.length; i++) {
      try {
        return await action(this._httpClients[0]);
      } catch (error: any) {
        if (i == this._httpClients.length - 1 || error instanceof ServerError)
          throw error;
        this._httpClients.push(this._httpClients.shift()); // rotate
      }
    }
  }

  public async getToken(
    request: TokenRequest,
    abortSignal?: AbortSignal
  ): Promise<TokenResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    const deviceStatus: DeviceStatus = DeviceUtility.hasCachedIdentifier()
      ? "KNOWN"
      : "NEW";
    const deviceType: DeviceType = "WEB";
    return await this.retry(async (httpClient) => {
      return (<TokenResponse>await httpClient.post(
        "v1/token",
        {
          device: {
            deviceIdentifier: DeviceUtility.getIdentifier(),
            devicePlatform: DeviceUtility.platform,
            devicePlatformVersion: DeviceUtility.platformVersion,
            deviceStatus: deviceStatus,
            deviceType: deviceType,
          },
          externalToken: request.externalToken,
          username: request.username,
          password: request.password,
        },
        abortSignal
      ));
    });
  }
}
