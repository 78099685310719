
import { ApiClient, models } from "@liveswitch/sdk";
import { defineComponent, PropType } from "vue";
import ErrorDisplay from "./ErrorDisplay.vue"
import SipModal from "./SipModal.vue";
import Spinner from "./Spinner.vue";


type FilterModeDisplay = "REJECT" | "REPLACE" | "undefined";
type FilterModeUser = "FIRST" | "TOP" | "undefined";
type RoomLobbyType = "MANUAL" | "NONE" | "undefined";
type OptionalBoolean = "no" | "undefined" | "yes";

export default defineComponent({ 
  components: {
    ErrorDisplay,
    SipModal,
    Spinner,
  },
  props: {       
    apiClient: {
      type: ApiClient,
      default: null
    },
    roomInfo: {
      type: Object as PropType<models.RoomInfo>,
      default: null,
    },
    initialRoomKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data(): {
    creatingRoom: boolean;
    enableSip: OptionalBoolean;
    enableStorage: OptionalBoolean;
    filterModeDisplay: FilterModeDisplay;
    filterModeUser: FilterModeUser;
    lobbyType: RoomLobbyType;
    maxAudioDisplay?: number;
    maxAudioJitterDelay?: number,
    maxAudioUser?: number;
    maxVideoDisplay?: number;
    maxVideoJitterDelay?: number,
    maxVideoUser?: number;    
    roomId?: string;
    roomError?: Error;
    roomKey: string;
    roomPasscode?: string;
    showSipModal: boolean;
  } {
    return {
      creatingRoom: false,
      enableSip: "undefined",
      enableStorage: "undefined",
      filterModeDisplay: "REPLACE",
      filterModeUser: "FIRST",
      lobbyType: "NONE",
      maxAudioDisplay: undefined,
      maxAudioJitterDelay: undefined,
      maxAudioUser: undefined,
      maxVideoDisplay: undefined,
      maxVideoJitterDelay: undefined,
      maxVideoUser: undefined,
      roomId: undefined,
      roomError: undefined,
      roomKey: "",
      roomPasscode: undefined,
      showSipModal: false,
    };
  },
  async mounted() {
    try {
        this.roomKey = this.initialRoomKey;        
        //if we can get the roomId that means we created the room
        const rooms = (await this.apiClient.listRooms(<models.RoomListRequest>{key: this.roomKey})).values;
        if (rooms.length == 1) {
          this.roomId = rooms[0].id;
        }
      } catch(ex) {
        //nothing
      }
  },
  methods: {
    updateRoomInfoDeferred(): void {
      const me = this;
      this.$nextTick(()=> {
        me.$emit("updateRoomInfo", me.roomKey);
      });
    },
    async createRoom(e: any) {
      e.preventDefault();
      this.creatingRoom = true;
      try {
        if (!this.roomInfo) {
          const room = (await this.apiClient.createRoom(<models.RoomCreateRequest>{
            key: this.roomKey,
            passcode: this.roomPasscode,
            filterModeDisplay: this.filterModeDisplay == "undefined" ? undefined : this.filterModeDisplay,
            filterModeUser: this.filterModeUser == "undefined" ? undefined : this.filterModeUser,
            maxAudioDisplay: this.maxAudioDisplay,
            maxAudioJitterDelay: this.maxAudioJitterDelay,
            maxAudioUser: this.maxAudioUser,
            maxVideoDisplay: this.maxVideoDisplay,
            maxVideoJitterDelay: this.maxVideoJitterDelay,
            maxVideoUser: this.maxVideoUser,
            roomLobbyType: this.lobbyType == "undefined" ? undefined : this.lobbyType,
          })).value;
          switch (this.enableSip) {
            case "no":
              console.info("Disabling SIP...");
              await this.apiClient.disableSipByRoomId(room.id);
              break;
            case "yes":
              console.info("Enabling SIP...");
              await this.apiClient.enableSipByRoomId(room.id);
              break;
          }
          switch (this.enableStorage) {
            case "no":
              console.info("Disabling storage...");
              await this.apiClient.disableStorageByRoomId(room.id);
              break;
            case "yes":
              console.info("Enabling storage...");
              await this.apiClient.enableStorageByRoomId(room.id);
              break;
          }
          this.$emit("updateRoomInfo", this.roomKey);
        } 
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        console.error(error);
        this.roomError = error;
      } finally {
        this.creatingRoom = false;
      }
    }
  }
})
