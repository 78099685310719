import Size from "./Size";

export default class VideoUtility {
  private static readonly _defaultBaselineHeight: number = 480;
  private static readonly _defaultBaselineWidth: number = 640;
  private static readonly _defaultBitrateExponent: number = 0.75;
  private static readonly _defaultBitsPerPixel: number = 0.05;

  public static calculateBitrate(frameSize: Size, frameRate: number, bitsPerPixel?: number, baselineWidth?: number, baselineHeight?: number, bitrateExponent?: number) {
    const baselinePixelCount = (baselineWidth ?? this._defaultBaselineWidth) * (baselineHeight ?? this._defaultBaselineHeight);
    const baselineBitrate = baselinePixelCount * frameRate * (bitsPerPixel ?? this._defaultBitsPerPixel) / 1000;
    const pixelScale = (frameSize.width * frameSize.height) / baselinePixelCount;
    return Math.ceil(Math.pow(pixelScale, bitrateExponent ?? this._defaultBitrateExponent) * baselineBitrate);
  }

  public static calculateBitrateUsingPixelCount(pixelCount: number, frameRate: number, bitsPerPixel?: number, baselineWidth?: number, baselineHeight?: number, bitrateExponent?: number) {
    const baselinePixelCount = (baselineWidth ?? this._defaultBaselineWidth) * (baselineHeight ?? this._defaultBaselineHeight);
    const baselineBitrate = baselinePixelCount * frameRate * (bitsPerPixel ?? this._defaultBitsPerPixel) / 1000;
    const pixelScale = (pixelCount) / baselinePixelCount;
    return Math.ceil(Math.pow(pixelScale, bitrateExponent ?? this._defaultBitrateExponent) * baselineBitrate);
  }

  public static calculateScaledResolution(frameSize: Size, scaleResolutionDownBy: number): Size {
    const scaledHeight = Math.round(frameSize.height / scaleResolutionDownBy);
    const scaledWidth = Math.round(frameSize.width / scaleResolutionDownBy);
    return {
      height: scaledHeight,
      width: scaledWidth
    } as Size;
  }

  public static calculateScaleResolutionDownBy(originalFrameSize: Size, maxPixelCount: number, resolutionThrottle?: number): number {
    if (originalFrameSize || !maxPixelCount) return;
    resolutionThrottle ??= 1;
    const scaleResolutionDownBy = Math.max(1, Math.sqrt(originalFrameSize.width * originalFrameSize.height / (maxPixelCount * resolutionThrottle)));
    return scaleResolutionDownBy;
  }
}
