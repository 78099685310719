
import { defineComponent, PropType } from "vue";
import { Attendee, AttendeeCollection, AttendeeStatus, Meeting } from "@liveswitch/sdk"
import AttendeeModal from "./Attendee.vue";

export default defineComponent({
  components: {
    AttendeeModal,
  },
  props: {
    attendees: {
      required: true,
      type: Object as PropType<AttendeeCollection>
    },
    currentAttendee: {
      required: true,
      type: Object as PropType<Attendee>
    },
    meeting: {
      required: true,
      type: Object as PropType<Meeting>
    },
  },
  data(): {
    action: string,
    editing: boolean,
    editAttendee?: Attendee,
    refreshLobbyInterval: number | null,
    statusFilter: AttendeeStatus,    
  } {
    return {
      action: "",
      editing: false,
      refreshLobbyInterval: null,
      statusFilter: "ACTIVE"
    };
  },
  async mounted() { },
  watch: {
    attendees: {
      handler() {
        if (this.editAttendee != null) {
          this.editAttendee = this.attendees.find((a: Attendee) => a.id == this.editAttendee?.id);
        }
      },
      deep: true,
    }
  },
  computed: {
    canModerate() {
      return this.currentAttendee.role == "HOST" || this.currentAttendee.role == "MODERATOR";
    },
  },
  methods: {
    async clearAttendees() {
      await this.attendees.stop();
    },
    async loadAttendeeList() {
      await this.attendees.start();
    },
    toggleAudioMute(attendee: Attendee) {
      attendee.isAudioMuted ? attendee.unmuteAudio() : attendee.muteAudio();
    },
    toggleVideoMute(attendee: Attendee) {
      attendee.isVideoMuted ? attendee.unmuteVideo() : attendee.muteVideo();
    },   
    isMe(attendee: Attendee) {
      return this.currentAttendee.id == attendee.id;
    },
    inLobby() {
      return this.meeting.attendees.statusFilter == "LOBBY";
    },
    edit(attendee: Attendee) {
      this.editAttendee = attendee.id == this.currentAttendee.id ? this.currentAttendee : attendee;
      this.editing = true;
    },
    stopEditing() {
      this.editing = false;
    },
    async privateMessage(attendee: Attendee) {
      const channel = await this.meeting.chat.createChannel({
        name: `PM ${attendee.id}`,
        type: "PRIVATE",
      });
      await channel.addMember({
        attendeeId: this.currentAttendee.id,
        role: "OWNER",
      });
      await channel.addMember({
        attendeeId: attendee.id,
        role: "MEMBER",
      });
      this.$emit("privateMessage", channel.id);
    },    
    async nextPage() {
      await this.meeting.attendees.nextPage();
    },
    async prevPage() {
      await this.meeting.attendees.previousPage();
    },
    menuChange(attendee: Attendee) {
      switch (this.action) {
        case "muteAudio": 
          attendee.muteAudio();
          break;
        case "unmuteAudio": 
          attendee.unmuteAudio();
          break;
        case "muteVideo":
          attendee.muteVideo();
          break;
        case "unmuteVideo":
          attendee.unmuteVideo();
          break;
        case "kick":
          try {
            attendee.kick();
          } catch (ex) {
            alert(ex);
          }
          break;
        case "edit":
          this.edit(attendee);
          break;
        case "privateMessage":
          this.privateMessage(attendee);
          break;
        case "enableSendDisplayMedia":
          attendee.addPermission("SendDisplayMedia");
          break;
        case "disableSendDisplayMedia":
          attendee.removePermission("SendDisplayMedia");
          break;
        case "block":
          attendee.block();
          break;
        case "admit":
          attendee.admit();
          break;
        case "raiseHand":
          attendee.raiseHand();
        break;
        case "lowerHand":
          attendee.lowerHand();
          break;
        case "enableAudioUnmute":
          attendee.enableAudioUnmute();
          break;
        case "disableAudioUnmute":
          attendee.disableAudioUnmute();
          break;
         case "enableVideoUnmute":
          attendee.enableVideoUnmute();
          break;
         case "disableVideoUnmute":
          attendee.disableVideoUnmute();
          break;
        case "suppressNoise":
          attendee.suppressNoise();
          break;
        case "unsuppressNoise":
          attendee.unsuppressNoise();
        break;
        case "pin":
          attendee.pin();
          break;
        case "unpin":
          attendee.unpin();
          break;
        default:
          break;
      }
      this.action = "";
    },
    async lobbyMenuChange() {
      //TODO: feels hack-ish
      if (this.refreshLobbyInterval) {
        window.clearInterval(this.refreshLobbyInterval);
        this.refreshLobbyInterval = null;
      }

      await this.meeting.attendees.refresh({
        statusFilter: this.statusFilter
      });

      //TODO: feels hack-ish
      if (this.statusFilter == "LOBBY") {
        this.refreshLobbyInterval = window.setInterval(async()=> {
          await this.meeting.attendees.refresh();
        }, 5000);
      }
    }
  }
})
