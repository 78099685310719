
import { defineComponent, PropType } from "vue";
import { Media } from "@liveswitch/sdk";
import Video from "./Video.vue"

export default defineComponent({
  components: {
    Video,
  },
  props: {
    medias: {
      type: Object as PropType<Media[]>,
    }
  },
})
