import Constants from "./Constants";

export default class PromiseCompletionSource<T> {
  private readonly _promise: Promise<T>;
  
  private _completed = false;
  private _reason: any;
  private _resolve: (value: T | PromiseLike<T>) => void;
  private _resolved = false;
  private _reject: (reason?: any) => void;
  private _rejected = false;
  private _value: T | PromiseLike<T>;

  public get completed(): boolean { return this._completed; }
  public get promise(): Promise<T> { return this._promise; }
  public get reason(): any { return this._reason; }
  public get resolved(): boolean { return this._resolved; }
  public get rejected(): boolean { return this._rejected; }
  public get value(): T | PromiseLike<T> { return this._value; }

  public constructor(abortSignal?: AbortSignal) {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    if (abortSignal) {
      abortSignal.onabort = () => {
        this.reject(new Error(Constants.Errors.General.Operation.aborted));
      };
      if (abortSignal.aborted) {
        this.reject(new Error(Constants.Errors.General.Operation.aborted));
      }
    }
  }

  public resolve(value?: T | PromiseLike<T>) {
    this._completed = true;
    this._resolved = true;
    this._value = value ?? null;
    this._resolve(this._value);
  }

  public reject(reason?: any) {
    this._completed = true;
    this._rejected = true;
    this._reason = reason;
    this._reject(this._reason);
  }
}
