export enum RegionAbbreviationLength {
    Max = 10
}

export enum RegionCodeLength {
    Max = 3
}

export enum RegionNameLength {
    Max = 100
}