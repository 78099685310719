
import { defineComponent } from 'vue'

export default defineComponent({
  name: "ChatBox",
  props: {
    types: {
      type: String,
      default: null,
    },
    existingImageUrl: {
      type: String,
      default: null,
    },
  },
  data(): {
    chatFiles: File[];
    chatText: string;
    progress: Number;
    sending: boolean;
    uploading: boolean;
  } {
    return {
      chatFiles: [],
      chatText: "",
      progress: 0,
      sending: false,
      uploading: false,
    };
  },
  methods: {
    readFile(e: InputEvent) {
      const input = <HTMLInputElement>e.target;
      if (!input.files || !input.files.length) return;
      const file = input.files[0];
      
      this.uploading = true;
      const fileReader = new FileReader(); 
      fileReader.readAsDataURL(file); 
      fileReader.addEventListener("progress", event => {
        if (!event.lengthComputable) return;
        this.progress = event.total > 0 ? event.loaded / event.total : 0;
      });
      fileReader.addEventListener("load", () => {
        this.chatFiles.push(file);
        input.value = "";
        this.uploading = false;
      });
      fileReader.addEventListener("abort", () => {
        alert(fileReader.error);
        input.value = "";
        this.uploading = false;
      });
      fileReader.addEventListener("error", () => {
        alert(fileReader.error);
        input.value = "";
        this.uploading = false;
      });
    },
    sendMessage() {
      this.sending = true;
      this.$emit("sendMessage", () => {
        this.chatFiles = [];
        this.chatText = "";
        this.sending = false;
      });
    },
  },
});
