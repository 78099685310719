export enum AttendeeClientTypeLength {
  Max = 20
}

export enum AttendeeDisplayNameLength {
  Max = 50
}

export enum AttendeeSdkVersionLength {
  Max = 10
}

export enum AttendeeSipConnectorIdLength {
  Max = 50
}

export enum AttendeeSourceLanguageLength {
  Max = 10
}