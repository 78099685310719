import AudioLevelEvent from "./models/AudioLevelEvent";
import AudioTrackEvent from "./models/AudioTrackEvent";
import ConnectionStatsModel from "../models/ConnectionStats";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Media from "./Media";
import TrackStatsModel from "../models/TrackStats";

export default abstract class AudioTrack {
  private readonly _levelChanged = new EventOwner<AudioLevelEvent>();
  private readonly _speechStarted = new EventOwner<AudioTrackEvent>();
  private readonly _speechStopped = new EventOwner<AudioTrackEvent>();

  private _bitrate: number = null;
  private _bytesReceived: number = null;
  private _bytesSent: number = null;
  private _candidateAddress: string = null;
  private _candidateRelayProtocol: string = null;
  private _candidateType: string = null;
  private _isSpeaking: boolean = null;
  private _jitter: number = null;
  private _keepAliveRequestsReceived: number;
  private _keepAliveRequestsSent: number;
  private _keepAliveResponsesReceived: number;
  private _keepAliveResponsesSent: number;
  private _level: number = null;
  private _networkQuality: number = null;
  private _packetLoss: number = null;
  private _packetsLost: number = null;
  private _packetsReceived: number = null;
  private _packetsSent: number = null;
  private _remoteCandidateAddress: string = null;
  private _remoteCandidateType: string = null;
  private _roundTripTime: number = null;
  private _speechLevel: number = Math.pow(10, -26 / 20.0); // -26 dBov per ITU-T Rec.P.56

  public get bitrate(): number { return this._bitrate; }
  public get bytesReceived(): number { return this._bytesReceived; }
  public get bytesSent(): number { return this._bytesSent; }
  public get candidateAddress(): string { return this._candidateAddress; }
  public get candidateRelayProtocol(): string { return this._candidateRelayProtocol; }
  public get candidateType(): string { return this._candidateType; }
  public get isLocal(): boolean { return !this.isRemote; }
  public abstract get isMuted(): boolean;
  public abstract get isPaused(): boolean;
  public abstract get isRemote(): boolean;
  public get isSpeaking(): boolean { return this._isSpeaking; }
  public get jitter(): number { return this._jitter; }
  public get keepAliveRequestsReceived(): number { return this._keepAliveRequestsReceived; }
  public get keepAliveRequestsSent(): number { return this._keepAliveRequestsSent; }
  public get keepAliveResponsesReceived(): number { return this._keepAliveResponsesReceived; }
  public get keepAliveResponsesSent(): number { return this._keepAliveResponsesSent; }
  public get level(): number { return this._level; }
  public abstract get media(): Media;
  public get networkQuality(): number { return this._networkQuality; }
  public get packetLoss(): number { return this._packetLoss; }
  public get packetsLost(): number { return this._packetsLost; }
  public get packetsReceived(): number { return this._packetsReceived; }
  public get packetsSent(): number { return this._packetsSent; }
  public get remoteCandidateAddress(): string { return this._remoteCandidateAddress; }
  public get remoteCandidateType(): string { return this._remoteCandidateType; }
  public get roundTripTime(): number { return this._roundTripTime; }
  public get speechLevel(): number { return this._speechLevel; }
  public set speechLevel(value: number) { this._speechLevel = value; }
  public abstract get stream(): MediaStreamTrack;
  
  /** @event */
  public get levelChanged(): EventOwner<AudioLevelEvent> { return this._levelChanged; }
  /** @event */
  public get speechStarted(): EventOwner<AudioTrackEvent> { return this._speechStarted; }
  /** @event */
  public get speechStopped(): EventOwner<AudioTrackEvent> { return this._speechStopped; }
  /** @event */
  public abstract get streamBound(): EventOwnerAsync<AudioTrackEvent>;
  /** @event */
  public abstract get streamUnbound(): EventOwner<AudioTrackEvent>;

  /** @internal */
  public updateLevel(level: number): void {
    if (level == this._level) return;
    const previousLevel = this._level;
    this._level = level;
    this._levelChanged.dispatch({
      level: level,
      previousLevel: previousLevel,
      track: this,
    });
    
    const isSpeaking = level >= this._speechLevel;
    if (isSpeaking == this._isSpeaking) return;
    this._isSpeaking = isSpeaking;
    if (this._isSpeaking) this._speechStarted.dispatch({
      track: this
    });
    else this._speechStopped.dispatch({
      track: this
    });
  }

  /** @internal */
  public updateStats(stats: TrackStatsModel, connectionStats: ConnectionStatsModel): void {
    if (stats.bitrate != null) this._bitrate = stats.bitrate;
    if (stats.bytesReceived != null) this._bytesReceived = stats.bytesReceived;
    if (stats.bytesSent != null) this._bytesSent = stats.bytesSent;
    if (stats.jitter != null) this._jitter = stats.jitter;
    if (stats.networkQuality != null) this._networkQuality = stats.networkQuality;
    if (stats.packetLoss != null) this._packetLoss = stats.packetLoss;
    if (stats.packetsLost != null) this._packetsLost = stats.packetsLost;
    if (stats.packetsReceived != null) this._packetsReceived = stats.packetsReceived;
    if (stats.packetsSent != null) this._packetsSent = stats.packetsSent;
    if (stats.roundTripTime != null) this._roundTripTime = stats.roundTripTime;
    if (connectionStats.candidateAddress != null) this._candidateAddress = connectionStats.candidateAddress;
    if (connectionStats.candidateRelayProtocol != null) this._candidateRelayProtocol = connectionStats.candidateRelayProtocol;
    if (connectionStats.candidateType != null) this._candidateType = connectionStats.candidateType;
    if (connectionStats.keepAliveRequestsReceived != null) this._keepAliveRequestsReceived = connectionStats.keepAliveRequestsReceived;
    if (connectionStats.keepAliveRequestsSent != null) this._keepAliveRequestsSent = connectionStats.keepAliveRequestsSent;
    if (connectionStats.keepAliveResponsesReceived != null) this._keepAliveResponsesReceived = connectionStats.keepAliveResponsesReceived;
    if (connectionStats.keepAliveResponsesSent != null) this._keepAliveResponsesSent = connectionStats.keepAliveResponsesSent;
    if (connectionStats.remoteCandidateAddress != null) this._remoteCandidateAddress = connectionStats.remoteCandidateAddress;
    if (connectionStats.remoteCandidateType != null) this._remoteCandidateType = connectionStats.remoteCandidateType;
  }
}