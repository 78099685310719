
import { IdentityClient } from "@liveswitch/sdk"
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    identityServiceUrl: {
      type: String,
      default: null,
      required: true,
    },
    apiKey: {
      type: String,
      default: null,
      required: true,
    }
  },
  data(): {
    identityClient?: IdentityClient;
    registered: boolean;
    registering: boolean;
    registerError?: string;
    registerDisplayName?: string;
    registerPassword?: string;
    registerUsername?: string;
  } {
    return {
      registered: false,
      registering: false,
      registerError: undefined,
      registerDisplayName: undefined,
      registerPassword: undefined,
      registerUsername: undefined,
    };
  },
  mounted() {
    
  },
  methods: {
    async register() {
      this.registering = true;
      try {
        if (!this.registerUsername) throw new Error("Username cannot be empty.");
        if (!this.registerPassword) throw new Error("Password cannot be empty.");
        this.identityClient = new IdentityClient({
          identityServiceUrl: this.identityServiceUrl,
          apiKey: this.apiKey,
        });
        await this.identityClient.register({
          username: this.registerUsername,
          password: this.registerPassword,
          displayName: this.registerDisplayName,
        });
        this.registered = true;
      } catch (error: any) {
        this.registerError = error;
      } finally {
        this.registering = false;
      }
    },
  }
})
