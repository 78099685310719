import ApiClient from "../api/Client";
import Attendee from "../Attendee";
import ChannelType from "./models/ChannelType";
import Chat from "./Chat"
import ChannelEvent from "./models/ChannelEvent";
import ChannelInit from "./models/ChannelInit";
import ChannelModel from "../api/models/ChatChannel";
import ChannelSearchOptions from "./models/ChannelSearchOptions";
import ChannelUpdateOptions from "./models/ChannelUpdateOptions";
import Collection from "../models/Collection";
import ControlConnection from "../control/Connection";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import Member from "./Member";
import MemberAddOptions from "./models/MemberAddOptions";
import MemberCollection from "./MemberCollection";
import MemberEvent from "./models/MemberEvent";
import Message from "./Message";
import MessageCollection from "./MessageCollection";
import MessageEvent from "./models/MessageEvent";
import MessageModel from "../api/models/ChatMessage";
import MessageSendOptions from "./models/MessageSendOptions";
import MessageType from "./models/MessageType";
import Reactive from "../core/Reactive";
import SubscribedView from "../SubscribedView";
import Utility from "../core/Utility";

export default class Channel {
  private readonly _apiClient: ApiClient;
  private readonly _chat: Chat;
  private readonly _controlConnection: ControlConnection;
  private readonly _isDefault: boolean;
  private readonly _localAttendee: Attendee;
  private readonly _members: MemberCollection;
  private readonly _memberAdded = new EventOwnerAsync<MemberEvent>();
  private readonly _memberRemoved = new EventOwnerAsync<MemberEvent>();
  private readonly _messages: MessageCollection;
  private readonly _messageDeleted = new EventOwnerAsync<MessageEvent>();
  private readonly _messageReceived = new EventOwnerAsync<MessageEvent>();
  private readonly _messageSent = new EventOwnerAsync<MessageEvent>();
  private readonly _subscribedView: SubscribedView;
  private readonly _updated = new EventOwnerAsync<ChannelEvent>();

  private _model: ChannelModel;

  public get chat(): Chat { return this._chat; }
  public get id(): string { return this._model.id; }
  public get isDefault(): boolean { return this._isDefault; }
  public get members(): MemberCollection { return this._members; }
  /** @event */
  public get memberAdded(): EventOwnerAsync<MemberEvent> { return this._memberAdded; }
  /** @event */
  public get memberRemoved(): EventOwnerAsync<MemberEvent> { return this._memberRemoved; }
  public get messages(): MessageCollection { return this._messages; }
  /** @event */
  public get messageDeleted(): EventOwnerAsync<MessageEvent> { return this._messageDeleted; }
  /** @event */
  public get messageReceived(): EventOwnerAsync<MessageEvent> { return this._messageReceived; }
  /** @event */
  public get messageSent(): EventOwnerAsync<MessageEvent> { return this._messageSent; }
  public get name(): string { return this._model.name; }
  public get status(): string { return this._model.status }
  public get type(): ChannelType { return this._model.type; }
  /** @event */
  public get updated(): EventOwnerAsync<ChannelEvent> { return this._updated; }

  /** @internal */
  constructor(init: ChannelInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiClient, "init.apiClient");
    Guard.isNotNullOrUndefined(init.chat, "init.chat");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.localAttendee, "init.localAttendee");
    Guard.isNotNullOrUndefined(init.model, "init.model");
    Guard.isNotNullOrUndefined(init.subscribedView, "init.subscribedView");
    this._apiClient = init.apiClient;
    this._chat = init.chat;
    this._controlConnection = init.controlConnection;
    this._isDefault = Utility.isNullOrUndefined(init.model.id);
    this._localAttendee = init.localAttendee;
    this._model = init.model;
    this._subscribedView = init.subscribedView;
    this._members = new MemberCollection();
    this._messages = new MessageCollection();
  }

  private async sendMessage(messageModel: MessageModel, file?: File): Promise<Message> {
    if (file) {
      const fileId = (await this._apiClient.createFile({
        file: file,
        meetingId: this._controlConnection.meetingId
      })).value?.id;
      if (!fileId) throw new Error("File upload failed.");
      messageModel.uri = `file:${fileId}`;
    }

    messageModel = (await this._controlConnection.addChatChannelMessage(messageModel, {
      channelId: this._model.id,
    })).chatMessage;
    if (!messageModel) throw new Error("Could not send message.");

    const message = new Message({
      apiClient: this._apiClient,
      attendee: this._localAttendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: messageModel,
    });

    if (file) {
      await message.refreshModel();
    }
    
    this._messages.tryAdd(message);
    await this._messageSent.dispatch({ message });
    return message;
  }

  /** @internal */
  public async addMemberInternal(memberId: string): Promise<Member> {
    if (this._members.get(memberId)) return;
    
    const memberModel = (await this._controlConnection.getChatChannelMember({
      chatMemberId: memberId,
    })).chatChannelMember;
    if (!memberModel) return;
    
    const attendee = await this._subscribedView.subscribeToAttendee(memberModel.attendeeId, "chatMemberPaging");
    if (!attendee) return;

    const member = new Member({
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: memberModel,
    });
    this._members.tryAdd(member);
    await this._memberAdded.dispatch({ member });
    return member;
  }

  /** @internal */
  public async addMessageInternal(messageId: string, attendeeId: string) : Promise<Message> {
    const messageModel = (await this._controlConnection.getChatChannelMessage({
      messageId: messageId,
    })).chatMessage;

    const attendee = await this._subscribedView.subscribeToAttendee(attendeeId, "chatMessageReceived");
    if (!attendee) return;

    const message = new Message({
      apiClient: this._apiClient,
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: messageModel,
    });
    await message.load();
    this._messages.tryAdd(message);
    await this._messageReceived.dispatch({ message });
    return message;
  }

  /** @internal */
  public async load(): Promise<void> {
    await this._messages.load({
      apiClient: this._apiClient,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      subscribedView: this._subscribedView,
    });
    if (this._isDefault) return;
    await this._members.load({
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      subscribedView: this._subscribedView,
    });
  }

  /** @internal */
  public async refreshModel(): Promise<void> {
    if (this._isDefault) return;

    const channelModel = (await this._controlConnection.getChatChannel({
      channelId: this._model.id,
    })).chatChannel;
    if (!channelModel) return;

    this._model = channelModel;
    await this._updated.dispatch({
      channel: this
    });
  }

  /** @internal */
  public async removeMemberInternal(memberId: string): Promise<void> {
    const member = this._members.get(memberId);
    if (!member || !this._members.tryRemove(memberId)) return;
    await this._memberRemoved.dispatch({ member });
  }

  /** @internal */
  public async removeMessageInternal(messageId: string): Promise<void> {
    const message = this._messages.get(messageId);
    if (!message || !this._messages.tryRemove(messageId)) return;
    await this._messageDeleted.dispatch({ message });
  }

  /** @internal */
  public async updateMemberInternal(memberId: string): Promise<void> {
    await this._members.get(memberId)?.refreshModel();
  }

  /** @internal */
  public async updateMessageInternal(messageId: string): Promise<void> {
    await this._messages.get(messageId)?.refreshModel();
  }
  
  public async addMember(options: MemberAddOptions): Promise<Member> {
    if (this._isDefault) throw new Error("Cannot add members to the default channel.");
    const memberModel = (await this._controlConnection.addChatChannelMember(this._model, {
      attendeeId: options.attendeeId,
      channelId: this._model.id, // not currently used by the server
      roleType: options.role,
    })).chatChannelMember;
    if (!memberModel) throw new Error("Could not add member.");

    const attendee = await this._subscribedView.subscribeToAttendee(memberModel.attendeeId, "chatMemberPaging");
    if (!attendee) throw new Error("Could not add member. Could not subscribe to attendee.");

    const member = new Member({
      attendee: attendee,
      channel: Reactive.wrap(this),
      controlConnection: this._controlConnection,
      model: memberModel,
    });
    this._members.tryAdd(member);
    await this._memberAdded.dispatch({ member });
    return member;
  }

  public async delete(): Promise<void> {
    if (this._isDefault) throw new Error("Cannot delete the default channel.");
    await this._controlConnection.deleteChatChannel(this._model, {
      channelId: this._model.id,
    });
    this._chat.removeChannelInternal(this._model.id);
  }

  public async search(options: ChannelSearchOptions): Promise<Collection<Message>> {
    const collection = (await this._controlConnection.listChatChannelMessages({
      channelId: this._model.id,
      limit: options.limit,
      offset: options.offset,
      offsetId: options.offsetId,
      sortDirection: "DESC",
      where: options.filter,
    })).chatMessages;

    const messages: Message[] = [];
    for (const messageModel of collection.values) {
      const attendee = await this._subscribedView.subscribeToAttendee(messageModel.createdBy, "chatMessageReceived");
      const message = new Message({
        apiClient: this._apiClient,
        attendee: attendee,
        channel: Reactive.wrap(this),
        controlConnection: this._controlConnection,
        model: messageModel,
      });
      await message.load();
      messages.push(message);
    }
    return {
      totalCount: collection.totalCount,
      values: messages,
    }
  }

  public async send(content: string | File, options?: MessageSendOptions): Promise<Message> {
    Guard.isNotNullOrUndefined(content, "content");
    options ??= {};
    if (Utility.isString(content)) {
      return await this.sendMessage({
        content: <string>content,
        priority: options.priority,
        type: "TEXT",
      });
    }
    const file = <File>content;
    let type: MessageType = "FILE";
    if (file.type?.startsWith("audio/")) type = "AUDIO";
    if (file.type?.startsWith("image/")) type = "IMAGE";
    if (file.type?.startsWith("video/")) type = "VIDEO";
    return await this.sendMessage({
      fileLength: file.size,
      fileName: file.name,
      mimeType: file.type,
      priority: options.priority,
      type: type,
    }, file);
  }
  
  public async update(options: ChannelUpdateOptions): Promise<void> {
    if (this._isDefault) throw new Error("Cannot update the default channel.");
    Guard.isNotNullOrUndefined(options, "options");
    await this._controlConnection.updateChatChannel(Object.assign({}, this._model, options));
  }
}