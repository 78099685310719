import BadRequestField from "./BadRequestField";
import ServerError from "./ServerError";

export default class BadRequestError extends ServerError {
  private readonly _requestFields: BadRequestField[];

  constructor(requestFields: BadRequestField[]) {
    super("The request failed validation.");
    this.name = "BadRequestError";
    this._requestFields = requestFields;
  }

  public get requestFields(): BadRequestField[] { return this._requestFields; }
}