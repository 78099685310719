import AttendeeEvent from "./models/AttendeeEvent";
import AttendeeInit from "./models/AttendeeInit";
import AttendeeModel from "./api/models/Attendee";
import AttendeePermissionEvent from "./models/AttendeePermissionEvent";
import AttendeeQuality from "./models/AttendeeQuality";
import AttendeeRole from "./models/AttendeeRole";
import AttendeeSetting from "./AttendeeSetting";
import AttendeeSettingName from "./models/AttendeeSettingName";
import AttendeeSettingCollection from "./AttendeeSettingCollection";
import AttendeeSettingEvent from "./models/AttendeeSettingEvent";
import AttendeeStatus from "./models/AttendeeStatus";
import AttendeeType from "./models/AttendeeType";
import Constants from "./core/Constants";
import ControlConnection from "./control/Connection";
import DispatchQueue from "./core/DispatchQueue";
import EventOwner from "./core/EventOwner";
import EventOwnerAsync from "./core/EventOwnerAsync";
import Guard from "./core/Guard";
import Media from "./media/Media";
import Meeting from "./Meeting";
import NetworkStatus from "./models/NetworkStatus";
import PermissionName from "./models/PermissionName";
import Permissions from "./Permissions";
import Utility from "./core/Utility";
import { AttendeeDisplayNameLength } from "./core/validation/Attendee";
import { UserAccountAvatarUrlLength } from "./core/validation/UserAccount";

export default class Attendee {
  private readonly _audioMuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _audioPaused = new EventOwnerAsync<AttendeeEvent>();
  private readonly _audioResumed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _audioUnmuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _audioUnmuteDisabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _audioUnmuteEnabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _avatarUrlUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _controlConnection: ControlConnection;
  private readonly _displayNameUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _eventQueue = new DispatchQueue();
  private readonly _handRaised = new EventOwnerAsync<AttendeeEvent>();
  private readonly _handLowered = new EventOwnerAsync<AttendeeEvent>();
  private readonly _isLocal: boolean;
  private readonly _mediaBound = new EventOwnerAsync<AttendeeEvent>();
  private readonly _mediaUnbound = new EventOwner<AttendeeEvent>();
  private readonly _meeting: Meeting;
  private readonly _networkStatusReceiveUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _networkStatusSendUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _noiseSuppressed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _noiseUnsuppressed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _permissionAdded = new EventOwnerAsync<AttendeePermissionEvent>();
  private readonly _permissionRemoved = new EventOwnerAsync<AttendeePermissionEvent>();
  private readonly _permissionsUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _qualityUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _roleUpdated = new EventOwnerAsync<AttendeeEvent>();
  private readonly _settings: AttendeeSettingCollection = new AttendeeSettingCollection();
  private readonly _settingAdded = new EventOwnerAsync<AttendeeSettingEvent>();
  private readonly _settingRemoved = new EventOwnerAsync<AttendeeSettingEvent>();
  private readonly _videoMuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _videoPaused = new EventOwnerAsync<AttendeeEvent>();
  private readonly _videoResumed = new EventOwnerAsync<AttendeeEvent>();
  private readonly _videoUnmuted = new EventOwnerAsync<AttendeeEvent>();
  private readonly _videoUnmuteDisabled = new EventOwnerAsync<AttendeeEvent>();
  private readonly _videoUnmuteEnabled = new EventOwnerAsync<AttendeeEvent>();
  
  private _isPinned = false;
  private _media: Media = null;
  private _model: AttendeeModel = null;
  private _networkStatusReceive: NetworkStatus = "unknown";
  private _networkStatusSend: NetworkStatus = "unknown";
  private _quality: AttendeeQuality = "high";

  /** @internal */
  public set avatarUrl(value: string) { this._model.avatarUrl = value; }
  /** @internal */
  public get canBlock(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "BlockAttendee"); }
  /** @internal */
  public get canCreateChatChannel(): boolean { return Permissions.hasPermission(this, "CHAT", "CreateChannel"); }
  /** @internal */
  public get canDeleteChatChannel(): boolean { return Permissions.hasPermission(this, "CHAT", "DeleteChannel"); }
  /** @internal */
  public get canDeleteChatMessage(): boolean { return Permissions.hasPermission(this, "CHAT", "DeleteMessage"); }
  /** @internal */
  public get canEnableChat(): boolean { return Permissions.hasPermission(this, "MEETING", "EnableChat"); }
  /** @internal */
  public get canKick(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "KickAttendee"); }
  /** @internal */
  public get canMute(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "MuteAttendee"); }
  /** @internal */
  public get canReceiveMedia(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "ReceiveMedia"); }
  /** @internal */
  public get canRecord(): boolean { return Permissions.hasPermission(this, "MEETING", "RecordMeeting"); }
  /** @internal */
  public get canSendChatMessage(): boolean { return Permissions.hasPermission(this, "CHAT", "SendMessage"); }
  /** @internal */
  public get canSendUserMedia(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "SendUserMedia"); }
  /** @internal */
  public get canSendDisplayMedia(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "SendDisplayMedia"); }
  /** @internal */
  public get canUpdate(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "UpdateAttendee"); }
  /** @internal */
  public get canUnmute(): boolean { return Permissions.hasPermission(this, "ATTENDEE", "UnmuteAttendee"); }
  /** @internal */
  public set displayName(value: string) { this._model.displayName = value; }
  /** @internal */
  public set isPinned(value: boolean) { this._isPinned = value; }
  /** @internal */
  public get model(): AttendeeModel { return this._model; }
  /** @internal */
  public set model(value: AttendeeModel)  { this._model = value; }
  /** @internal */
  public set permissions(value: string[]) { this._model.permissions = value; }
  /** @internal */
  public set role(value: AttendeeRole) { this._model.role = value; }

  /** @event */
  public get audioMuted(): EventOwnerAsync<AttendeeEvent> { return this._audioMuted; }
  /** @event */
  public get audioPaused(): EventOwnerAsync<AttendeeEvent> { return this._audioPaused; }
  /** @event */
  public get audioResumed(): EventOwnerAsync<AttendeeEvent> { return this._audioResumed; }
  /** @event */
  public get audioUnmuted(): EventOwnerAsync<AttendeeEvent> { return this._audioUnmuted; }
  /** @event */
  public get audioUnmuteDisabled(): EventOwnerAsync<AttendeeEvent> { return this._audioUnmuteDisabled; }
  /** @event */
  public get audioUnmuteEnabled(): EventOwnerAsync<AttendeeEvent> { return this._audioUnmuteEnabled; }
  /** @event */
  public get avatarUrlUpdated(): EventOwnerAsync<AttendeeEvent> { return this._avatarUrlUpdated; }
  /** @event */
  public get displayNameUpdated(): EventOwnerAsync<AttendeeEvent> { return this._displayNameUpdated; }
  /** @event */
  public get handRaised(): EventOwnerAsync<AttendeeEvent> { return this._handRaised; }
  /** @event */
  public get handLowered(): EventOwnerAsync<AttendeeEvent> { return this._handLowered; }
  /** @event */
  public get mediaBound(): EventOwnerAsync<AttendeeEvent> { return this._mediaBound; }
  /** @event */
  public get mediaUnbound(): EventOwner<AttendeeEvent> { return this._mediaUnbound; }
  /** @event */
  public get networkStatusReceiveUpdated(): EventOwnerAsync<AttendeeEvent> { return this._networkStatusReceiveUpdated; }
  /** @event */
  public get networkStatusSendUpdated(): EventOwnerAsync<AttendeeEvent> { return this._networkStatusSendUpdated; }
  /** @event */
  public get noiseSuppressed(): EventOwnerAsync<AttendeeEvent> { return this._noiseSuppressed; }
  /** @event */
  public get noiseUnsuppressed(): EventOwnerAsync<AttendeeEvent> { return this._noiseUnsuppressed; }
  /** @event */
  public get permissionAdded(): EventOwnerAsync<AttendeePermissionEvent> { return this._permissionAdded; }
  /** @event */
  public get permissionRemoved(): EventOwnerAsync<AttendeePermissionEvent> { return this._permissionRemoved; }
  /** @event */
  public get permissionsUpdated(): EventOwnerAsync<AttendeeEvent> { return this._permissionsUpdated; }
  /** @event */
  public get qualityUpdated(): EventOwnerAsync<AttendeeEvent> { return this._qualityUpdated; }
  /** @event */
  public get roleUpdated(): EventOwnerAsync<AttendeeEvent> { return this._roleUpdated; }
  /** @event */
  public get settingAdded(): EventOwnerAsync<AttendeeSettingEvent> { return this._settingAdded; }
  /** @event */
  public get settingRemoved(): EventOwnerAsync<AttendeeSettingEvent> { return this._settingRemoved; }
  /** @event */
  public get videoMuted(): EventOwnerAsync<AttendeeEvent> { return this._videoMuted; }
  /** @event */
  public get videoPaused(): EventOwnerAsync<AttendeeEvent> { return this._videoPaused; }
  /** @event */
  public get videoResumed(): EventOwnerAsync<AttendeeEvent> { return this._videoResumed; }
  /** @event */
  public get videoUnmuted(): EventOwnerAsync<AttendeeEvent> { return this._videoUnmuted; }
  /** @event */
  public get videoUnmuteDisabled(): EventOwnerAsync<AttendeeEvent> { return this._videoUnmuteDisabled; }
  /** @event */
  public get videoUnmuteEnabled(): EventOwnerAsync<AttendeeEvent> { return this._videoUnmuteEnabled; }
  
  public get avatarUrl(): string { return this._model.avatarUrl; }
  public get displayName(): string { return this._model.displayName; }
  public get id(): string { return this._model.id; }
  public get isAudioMuted(): boolean { return this._model.isUserAudioMuted; }
  public get isAudioPaused(): boolean { return this._model.isUserAudioPaused; }
  public get isAudioUnmuteDisabled(): boolean { return !this._model.allowUnmuteAudio; }
  public get isLocal(): boolean { return this._isLocal; }
  public get isNoiseSuppressed(): boolean { return this._model.isNoiseSuppressed; }
  public get isPinned(): boolean { return this._isPinned; }
  public get isRemote(): boolean { return !this._isLocal; }
  public get isVideoMuted(): boolean { return this._model.isUserVideoMuted; }
  public get isVideoPaused(): boolean { return this._model.isUserVideoPaused; }
  public get isVideoUnmuteDisabled(): boolean { return !this._model.allowUnmuteVideo; }
  public get media(): Media { return this._media; }
  public get networkStatusReceive(): NetworkStatus { return this._networkStatusReceive; }
  public get networkStatusSend(): NetworkStatus { return this._networkStatusSend; }
  public get permissions(): string[] { return this._model.permissions; }
  public get quality(): AttendeeQuality { return this._quality; }
  public get role(): AttendeeRole { return this._model.role; }
  public get settings(): AttendeeSettingCollection { return this._settings; }
  public get status(): AttendeeStatus { return this._model.status; }
  public get type(): AttendeeType { return this._model.type; }
  public get userAccountId(): string { return this._model.userAccountId; }
  
  /** @internal */
  constructor(init: AttendeeInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.controlConnection, "init.controlConnection");
    Guard.isNotNullOrUndefined(init.isLocal, "init.isLocal");
    Guard.isNotNullOrUndefined(init.meeting, "init.meeting");
    Guard.isNotNullOrUndefined(init.model, "init.model");
    this._controlConnection = init.controlConnection;
    this._isLocal = init.isLocal;
    this._meeting = init.meeting;
    this._model = init.model;
    this._model.settings?.forEach((setting) => {
      this._settings.tryAdd(setting);
    });
    this._networkStatusReceive = this.getNetworkStatusReceive();
    this._networkStatusSend = this.getNetworkStatusSend();
    this._quality = this.getQuality();
  }

  private getQuality(): AttendeeQuality {
    if (this._networkStatusReceive == "offline" || this._networkStatusSend == "offline" || this.isAudioPaused || this.isVideoPaused) return "low";
    if (this._networkStatusReceive == "degraded" || this._networkStatusSend == "degraded") return "medium";
    return "high";
  }

  private getNetworkStatusReceive(): NetworkStatus {
    const edgeAudioQualityDisplay = this._model.edgeAudioQualityDisplay ?? null;
    const edgeAudioQualityUser = this._model.edgeAudioQualityUser ?? null;
    const edgeVideoQualityDisplay = this._model.edgeVideoQualityDisplay ?? null;
    const edgeVideoQualityUser = this._model.edgeVideoQualityUser ?? null;
    if (edgeAudioQualityDisplay == 0 ||
        edgeAudioQualityUser == 0 ||
        edgeVideoQualityDisplay == 0 ||
        edgeVideoQualityUser == 0) return "offline";
    if ((edgeAudioQualityDisplay > 0 && edgeAudioQualityDisplay < 1) ||
        (edgeAudioQualityUser > 0 && edgeAudioQualityUser < 1) ||
        (edgeVideoQualityDisplay > 0 && edgeVideoQualityDisplay < 1) ||
        (edgeVideoQualityUser > 0 && edgeVideoQualityUser < 1)) return "degraded";
    if (edgeAudioQualityDisplay == 1 ||
        edgeAudioQualityUser == 1 ||
        edgeVideoQualityDisplay == 1 ||
        edgeVideoQualityUser == 1) return "online";
    return "unknown";
  }

  private getNetworkStatusSend(): NetworkStatus {
    const originAudioQualityDisplay = this._model.originAudioQualityDisplay ?? null;
    const originAudioQualityUser = this._model.originAudioQualityUser ?? null;
    const originVideoQualityDisplay = this._model.originVideoQualityDisplay ?? null;
    const originVideoQualityUser = this._model.originVideoQualityUser ?? null;
    if (originAudioQualityDisplay == 0 ||
        originAudioQualityUser == 0 ||
        originVideoQualityDisplay == 0 ||
        originVideoQualityUser == 0) return "offline";
    if ((originAudioQualityDisplay > 0 && originAudioQualityDisplay < 1) ||
        (originAudioQualityUser > 0 && originAudioQualityUser < 1) ||
        (originVideoQualityDisplay > 0 && originVideoQualityDisplay < 1) ||
        (originVideoQualityUser > 0 && originVideoQualityUser < 1)) return "degraded";
    if (originAudioQualityDisplay == 1 ||
        originAudioQualityUser == 1 ||
        originVideoQualityDisplay == 1 ||
        originVideoQualityUser == 1) return "online";
    return "unknown";
  }

  private async tryUpdateQuality(): Promise<boolean> {
    const quality = this.getQuality();
    if (quality == this._quality) return false;

    this._quality = quality;
    await this._qualityUpdated.dispatch({
      attendee: this
    });
    return true;
  }
  
  /** @internal */
  public async bindMedia(media: Media) {
    Guard.isNotNullOrUndefined(media, "media");
    if (this._media == media) return;
    this._media = media;
    await this._mediaBound.dispatch({
      attendee: this,
    });
  }

  /** @internal */
  public async setHandRaised(): Promise<void> { 
    if (this._settings.tryAdd({ attendeeId: this.id, name: "handraised" })) {
      await this.handRaised.dispatch({ attendee: this });
    }
  }

  /** @internal */
  public async setHandLowered(): Promise<void> { 
    if (this._settings.tryRemove("handraised")) {
      await this._handLowered.dispatch({ attendee: this });
    }
  }

  /** @internal */
  public async processDisableAudioUnmute() {
    if (!this._model.allowUnmuteAudio) return;
    this._model.allowUnmuteAudio = false; 
    await this.audioUnmuteDisabled.dispatch({ attendee: this });
  }

  /** @internal */
  public async processDisableVideoUnmute() {
    if (!this._model.allowUnmuteVideo) return;
    this._model.allowUnmuteVideo = false; 
    await this.videoUnmuteDisabled.dispatch({ attendee: this });
  }

  /** @internal */
  public async processEnableAudioUnmute() {
    if (this._model.allowUnmuteAudio) return;
    this._model.allowUnmuteAudio = true; 
    await this.audioUnmuteEnabled.dispatch({ attendee: this });
  }

  /** @internal */
  public async processEnableVideoUnmute() {
    if (this._model.allowUnmuteVideo) return;
    this._model.allowUnmuteVideo = true; 
    await this.videoUnmuteEnabled.dispatch({ attendee: this });
  }

  /** @internal */
  public async processMuteAudio() {
    if (this._model.isUserAudioMuted) return;
    this._model.isUserAudioMuted = true;
    await this.audioMuted.dispatch({ attendee: this });
  }

  /** @internal */
  public async processMuteVideo() {
    if (this._model.isUserVideoMuted) return;
    this._model.isUserVideoMuted = true;
    await this.videoMuted.dispatch({ attendee: this });
  }

  /** @internal */
  public async processPausedUpdated(attendee: AttendeeModel): Promise<void> {
    // update model
    if (this._model.isDisplayAudioPaused != attendee.isDisplayAudioPaused) {
      this._model.isDisplayAudioPaused = attendee.isDisplayAudioPaused;
    }
    if (this._model.isDisplayVideoPaused != attendee.isDisplayVideoPaused) {
      this._model.isDisplayVideoPaused = attendee.isDisplayVideoPaused;
    }
    if (this._model.isUserAudioPaused != attendee.isUserAudioPaused) {
      this._model.isUserAudioPaused = attendee.isUserAudioPaused;
      if (this._model.isUserAudioPaused) this._audioPaused.dispatch({ attendee: this });
      else this._audioResumed.dispatch({ attendee: this });
    }
    if (this._model.isUserVideoPaused != attendee.isUserVideoPaused) {
      this._model.isUserVideoPaused = attendee.isUserVideoPaused;
      if (this._model.isUserVideoPaused) this._videoPaused.dispatch({ attendee: this });
      else this._videoResumed.dispatch({ attendee: this });
    }

    // update attendee quality
    await this.tryUpdateQuality();
  }

  /** @internal */
  public async processPermissionAdded(permissionName: PermissionName) {
    const permissionType = Permissions.getPermissionTypeFromName(permissionName);
    const newPermission = `${permissionType}:${permissionName}`;
    if (this.permissions.indexOf(newPermission) == -1) {
      this.permissions.push(newPermission);
      await this.permissionAdded.dispatch({
        attendee: this,
        permission: `${permissionType}:${permissionName}`
      });
    }
  }

  /** @internal */
  public async processPermissionRemoved(permissionName: PermissionName) {
    const permissionType = Permissions.getPermissionTypeFromName(permissionName);
    const removeAt = this.permissions.indexOf(`${permissionType}:${permissionName}`);
    if (removeAt != -1) {
      this.permissions.splice(removeAt, 1);
      await this.permissionRemoved.dispatch({
        attendee: this,
        permission: `${permissionType}:${permissionName}`
      });
    }
  }

  /** @internal */
  public async processSettingAdded(setting: AttendeeSetting) {
    if (this._settings.tryAdd(setting)) {
      await this.settingAdded.dispatch({ attendee: this, attendeeSetting: setting });
    }
  }

  /** @internal */
  public async processSettingRemoved(setting: AttendeeSetting) {
    if (this._settings.tryRemove(setting.name)) {
      await this.settingRemoved.dispatch({ attendee: this, attendeeSetting: setting });
    }
  }

  /** @internal */
  public async processQualityEdgeUpdate(attendee: AttendeeModel): Promise<void> {
    // update model
    this._model.edgeAudioQualityDisplay = attendee.edgeAudioQualityDisplay;
    this._model.edgeAudioQualityUser = attendee.edgeAudioQualityUser;
    this._model.edgeVideoQualityDisplay = attendee.edgeVideoQualityDisplay;
    this._model.edgeVideoQualityUser = attendee.edgeVideoQualityUser;
    
    // update network status
    this._networkStatusReceive = this.getNetworkStatusReceive();
    await this._networkStatusReceiveUpdated.dispatch({
      attendee: this
    });

    // update attendee quality
    await this.tryUpdateQuality();
  }

  /** @internal */
  public async processQualityOriginUpdate(attendee: AttendeeModel): Promise<void> {
    // update model
    this._model.originAudioQualityDisplay = attendee.originAudioQualityDisplay;
    this._model.originAudioQualityUser = attendee.originAudioQualityUser;
    this._model.originVideoQualityDisplay = attendee.originVideoQualityDisplay;
    this._model.originVideoQualityUser = attendee.originVideoQualityUser;
    
    // update network status
    this._networkStatusSend = this.getNetworkStatusSend();
    await this._networkStatusSendUpdated.dispatch({
      attendee: this
    });

    // update attendee quality
    await this.tryUpdateQuality();
  }

  /** @internal */
  public async processSuppressNoise() {
    if (this._model.isNoiseSuppressed) return;
    this._model.isNoiseSuppressed = true;
    await this.noiseSuppressed.dispatch({ attendee: this });
  }

  /** @internal */
  public async processUnmuteAudio() {
    if (!this._model.isUserAudioMuted) return;
    this._model.isUserAudioMuted = false;
    await this.audioUnmuted.dispatch({ attendee: this });
  }

  /** @internal */
  public async processUnmuteVideo() {
    if (!this._model.isUserVideoMuted) return;
    this._model.isUserVideoMuted = false;
    await this.videoUnmuted.dispatch({ attendee: this });
  }

  /** @internal */
  public async processUnsuppressNoise() {
    if (!this._model.isNoiseSuppressed) return;
    this._model.isNoiseSuppressed = false;
    await this.noiseUnsuppressed.dispatch({ attendee: this });
  }

  /** @internal */
  public async processUpdate(attendee: AttendeeModel): Promise<void> {
    const avatarUrl = attendee.avatarUrl;
    if (avatarUrl != null && this.avatarUrl != avatarUrl) {
      this.avatarUrl = avatarUrl;
      await this._avatarUrlUpdated.dispatch({
        attendee: this,
      });
    }

    const displayName = attendee.displayName;
    if (displayName != null && this.displayName != displayName) {
      this.displayName = displayName;
      await this._displayNameUpdated.dispatch({
        attendee: this,
      });
    }

    const role = attendee.role;
    if (role != null && this._model.role != role) {
      this._model.role = role;
      await this._roleUpdated.dispatch({
        attendee: this,
      });
    }

    const permissions = attendee.permissions;
    if (permissions != null && permissions.length != this.permissions.length) {
      this.permissions = permissions;
      await this._permissionsUpdated.dispatch({
        attendee: this,
      });
    }
  }

  /** @internal */
  public unbindMedia(): void {
    if (!this._media) return;
    this._media = null;
    this._mediaUnbound.dispatch({
      attendee: this,
    });
  }

  /**
   * Add a permission to this attendee.
   * @param permissionName The permissionName to add.
   * @returns The promise object representing the asynchronous operation.
   */
  public async addPermission(permissionName: PermissionName): Promise<void> {
    const permissionType = Permissions.getPermissionTypeFromName(permissionName);
    if (this.permissions.indexOf(`${permissionType}:${permissionName}`) != -1) {
      return;
    }
    const permission = {
      permissionName: permissionName,
      permissionType: permissionType,
      attendeePermission: {
        attendeeId: this.id
      },
      meetingId: this._meeting.id,
    };
    const response = await this._controlConnection.addAttendeePermission(permission);
    await this._meeting.processAttendeePermissionAdded({attendeePermission: response.attendeePermissionRequest});
  }

  public async admit() {
    if (!this._meeting.hasLobby) throw new Error(Constants.Errors.Meeting.noLobby);
    if (this._model.status != "LOBBY") return;
    await this._controlConnection.admitAttendee(this.model);
  }

  public async block() {
    if (this._model.status == "LOBBY") {
      await this._controlConnection.blockAttendeeFromLobby(this.model);
    } else {
      await this._controlConnection.blockAttendeeFromRoom(this.model);
    }
  }

  /**
   * Checks if attendee has permission.
   * @param permissionName
   */
  public hasPermission(permissionName: PermissionName) {
    const permissionType = Permissions.getPermissionTypeFromName(permissionName);
    return this.permissions.includes(`${permissionType}:${permissionName}`);
  }

  /**
   * Disables the attendees ability to unmute audio.
   */
  public async disableAudioUnmute() {
    await this._controlConnection.disableAttendeeAudioUnmute(this.model);
    await this._meeting.processAttendeeAudioUnmuteDisabled({attendee: this.model});
  }

  /**
   * Disables the attendees ability to unmute video.
   */
  public async disableVideoUnmute() {
    await this._controlConnection.disableAttendeeVideoUnmute(this.model);
    await this._meeting.processAttendeeVideoUnmuteDisabled({attendee: this.model});
  }

  /**
   * Enables the attendees ability to unmute audio.
   */
  public async enableAudioUnmute() {
    await this._controlConnection.enableAttendeeAudioUnmute(this.model);
    await this._meeting.processAttendeeAudioUnmuteEnabled({attendee: this.model});
  }

  /**
   * Enables the attendees ability to unmute video.
   */
  public async enableVideoUnmute() {
    await this._controlConnection.enableAttendeeVideoUnmute(this.model);
    await this._meeting.processAttendeeVideoUnmuteEnabled({attendee: this.model});
  }

  /**
   * Checks if Attendees hand is raised.
   */
  public isHandRaised(): boolean {
    if (this._settings.get("handraised")) {
      return true;
    }
    return false;
  }

  /**
   * Kicks the attendee from the meeting.
   */
  public async kick() {
    if (this._meeting.localAttendee.userAccountId == this.userAccountId) throw new Error("Cannot kick self.");
    await this._controlConnection.kickAttendee(this.model);
  }

  /**
   * Lowers attendees hand.
   */
  public async lowerHand() {      
    if (this._meeting.localAttendee.id != this.id) throw new Error(Constants.Errors.Attendee.cannotLowerOtherAttendeesHand);
    if (this.isLocal && this._settings.get("handraised")) {
      const message = await this._controlConnection.lowerAttendeeHand(this.model, this._settings.get("handraised"));
      await this._meeting.processAttendeeHandLowered(message);
    }
  }

  /**
   * Mutes the attendees audio.
   */
  public muteAudio(): Promise<void> {   
    return this._eventQueue.dispatch(async () => {
      if (this._model.isUserAudioMuted) return;
      this._model.isUserAudioMuted = true;
      if (this.isLocal) this._meeting.localUserMedia?.audioTrack?.muteInternal();
      try {
        await this._controlConnection.muteAttendeeAudio(this.model);
        await this._meeting.processAttendeeAudioMuted({ attendee: this.model });
      } catch (error: any) {
        if (this.isLocal) this._meeting.localUserMedia?.audioTrack?.unmuteInternal();
        this._model.isUserAudioMuted = false;
        throw error;
      }
    });
  }

  /**
   * Mutes the attendees video.
   */
  public muteVideo(): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      if (this._model.isUserVideoMuted) return;
      this._model.isUserVideoMuted = true;
      if (this.isLocal) this._meeting.localUserMedia?.videoTrack?.muteInternal();
      try {
        await this._controlConnection.muteAttendeeVideo(this.model);
        await this._meeting.processAttendeeVideoMuted({ attendee: this.model });
      } catch (error: any) {
        if (this.isLocal) this._meeting.localUserMedia?.videoTrack?.unmuteInternal();
        this._model.isUserVideoMuted = false;
        throw error;
      }
    });
  }

  /**
   * Pins the attendee
   */
  public async pin() {
    await this._controlConnection.pinAttendee(this.model);
    await this._meeting.processAttendeePinned({attendee: this.model});
  }

  /**
   * Raises attendees hand.
   */
  public async raiseHand() {    
    if (this._meeting.localAttendee.id != this.id) throw new Error(Constants.Errors.Attendee.cannotRaiseOtherAttendeesHand);
    
    if (this.isLocal && !this._settings.get("handraised")) {
      const message = await this._controlConnection.raiseAttendeeHand(this.model, {
        attendeeId: this.id,
        name: "handraised",
      });
      await this._meeting.processAttendeeHandRaised(message);
    }
  }

  /**
   * Adds attendee setting
   */
  public async addSetting(setting: AttendeeSetting) {    
    if (this.isLocal && !this._settings.get(setting.name)) {
      const message = await this._controlConnection.addAttendeeSetting(this.model, setting);
      await this._meeting.processAttendeeSettingAdded(message);
    }
  }

    /**
   * Removes attendee setting
   */
    public async removeSetting(attendeeSettingName: AttendeeSettingName | string) {    
      if (this.isLocal && this._settings.get(attendeeSettingName)) {
        const message = await this._controlConnection.removeAttendeeSetting(this.model, {
          attendeeId: this.id,
          name: attendeeSettingName
        });
        await this._meeting.processAttendeeSettingRemoved(message);
      }
    }
    
  /**
   * Remove a permission from this attendee.
   * @param permission The permission {@link permissionName} to remove. 
   * @returns The promise object representing the asynchronous operation.
   */
  public async removePermission(permissionName: PermissionName): Promise<void> {
    const permissionType = Permissions.getPermissionTypeFromName(permissionName);
    const permission = {
      permissionName: permissionName,
      permissionType: permissionType,
      attendeePermission: {
        attendeeId: this.id
      },
      meetingId: this._meeting.id,
    };
    await this._controlConnection.removeAttendeePermission(permission);
    await this._meeting.processAttendeePermissionRemoved({attendeePermission: permission});
  }
    
  /**
   * Update the avatarUrl on this attendee.
   * @param avatarUrl The new avatarUrl.
   * @returns The promise object representing the asynchronous operation.
   */
  public async setAvatarUrl(avatarUrl: string): Promise<void> {
    if (!Utility.isNullOrUndefined(avatarUrl) && avatarUrl.length > UserAccountAvatarUrlLength.Max) {
      throw new Error(Constants.Errors.UserAccount.invalidAvatarUrlLength);
    }
    Guard.isLengthWithinBounds(avatarUrl, "avatarUrl", UserAccountAvatarUrlLength.Max);
    const oldAvatarUrl = this.avatarUrl;
    if (this.isLocal) this._meeting.localAttendee.avatarUrl = avatarUrl;
    const sendModel = <AttendeeModel>{};
    Object.assign(sendModel, this.model);
    sendModel.avatarUrl = avatarUrl;
    try {
      await this._controlConnection.updateAttendeeAvatarUrl(sendModel);
      await this._meeting.processAttendeeUpdated({attendee: sendModel});
    } catch (error: any) {
      if (this.isLocal) this._meeting.localAttendee.avatarUrl = oldAvatarUrl;
      throw error;
    }
  }

  /**
   * Update the display name on this attendee.
   * @param displayName The new display name.
   */
  public async setDisplayName(displayName: string) {
    Guard.isLengthWithinBounds(displayName, "displayName", AttendeeDisplayNameLength.Max);
    const oldDisplayName = this.displayName;
    if (this.isLocal) this._meeting.localAttendee.displayName = displayName;
    const sendModel = <AttendeeModel>{};
    Object.assign(sendModel, this.model);
    sendModel.displayName = displayName;
    try {
      await this._controlConnection.updateAttendeeDisplayName(sendModel);
      await this._meeting.processAttendeeUpdated({attendee: sendModel});
    } catch (error: any) {
      if (this.isLocal) this._meeting.localAttendee.displayName = oldDisplayName;
      throw error;
    }
  }

  /**
   * Update the role on this attendee.
   * @param role The new role.
   */
  public async setRole(role: AttendeeRole) {
    const oldRole = this.role;
    if (this.isLocal) this._meeting.localAttendee.role = role;
    const sendModel = <AttendeeModel>{};
    Object.assign(sendModel, this.model);
    sendModel.role = role;
    try {
      const response = await this._controlConnection.updateAttendeeRole(sendModel);
      await this._meeting.processAttendeeUpdated({attendee: response.attendee});
    } catch (error: any) {
      if (this.isLocal) this._meeting.localAttendee.role = oldRole;
      throw error;
    }
  }

  /**
   * Suppresses audio noise.
   */
  public async suppressNoise() {
    await this._controlConnection.suppressAttendeeNoise(this.model);
    await this.processSuppressNoise();
  }

  /**
   * Unmutes the attendees audio.
   */
  public unmuteAudio(): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      if (!this._model.isUserAudioMuted) return;
      this._model.isUserAudioMuted = false;
      if (this.isLocal) this._meeting.localUserMedia?.audioTrack?.unmuteInternal();
      try {
        await this._controlConnection.unmuteAttendeeAudio(this.model);
        await this._meeting.processAttendeeAudioUnmuted({ attendee: this.model });
      } catch (error: any) {
        if (this.isLocal) this._meeting.localUserMedia?.audioTrack?.muteInternal();
        this._model.isUserAudioMuted = true;
        throw error;
      }
    });
  }

  /**
   * Unmutes the attendees video.
   */
  public unmuteVideo(): Promise<void> {
    return this._eventQueue.dispatch(async () => {
      if (!this._model.isUserVideoMuted) return;
      this._model.isUserVideoMuted = false;
      if (this.isLocal) this._meeting.localUserMedia?.videoTrack?.unmuteInternal();
      try {
        await this._controlConnection.unmuteAttendeeVideo(this.model);
        await this._meeting.processAttendeeVideoUnmuted({ attendee: this.model });
      } catch (error: any) {
        if (this.isLocal) this._meeting.localUserMedia?.videoTrack?.muteInternal();
        this._model.isUserVideoMuted = true;
        throw error;
      }
    });
  }

  /**
   * Unpins the attendee.
   */
  public async unpin() {
    await this._controlConnection.unpinAttendee(this.model);
    await this._meeting.processAttendeeUnpinned({attendee: this.model});
  }

  /**
   * Unsuppress audio noise.
   */
  public async unsuppressNoise() {
    await this._controlConnection.unsuppressAttendeeNoise(this.model);
    await this.processUnsuppressNoise();
  }
}
