import { DisplayMedia } from "@liveswitch/sdk";
import DispatchQueue from "./DispatchQueue";
import QRTransformer from "./QRTransformer";

export default class QRDisplayMedia extends DisplayMedia {
  private _asyncQueue = new DispatchQueue();
  private _qrVisible = true;

  public get qrVisible(): boolean { return this._qrVisible; }

  public constructor(audio: boolean, video: boolean) {
    super(audio, video);
  }

  protected async getStream(constraints: MediaStreamConstraints): Promise<MediaStream> {
    const stream = await super.getStream(constraints);
    if (this._qrVisible) {
      const trackStreams = stream.getVideoTracks();
      for (const trackStream of trackStreams) {
        stream.removeTrack(trackStream);
        stream.addTrack(new QRTransformer(this.videoTrack, trackStream, <MediaTrackConstraints>constraints.video).trackStream);
      }
    }
    return stream;
  }

  public async setQRVisible(qrVisible: boolean): Promise<void> {
    return this._asyncQueue.dispatch(async () => {
      if (this._qrVisible == qrVisible) return;
      this._qrVisible = qrVisible;
      if (this.videoTrack) await this.videoTrack.reload();
    });
  }
}