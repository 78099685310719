import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-203e71c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error?.name == 'BadRequestError')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.error) + " ", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((<BadRequestError>_ctx.error).requestFields, (field) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.name
          }, [
            (field.name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.messages, (message) => {
                    return (_openBlock(), _createElementBlock("div", { key: message }, [
                      _createElementVNode("li", null, _toDisplayString(field.name) + ": " + _toDisplayString(message), 1)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.messages, (message) => {
                    return (_openBlock(), _createElementBlock("div", { key: message }, [
                      _createElementVNode("li", null, _toDisplayString(message), 1)
                    ]))
                  }), 128))
                ]))
          ]))
        }), 128))
      ]))
    : (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
}