import Utility from "./Utility";

export default class Base64 {
  private static _base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=|[A-Za-z0-9+\/]*)?$/;
  
  public static encode(buffer: Uint8Array): string {
    if (Utility.isNullOrUndefined(buffer)) return null;
    const binary = [];
    for (let i = 0; i < buffer.byteLength; i++) binary.push(String.fromCharCode(buffer[i]));
    return btoa(binary.join(""));
  }

  public static decode(base64: string): Uint8Array {
    if (Utility.isNullOrUndefined(base64)) return null;
    if (!this._base64Regex.test(base64)) return null;
    const binary = atob(base64);
    const buffer = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) buffer[i] = binary.charCodeAt(i);
    return buffer;
  }
}