export default class ParseError extends Error {
  private readonly _error: Error;
  private readonly _text: string;

  public get error(): Error { return this._error; }
  public get text(): string { return this._text; }

  constructor(message: string, error: Error, text: string) {
    super(message);
    this.name = "ParseError";
    this._error = error;
    this._text = text;
  }
}