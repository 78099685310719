import DatabaseLog from "../api/models/DatabaseLog";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import Identity from "../identity/models/Identity";
import MeetingSession from "../meeting/models/Session";

export default class MetricLogger {
    private readonly _httpClientWithIdentityToken: HttpClient;
    private readonly _identity: Identity;
    private readonly _init: MetricLoggerInit;

    private readonly _attendeeLogsPath = "/attendeeLogs";
    private readonly _meetingLogsPath = "/meetingLogs";
    private readonly _tenantLogsPath = "/tenantLogs";
    private readonly _userAccountLogsPath = "/userAccountLogs";

    private _httpClientWithMeetingToken: HttpClient = null;
    private _meetingSession: MeetingSession =  null;
    
    constructor(init: MetricLoggerInit) {
        Guard.isNotNullOrUndefined(init, "init");
        Guard.isNotNullOrUndefined(init.identity, "init.identity");
        
        this._init = init;
        this._identity = init.identity;
        this._meetingSession = init.meetingSession;

        this._httpClientWithIdentityToken = HttpClient.withTokenFactory(async () => {
            const token = await this._init.identity.token();
            return {
              baseUrl: new URL(token.apiServiceUrl).origin,
              value: token.token
            }
        });

        if (init.meetingSession) {
            this.initHttpClientWithMeetingToken();
        }
    }

    public set meetingSession(value : MeetingSession) {
        this._meetingSession = value;
        this.initHttpClientWithMeetingToken();
    }
    
    public async log(event: AppMetric | SdkMetric) {
        Guard.isNotNullOrUndefined(event.metricType, "event.metricType");
        const databaseLog = this.getDatabaseLog(event);
        const [httpClient, path] = this.getHttpClientAndPath();
        await httpClient.post(path, databaseLog);
    }

    private convertFromCamelCaseToDot(value: string): string {
        return value.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(".");
    }

    private convertFromCamelCaseToSentence(value: string): string {
        const sentence = value.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(" ");
        return sentence.charAt(0).toUpperCase() + sentence.slice(1) + ".";
    }

    private getDatabaseLog(event: AppMetric | SdkMetric): DatabaseLog {
        if (!metricTypes.includes(event.metricType as any)) throw Error("Validation error: Invalid metric type."); 
        if (event.metricType == "sdk" && !sdkMeetingMetricNames.includes(event.eventName as any)) throw Error("Validation error: Invalid sdk metric type."); 

        // is it a built-in eventName?
        if (appMeetingMetricNames.includes(event.eventName as any) || sdkMeetingMetricNames.includes(event.eventName as any))
        {
            event.eventName = `${event.metricType}.${this.convertFromCamelCaseToDot(event.eventName)}`;
            event.eventMessage = event.eventMessage ?? this.convertFromCamelCaseToSentence(event.eventName);
        }

        return {
            createdOn: event.timestamp,
            eventDebug: JSON.stringify(event.eventDebug),
            eventDuration: Math.round(event.eventDuration),
            eventMessage: event.eventMessage ?? event.eventName,
            eventName: event.eventName,
            eventSeverity: "INFORMATION",
            eventType: "METRIC"
        };
    } 

    private getHttpClientAndPath(): [HttpClient, string] {
        return this._httpClientWithMeetingToken ? [this._httpClientWithMeetingToken, this._attendeeLogsPath] : [this._httpClientWithIdentityToken, this._userAccountLogsPath];
    }

    private initHttpClientWithMeetingToken() {
        this._httpClientWithMeetingToken = HttpClient.withTokenFactory(async () => {
            const meetingToken = await this._meetingSession.token();
            const identityToken = await this._identity.token();
            return {
              baseUrl: new URL(identityToken.apiServiceUrl).origin,
              value: meetingToken.value
            }
        });
    }
}

const appMeetingMetricNames = [
    "connectionAttempt",
    "connectionDropped",
    "connectionError",
    "connectionSuccess",
    "joinAttempt",
    "joinError",
    "joinSuccess",
    "qualityDrop",
    "reconnectAttempt",
    "reconnectError",
    "reconnectSuccess",
    "rejoinAttempt",
    "rejoinError",
    "rejoinSuccess"
  ] as const;
export type AppMeetingMetricName = typeof appMeetingMetricNames[number];

const sdkMeetingMetricNames = [
    "connectionAttemptControl",
    "connectionAttemptEdge",
    "connectionAttemptOriginDisplay",
    "connectionAttemptOriginUser",
    "connectionDroppedControl",
    "connectionDroppedEdge",
    "connectionDroppedOriginDisplay",
    "connectionDroppedOriginUser",
    "connectionErrorControl",
    "connectionErrorEdge",
    "connectionErrorOriginDisplay",
    "connectionErrorOriginUser",
    "connectionSuccessControl",
    "connectionSuccessEdge",
    "connectionSuccessOriginDisplay",
    "connectionSuccessOriginUser",
    "joinAttempt",
    "joinError",
    "joinSuccess",
    "qualityDrop",
    "reconnectAttemptControl",
    "reconnectAttemptEdge",
    "reconnectAttemptOriginDisplay",
    "reconnectAttemptOriginUser",
    "reconnectErrorControl",
    "reconnectErrorEdge",
    "reconnectErrorOriginDisplay",
    "reconnectErrorOriginUser",
    "reconnectSuccessControl",
    "reconnectSuccessEdge",
    "reconnectSuccessOriginDisplay",
    "reconnectSuccessOriginUser",
    "rejoinAttempt",
    "rejoinError",
    "rejoinSuccess"
  ] as const;
export type SdkMeetingMetricName = typeof sdkMeetingMetricNames[number];

export type AppMetricType = "app";
export type SdkMetricType = "sdk";
const metricTypes = ["app", "sdk"] as const;

export type MetricType = typeof metricTypes[number];

export type AppMetricName = AppMeetingMetricName;
export type SdkMetricName = SdkMeetingMetricName;


export interface MetricLoggerInit {
    identity: Identity;
    meetingSession?: MeetingSession;
}

export interface Metric {
    eventDebug?: { [key: string]: string | number };
    eventDuration?: number;
    eventMessage?: string;
    eventName: AppMetricName | SdkMeetingMetricName | string;
}

export interface AppMetric extends Metric {
    metricType: AppMetricType,
    timestamp: Date
}

export interface SdkMetric extends Metric {
    metricType: SdkMetricType,
    timestamp: Date
}

export interface AppMeetingMetric extends Metric {
    eventName: AppMetricName;
}

export interface AppCustomMeetingMetric extends Metric {
    eventName: string
}

export interface SdkMeetingMetric extends Metric {
    eventName: SdkMetricName
}
