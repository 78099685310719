import AttendeeListState from "./models/AttendeeListState";

export default class AttendeeListStateMachine {
  private _state: AttendeeListState;

  public get state(): AttendeeListState { return this._state; }

  public constructor(initialState?: AttendeeListState) {
    this._state = initialState ?? "new";
  }

  public setState(state: AttendeeListState): void {
    if (this.state === state) return;
    if (this.trySetState(state)) return;
    throw new Error(`Cannot transition to '${state}' state while in '${this._state}' state.`);
  }

  public trySetState(state: AttendeeListState): boolean {
    switch (state) {
      case "busy":
      case "impaired":
        if (this._state != "started") return false;
        break;
      case "error": 
        break;
      case "initialized":
        if (this._state != "new" && this._state != "initialized") return false;
        break;
      case "started":
        if (this._state != "starting") return false;
        break;
      case "starting":
        if (this._state != "initialized" && this._state != "stopped") return false;
        break;
      case "stopped":
        if (this._state != "starting" && this._state != "stopping" && this._state != "initialized") return false;
        break;
      case "stopping":
        if (this._state != "started") return false;
        break;
      default:
        return false;
    }
    this._state = state;
    return true;
  }
}