
// Display Values

// Video
export const VideoOriginFrameRateDisplayMin = "ORIGIN:FRAMERATE:DISPLAY:MIN";
export const VideoOriginPixelCountDisplayMin = "ORIGIN:PIXELCOUNT:DISPLAY:MIN";
export const VideoOriginJitterDisplayLow = "ORIGIN:JITTER:DISPLAY:LOW";
export const VideoOriginJitterDisplayMedium = "ORIGIN:JITTER:DISPLAY:MEDIUM";
export const VideoOriginJitterDisplayHigh = "ORIGIN:JITTER:DISPLAY:HIGH";
export const VideoOriginPacketLossDisplayLow = "ORIGIN:PACKETLOSS:DISPLAY:LOW";
export const VideoOriginPacketLossDisplayMedium = "ORIGIN:PACKETLOSS:DISPLAY:MEDIUM";
export const VideoOriginPacketLossDisplayHigh = "ORIGIN:PACKETLOSS:DISPLAY:HIGH";
// Audio
export const AudioOriginJitterThresholdDisplayLow =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:LOW";
export const AudioOriginJitterThresholdDisplayMedium =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:MEDIUM";
export const AudioOriginJitterThresholdDisplayHigh =  "ORIGIN:AUDIO:JITTERTHRESHOLD:DISPLAY:HIGH";
export const AudioOriginPacketLossThresholdDisplayLow = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:LOW";
export const AudioOriginPacketLossThresholdDisplayMedium = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:MEDIUM";
export const AudioOriginPacketLossThresholdDisplayHigh = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:DISPLAY:HIGH";


// User Values
// Video
export const VideoOriginFrameRateUserMin = "ORIGIN:FRAMERATE:USER:MIN";
export const VideoOriginPixelCountUserMin = "ORIGIN:PIXELCOUNT:USER:MIN";
export const VideoOriginJitterUserLow = "ORIGIN:JITTER:USER:LOW";
export const VideoOriginJitterUserMedium = "ORIGIN:JITTER:USER:MEDIUM";
export const VideoOriginJitterUserHigh = "ORIGIN:JITTER:USER:HIGH";
export const VideoOriginPacketLossUserLow = "ORIGIN:PACKETLOSS:USER:LOW";
export const VideoOriginPacketLossUserMedium = "ORIGIN:PACKETLOSS:USER:MEDIUM";
export const VideoOriginPacketLosUserHigh = "ORIGIN:PACKETLOSS:USER:HIGH";
// Audio
export const AudioOriginJitterThresholdUserLow =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:LOW";
export const AudioOriginJitterThresholdUserMedium =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:MEDIUM";
export const AudioOriginJitterThresholdUserHigh =  "ORIGIN:AUDIO:JITTERTHRESHOLD:USER:HIGH";
export const AudioOriginPacketLossThresholUserLow = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:LOW";
export const AudioOriginPacketLossThresholdUserMedium = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:MEDIUM";
export const AudioOriginPacketLossThresholdUserHigh = "ORIGIN:AUDIO:PACKETLOSSTHRESHOLD:USER:HIGH";