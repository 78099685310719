import LocalVideoTrackState from "./models/LocalVideoTrackState";

export default class LocalVideoTrackStateMachine {
  private _state: LocalVideoTrackState;

  public get state(): LocalVideoTrackState { return this._state; }

  public constructor(initialState?: LocalVideoTrackState) {
    this._state = initialState ?? "new";
  }

  public setState(state: LocalVideoTrackState): void {
    if (this.trySetState(state)) return;
    throw new Error(`Cannot transition to '${state}' state while in '${this._state}' state.`);
  }

  public trySetState(state: LocalVideoTrackState): boolean {
    switch (state) {
      case "started":
        if (this._state != "starting") return false;
        break;
      case "starting":
        if (this._state != "new" && this._state != "stopped") return false;
        break;
      case "stopped":
        if (this._state != "starting" && this._state != "stopping" && this._state != "new") return false;
        break;
      case "stopping":
        if (this._state != "started") return false;
        break;
      default:
        return false;
    }
    this._state = state;
    return true;
  }
}