export default class HttpError extends Error {
  private readonly _request: Request;
  private readonly _response: Response;

  public get request(): Request { return this._request; }
  public get response(): Response { return this._response; }

  constructor(message: string, request: Request, response: Response) {
    super(message);
    this.name = "HttpError";
    this._request = request;
    this._response = response;
  }
}