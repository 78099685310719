export enum CountryCodeLength {
    Max = 3
}

export enum CountryNameLength {
    Max = 100
}

export enum CountryNumericLength {
    Max = 3
}