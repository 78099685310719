import Attendee from "./Attendee";
import PermissionName from "./models/PermissionName";
import PermissionType from "./models/PermissionType";

export default class Permissions {
  public static hasPermission(attendee: Attendee, permissionType: PermissionType, permissionName: PermissionName): boolean {
    return attendee?.permissions?.indexOf(`${permissionType}:${permissionName}`) !== -1;
  }

  public static getPermissionTypeFromName(permissionName: PermissionName) : PermissionType {
    let permissionType: PermissionType = "ATTENDEE";
    switch (permissionName) {
      case "AdministerChat":
      case "CreateChannel":
      case "DeleteChannel":
      case "UpdateChannel":
      case "DeleteMessage":
      case "SendMessage":
        permissionType = "CHAT";
        break;
      case "EnableChat":
      case "RecordMeeting":
        permissionType = "MEETING";
        break;

      case "DeleteUserAccountLink":
      case "HostLargeMeeting":
        permissionType = "USERACCOUNT";
        break;
      case "EnableSip":
      case "ManageStorage":
        permissionType = "ROOM";
      default: 
        break;
    }
    return permissionType;
  }
}
