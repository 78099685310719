export enum ServerNameLength {
  Max = 50
}

export enum ServerAppVersionLength {
  Max = 50
}

export enum ServerArchitectureLength {
  Max = 50
}

export enum ServerInternalHostLength {
  Max = 255
}

export enum ServerInternalProtocolLength {
  Max = 20
}

export enum ServerMediaHostLength {
  Max = 255
}

export enum ServerMediaProtocolLength {
  Max = 20
}

export enum ServerOperatingSystemLength {
  Max = 50
}

export enum ServerOperatingSystemVersionLength {
  Max = 10
}

export enum ServerExternalHostLength {
  Max = 255
}

export enum ServerExternalProtocolLength {
  Max = 20
}