import { version }  from "@liveswitch/sdk";

export function getSdkVersion() {
  return version;
}

export async function getServerVersion(serverStatusUrl: string ): Promise<string | null> {   
  try {
    const response = await fetch(serverStatusUrl, {
      method: "GET",
    });

    if (!response.ok) return null;
    return (await response.json()).assemblyVersion;
  } catch {
    return null;
  }
}