export default class FakeAudio {
  private readonly _context: AudioContext;
  private readonly _sampleRate: number;
  private readonly _workletProcessorBlob: Blob;

  private _amplitude = 0;
  private _frequency = 0;
  private _isStarted = false;
  private _stream?: MediaStream;
  private _streamDestinationNode?: MediaStreamAudioDestinationNode;
  private _streamTrack?: MediaStreamTrack;
  private _workletNode?: AudioWorkletNode;

  public get amplitude(): number { return this._amplitude; }

  public set amplitude(value: number) {
    this._amplitude = Math.min(1, Math.max(0, value));
    if (this._workletNode) {
      this._workletNode.port.postMessage({
        amplitude: this._amplitude
      });
    }
  }

  public get frequency(): number { return this._frequency; }

  public set frequency(value: number) {
    this._frequency = Math.max(0, value);
    if (this._workletNode) {
      this._workletNode.port.postMessage({
        frequency: this._frequency
      });
    }
  }

  public get sampleRate(): number { return this._sampleRate; }

  public get streamTrack(): MediaStreamTrack | undefined { return this._streamTrack; }

  public constructor(frequency: number, amplitude: number, sampleRate?: number) {
    this._sampleRate = sampleRate ?? 48000;
        
    this.amplitude = amplitude ?? 0.5;
    this.frequency = frequency ?? 440;

    this._context = new AudioContext({
      sampleRate: this._sampleRate,
      latencyHint: "interactive"
    });
        
    this._workletProcessorBlob = new Blob([`
    class FakeAudioSource extends AudioWorkletProcessor {
        constructor(options) {
            super();
            this._amplitude = options.amplitude ?? 0.5;
            this._frequency = options.frequency ?? 440;
            this._signalCounter = 0;
            this.port.onmessage = event => {
                if (event.data.amplitude) {
                    this._amplitude = event.data.amplitude;
                }
                if (event.data.frequency) {
                    this._frequency = event.data.frequency;
                }
            }
        }
        process(inputs, outputs) {
            const channels = outputs[0]
            let channel = channels[0];
            let angle = 2 * Math.PI * this._frequency / sampleRate;
            for (let i = 0; i < channel.length; i++) {
                let signal = Math.min(1.0, Math.max(-1.0, this._amplitude * Math.sin(angle * this._signalCounter)));
                this._signalCounter++;
                if (this._signalCounter == Number.MAX_SAFE_INTEGER) {
                    this._signalCounter = 0;
                }
                channel[i] = signal;
            }
            return true
        }
    }
    registerProcessor('liveswitch-fake-audio-source', FakeAudioSource);`], { type: "application/javascript" });
  }

  public async start(): Promise<void> {
    if (!this._isStarted) {
      await this._context.audioWorklet.addModule(URL.createObjectURL(this._workletProcessorBlob));
      this._workletNode = new AudioWorkletNode(this._context, "liveswitch-fake-audio-source", {
        numberOfInputs: 0,
        outputChannelCount: [1],
        processorOptions: {
          amplitude: this._amplitude,
          frequency: this._frequency
        }
      });
      this._streamDestinationNode = this._context.createMediaStreamDestination();
      this._workletNode.connect(this._streamDestinationNode);
      this._stream = this._streamDestinationNode.stream;
      this._streamTrack = this._stream.getAudioTracks()[0];
      this._isStarted = true;
    }
  }

  public async stop(): Promise<void> {
    if (this._isStarted) {
      this._workletNode?.disconnect();
      this._context.close();
      this._isStarted = false;
    }
  }
}